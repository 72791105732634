import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {BsFillCloudArrowDownFill, BsPersonPlusFill} from "react-icons/bs";
import "../../scss/student-info.scss";
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import axiosCommon from '../../config/axiosCommon';
import DeleteStudentInfoModal from "../../components/student-infos/DeleteStudentInfoModal";
import {
    addReturnUrlToParamString,
    changeQueryString,
    formatDate,
    formatTime,
    getSearchParams,
    getTitleFromId,
    setPageTitle
} from "../../common/function";
import constants from "../../config/constants";
import {toast} from 'react-toastify';
import HistoryFeePaidModal from "../../components/student-infos/HistoryFeePaidModal";
import DatePicker from "react-datepicker";
import UploadFile from "./UploadFile";
import TestModal from "./TestModal";
import EditAssignedUserModal from "../../components/Custom/EditAssignedUserModal";
import * as XLSX from 'xlsx/xlsx.mjs';
import {cloneDeep} from "lodash";

export default () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [idStudentInfo, setIdStudentInfo] = useState();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showAssignedModal, setShowAssignedModal] = useState(false);
    const [showCallTimeModal, setShowCallTimeModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [saleList, setSaleList] = useState([]);
    const [idShowTest, setIdShowTest] = useState();
    const [rowSwitch, setRowSwitch] = useState({});
    const [totalRows, setTotalRows] = useState(1);
    const [key, setKey] = useState(Date.now());

    const [inputs, setInputs] = useState({
        full_name: '',
        student_no: '',
        phone: '',
        email: '',
        contact_source: '',
        status: '',
        status_reject: '',
        assigned_user: '',
        end_date: '',
        start_date: '',
        call_day: '',
        call_hour_start: '',
        call_hour_end: '',
        writing_skill_test: 'all',
        per_page: constants.arrPerPage[0],
        page: 1,
    });

    const [columns] = useState(
        [
            {
                name: 'Mã HV',
                cell: row => row.student_no,
                selector: row => row.id,
                sortable: true,
                width: 2,
            },
            {
                name: 'Họ và tên',
                selector: row => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top' overlay={<Tooltip>{row.full_name}</Tooltip>}>
                                <div>{row.full_name}</div>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
                grow: 2,
            },
            {
                name: 'SĐT',
                selector: row => row.phone,
                grow: 2,
            },
            {
                name: 'Email',
                selector: row => row.email,
                grow: 3,
                wrap: true
            },
            {
                name: 'Trạng thái',
                sortable: true,
                selector: row => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top' overlay={
                                <Tooltip>{getTitleFromId(row.status, constants.statuses)}</Tooltip>}>
                                <div>{getTitleFromId(row.status, constants.statuses)}</div>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
                grow: 2,
            },
            {
                name: 'Nguyên nhân từ chối',
                sortable: true,
                selector: row => {
                    let text = row.status_reject ? getTitleFromId(row.status_reject, constants.status_rejects) : '-'
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top' overlay={<Tooltip>{text}</Tooltip>}>
                                <div>{text}</div>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
                grow: 2,
            },
            {
                name: 'Người phụ trách',
                sortable: true,
                selector: row => {
                    let text = row.user_full_name ?? '-'
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top' overlay={<Tooltip>{text}</Tooltip>}>
                                <div>{text}</div>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
                grow: 2,
            },
            {
                name: 'Ngày tạo',
                sortable: true,
                cell: row => formatDate(row.created_at),
                selector: row => row.created_at,
                grow: 2,
            },
            {
                name: 'Dạng bài kiểm tra',
                sortable: true,
                selector: row => getTitleFromId(row.writing_skill_test, constants.writing_skill_tests),
                grow: 2,
            },
            {
                name: 'Thời gian gọi',
                sortable: true,
                cell: row => row.call_day ? formatDate(row.call_day) + ' ' + formatTime(row.call_hour, '--:--') : formatTime(row.call_hour),
                selector: row => formatDate(row.call_day, '9999-99-99', 'YYYY-MM-DD') + ' ' + formatTime(row.call_hour, '--:--'),
                grow: 2,
            },
            {
                name: 'Đã nhắc test',
                sortable: true,
                cell: row => <Form.Check type="switch" name="is_test_reminded" checked={row.is_test_reminded}
                                         onChange={() => setRowSwitch(row)}/>,
                selector: row => row.is_test_reminded,
                grow: 2,
                center: true,
            },
            {
                name: '',
                grow: 3.3,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa học viên</Tooltip>}>
                                <NavLink className={'btn btn-info btn-sm me-1'}
                                         to={`/student-infos/${row.id}?` + addReturnUrlToParamString()}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </NavLink>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Kết quả bài kiểm tra</Tooltip>}>
                                <NavLink className={'btn btn-success btn-sm me-1'}
                                         onClick={() => setIdShowTest(row.id)}>
                                    <i className="fa-solid fa-clipboard"></i>
                                </NavLink>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa thời gian gọi</Tooltip>}>
                                <Button className={'btn btn-warning btn-sm me-1'} onClick={() => {
                                    setCurrentRow(row);
                                    setShowCallTimeModal(true);
                                }}>
                                    <i className="fa-regular fa-calendar-days text-white"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa người phụ trách</Tooltip>}>
                                <Button className={'btn btn-secondary btn-sm me-1'} onClick={() => {
                                    setCurrentRow(row);
                                    setShowAssignedModal(true);
                                }}>
                                    <i className="fa-solid fa-user text-white"></i>
                                </Button>
                            </OverlayTrigger>
                            {row.role_id == constants.ROLE_ID_ADMIN &&
                                <OverlayTrigger placement='left' overlay={<Tooltip>Xóa học viên</Tooltip>}>
                                    <Button className={'btn btn-danger btn-sm me-1'} onClick={() => openModal(row.id)}>
                                        <i className="fa-regular fa-trash-can"></i>
                                    </Button>
                                </OverlayTrigger>
                            }
                        </Fragment>
                    );
                },
            },
        ]
    );

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleChangeDateTime = (data) => {
        setInputs(values => ({...values, ...data}))
    }

    const openModal = (id) => {
        setIdStudentInfo(id);
        setOpen(true);
    }

    const openHistoryFeePaidModal = (id) => {
        setIdStudentInfo(id);
        setShowModal(true);
    }

    const showToast = msg => {
        toast.success(msg, {
            autoClose: 3000,
            hideProgressBar: true,
            position: "top-right",
            closeOnClick: true
        })
    }

    let getSearchInfo = (searchs = null) => {
        let searchInfo = {
            ...inputs,
            start_date: formatDate(inputs.start_date, '', 'YYYY-MM-DD'),
            end_date: formatDate(inputs.end_date, '', 'YYYY-MM-DD'),
            call_day: formatDate(inputs.call_day, '', 'YYYY-MM-DD')
        }
        if (searchs) {
            searchInfo = {...searchInfo, ...searchs}
        } else {
            changeQueryString(searchInfo)
        }
        return searchInfo
    }

    const getListStudentInfo = (searchs = null) => {
        setIsLoading(true)
        axiosCommon.get(ApiPath.studentInfos, {params: getSearchInfo(searchs)})
            .then((res) => {
                setData(res.data.data)
				setTotalRows(res.data.total)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setIsLoading(true)
        setPageTitle('Quản lý thông tin học viên');
        const searchParams = getSearchParams()
        setInputs({
            ...inputs,
            ...searchParams,
            start_date: searchParams.start_date ? new Date(searchParams.start_date) : '',
            end_date: searchParams.end_date ? new Date(searchParams.end_date) : '',
            call_day: searchParams.call_day ? new Date(searchParams.call_day) : '',
        })
        setKey(Date.now())
        axiosCommon.post(ApiPath.commonData)
            .then((res) => {
                setSaleList(res.data.sales)
                getListStudentInfo(searchParams);
            })

        // Show toast
        const notification = location.state?.notification
        if (notification && notification.type == 'success') {
            showToast(notification.message)
            navigate(location.pathname + location.search, {replace: true})
        }
    }, [])

    useEffect(() => {
        if (rowSwitch && rowSwitch.id) {
            axiosCommon.post(ApiPath.studentUpdateData, {
                student_info_id: rowSwitch.id,
                is_test_reminded: !rowSwitch.is_test_reminded,
                form_search: getSearchInfo(),
                page: inputs?.page
            }).then((res) => {
				showToast('Chuyển đổi trạng thái Đã nhắc test thành công.')
                setData(res.data.data)
				setTotalRows(res.data.total)
            })
        }
    }, [rowSwitch])

    const onSubmit = (e) => {
        e.preventDefault();
        getListStudentInfo();
    }

    const deleteStudentInfo = () => {
        setIsLoading(true)
        axiosCommon.delete(ApiPath.detailStudentInfo + idStudentInfo)
            .then((res) => {
                getListStudentInfo();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const updateAssignedUser = (assignedUserId) => {
        setIsLoading(true)
        axiosCommon.post(ApiPath.studentUpdateData, {
            student_info_id: currentRow.id,
            assigned_user: assignedUserId,
            form_search: getSearchInfo(),
            page: inputs?.page
        }).then((res) => {
            showToast('Cập nhật người phụ trách thành công.')
			setData(res.data.data)
			setTotalRows(res.data.total)
        })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const updateCallTime = (callTime) => {
        setIsLoading(true)
        axiosCommon.post(ApiPath.studentUpdateData, {
            student_info_id: currentRow.id,
            call_day: formatDate(callTime.call_day, '', 'YYYY-MM-DD'),
            call_hour: callTime.call_hour,
            form_search: getSearchInfo(),
            page: inputs?.page
        }).then((res) => {
            showToast('Cập nhật thời gian gọi thành công.')
			setData(res.data.data)
			setTotalRows(res.data.total)
        })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const exportStudent = (paramSearch = inputs) => {
        setLoaded(false);
        axiosCommon.get(ApiPath.exportStudentInfo, {params: paramSearch})
            .then((response) => {
                if (response.data) {
                    const arrayOfArrays = response.data.map(obj => Object.values(obj));
                    const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArrays);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                    XLSX.writeFile(workbook, 'student-info.xlsx');
                }
            })
            .finally(() => {
                setLoaded(true);
            });
    }

    const handlePageChange = (page) => {
        let search = {
            ...inputs,
            page: page
        }
        setInputs(search)
		search = { ...getSearchInfo(), page: page }
        changeQueryString(search)
        getListStudentInfo(search)
    }
    const handlePerPageChange = (newPerPage) => {
        let search = {
            ...inputs,
            per_page: newPerPage,
            page: 1
        }
        setInputs(search)
		search = { ...getSearchInfo(), per_page: newPerPage, page: 1 }
        changeQueryString(search)
        getListStudentInfo(search)
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };
    const conditionalRowStyles = [
        {
            when: row => row.check_edit_fees,
            style: {
                backgroundColor: 'pink',
                color: 'red',
            },
        },
    ];

    return (
        <Fragment>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.StudentInfos}/>
            <Row className="mb-2">
                <Col sm={12}>
                    <div className={'div-btn'}>
                        <Button
                            className={'background2e3650 mr-10 btn btn-info btn-sm'}
                            onClick={() => exportStudent()}
                        >
                            <BsFillCloudArrowDownFill className={'icon-add-student-info'}/>
                            Download file
                        </Button>
                        <UploadFile getListStudentInfo={getListStudentInfo}/>
                        <NavLink className={'btn btn-tertiary background2e3650'}
                                 to={Routes.CreateStudentInfo.path + '?' + addReturnUrlToParamString()}>
                            <BsPersonPlusFill className={'icon-add-student-info'}/>
                            <span>Thêm Học Viên</span>
                        </NavLink>
                    </div>
                </Col>
            </Row>
            <Form onSubmit={onSubmit}>
                <Row className="mb-1">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Tên học viên</Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text" name="full_name" value={inputs.full_name}
                                    onChange={handleChange} placeholder="Tên học viên"/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Mã học viên</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="student_no" value={inputs.student_no}
                                              onChange={handleChange} placeholder="Mã học viên"/>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>SĐT</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="phone" value={inputs.phone} onChange={handleChange}
                                              placeholder="Số điện thoại"/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Email</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="email" value={inputs.email} onChange={handleChange}
                                              placeholder="Email học viên"/>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Người phụ trách</Form.Label>
                            <Col sm={9}>
                                <Form.Select name="assigned_user" value={inputs.assigned_user} onChange={handleChange}>
                                    <option value="" defaultValue>Tất cả người phụ trách</option>
                                    <option value="none">Chưa có người phụ trách</option>
                                    {
                                        saleList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.full_name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Giờ gọi</Form.Label>
                            <Col sm={9}>
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={5}>
                                        <Form.Control type="time" name="call_hour_start" value={inputs.call_hour_start}
                                                      onChange={handleChange} placeholder="HH:mm"/>
                                    </Col>
                                    <Form.Label column sm={2} className="text-center">~</Form.Label>
                                    <Col sm={5}>
                                        <Form.Control type="time" name="call_hour_end" value={inputs.call_hour_end}
                                                      onChange={handleChange} placeholder="HH:mm"/>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Ngày xếp lịch gọi</Form.Label>
                            <Col sm={9}>
                                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon
                                            placeholderText="Ngày xếp lịch gọi"
                                            selected={inputs.call_day}
                                            onChange={value => handleChangeDateTime({call_day: value})}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Trạng thái</Form.Label>
                            <Col sm={9}>
                                <Form.Select name="status" value={inputs.status} onChange={handleChange}>
                                    <option value="" defaultValue>Tất cả trạng thái</option>
                                    {
                                        constants.statuses.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.title}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Ngày tạo</Form.Label>
                            <Col sm={9}>
                                <DatePicker
                                    className="form-control" dateFormat="dd/MM/yyyy" showIcon
                                    placeholderText={`--/--/----`}
                                    selected={inputs.start_date}
                                    onChange={value => handleChangeDateTime({start_date: value})}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3} className={`text-center`}>~</Form.Label>
                            <Col sm={9}>
                                <DatePicker
                                    className="form-control" dateFormat="dd/MM/yyyy" showIcon
                                    selected={inputs.end_date}
                                    placeholderText={`--/--/----`}
                                    onChange={value => handleChangeDateTime({end_date: value})}/>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-1">

                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Dạng bài kiểm tra</Form.Label>
                            <Col sm={9} className="mt-2">
                                <Form.Check inline label="Tất cả" name="writing_skill_test" type="radio" value={'all'}
                                            id="writing_skill_test-all" checked={inputs.writing_skill_test === 'all'}
                                            onChange={handleChange}/>
                                {
                                    constants.writing_skill_tests.map((item, index) => {
                                        return (
                                            <Form.Check key={index} inline label={item.title} name="writing_skill_test"
                                                        type="radio" value={item.id}
                                                        id={`writing_skill_test-${index}`}
                                                        checked={inputs.writing_skill_test == item.id}
                                                        onChange={handleChange}/>
                                        )
                                    })
                                }
                            </Col>
                        </Form.Group>
                    </Col>
                    {constants.arr_reject_status_ids.includes(parseInt(inputs.status)) &&
                        <Col sm={6}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>Nguyên nhân từ chối</Form.Label>
                                <Col sm={9}>
                                    <Form.Select name="status_reject" value={inputs.status_reject}
                                                 onChange={handleChange}>
                                        <option value="" defaultValue>Tất cả nguyên nhân</option>
                                        {
                                            constants.status_rejects.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.title}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                    }
                </Row>
                <Row className="mb-3">
                    <Col sm={12}>
                        <Button type="submit" className="btn btn-secondary float-end background2e3650">Tìm kiếm</Button>
                    </Col>
                </Row>
            </Form>
            <DataTable
                key={key}
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={{
                    rowsPerPageText: 'Số hàng mỗi trang:',
                    rangeSeparatorText: 'của',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Tất cả',
                }}
                onChangePage={handlePageChange}
                currentPage={inputs?.page}
                columns={columns}
                data={data}
                pagination
                title="Danh sách thông tin học viên"
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                onChangeRowsPerPage={handlePerPageChange}
                paginationRowsPerPageOptions={constants.arrPerPage}
                paginationPerPage={parseInt(inputs?.per_page)}
                noDataComponent={<div className="p-4">Không tìm thấy học viên nào.</div>}
            />
            <DeleteStudentInfoModal show={open} setShowModal={setOpen}
                                    actionOk={deleteStudentInfo} actionCancel={() => setIdStudentInfo(0)}/>
            <HistoryFeePaidModal show={showModal} id={idStudentInfo} setShowModal={setShowModal}
                                 actionOk={() => setShowModal(false)} actionCancel={() => setIdStudentInfo(0)}/>
            {
                Boolean(idShowTest) &&
                <TestModal
                    showModal={idShowTest}
                    setShowModal={setIdShowTest}
                />
            }
            <EditAssignedUserModal show={showAssignedModal} setShowModal={setShowAssignedModal}
                                   currentRow={currentRow} actionOk={updateAssignedUser}
                                   actionCancel={() => setCurrentRow(null)}/>
            <EditCallTimeModal show={showCallTimeModal} setShowModal={setShowCallTimeModal}
                               currentRow={currentRow} actionOk={updateCallTime}
                               actionCancel={() => setCurrentRow(null)}/>
        </Fragment>
    );
}

function EditCallTimeModal({show, setShowModal, currentRow, actionOk, actionCancel = ''}) {
    const [inputs, setInputs] = useState({
        call_day: '',
        call_hour: ''
    });

    useEffect(() => {
        if (show) {
            setInputs({
                call_day: currentRow.call_day ? new Date(currentRow.call_day) : '',
                call_hour: currentRow.call_hour
            })
        }
    }, [show])

    const handleClose = () => {
        if (actionCancel) {
            actionCancel();
        }
        setShowModal(false);
    };

    const handleOk = () => {
        actionOk(inputs)
        handleClose()
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleChangeDateTime = (data) => {
        setInputs(values => ({...values, ...data}))
    }

    return (
        <Modal show={show} onHide={() => setShowModal(null)}>
            <Modal.Header closeButton className="d-flex align-content-center">
                <Modal.Title>Thay đổi thời gian gọi</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-2 flex-column px-3">
                <Row className="mb-8">
                    <Form.Group as={Col} md="6" controlId="status">
                        <Form.Label>Ngày gọi</Form.Label>
                        <DatePicker className="form-control" dateFormat={"dd/MM/yyyy"} showIcon
                                    placeholderText="Ngày xếp lịch gọi"
                                    selected={inputs.call_day}
                                    onChange={value => handleChangeDateTime({call_day: value})}/>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="status">
                        <Form.Label>Giờ gọi</Form.Label>
                        <Form.Control type="time" name="call_hour" value={inputs.call_hour} onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <Button className="mt-3 mb-4" variant='info' onClick={handleOk}>Thay đổi</Button>
            </Modal.Body>
        </Modal>
    )
}
