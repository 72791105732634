import React from "react";
import moment from "moment-timezone";
import {Col, Row} from 'react-bootstrap';

export default () => {
    const currentYear = moment().get("year");

    return (
        <div>
            <footer className="navbar navbar-expand-lg navbar-light bg-light fixed-bottom">
                <p className="mb-0 text-center text-xl-left m-auto">
                    Copyright © {`${currentYear} `} TTH Solution
                </p>
            </footer>
        </div>
    );
};
