import React, {useEffect, useState} from "react";
import {Form, Modal, Button, InputGroup} from 'react-bootstrap';

export default (props) => {
    const {
        show,
        setShowModal,
        actionCancel = '',
        currentUnit,
    } = props

    const [description, setDescription] = useState('');

    const handleClose = () => {
        if (actionCancel) {
            actionCancel();
        }
        setShowModal(false);
    };

    const handleOk = () => {
        handleClose()
    };

    useEffect(() => {
        if (show) {
            setDescription(currentUnit.note);
        }
    }, [show])

    return (
        <>
            <Modal size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   as={Form} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentUnit?.lesson}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <div>Link Audio: <span className="text-danger">
                            <a target="_blank" href={currentUnit.link_audio}>{currentUnit.link_audio}</a>
                            </span>
                        </div>
                        <div>Link bài học: <span className="text-danger">
                                <a target="_blank" href={currentUnit.link_lesson}>{currentUnit.link_lesson}</a>
                            </span>
                        </div>
                        <div>Mô tả ngắn:</div>
                        <InputGroup>
                            <Form.Control readOnly={true} name="description" as="textarea" value={currentUnit.description} rows="4" />
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose} autoFocus>
                        Hủy
                    </Button>
                    {/*<Button variant="info" onClick={handleOk}>*/}
                    {/*    OK*/}
                    {/*</Button>*/}
                </Modal.Footer>
            </Modal>
        </>
    );
}
