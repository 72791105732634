import * as React from 'react';
import {formatMoneyComma} from "../../common/function";

export default function TableReport({dataTable}) {
    return (
        <table className="table table-bordered bg-white">
            <tbody>
            <tr>
                <th scope="row" className={`text-center align-middle`} rowSpan={4}>1</th>
                <td rowSpan={4} className={`text-center align-middle`}>DOANH SỐ</td>
                <td rowSpan={4} className={`text-center align-middle`}>Doanh số tuần</td>
                <td colSpan={3}>Tổng doanh số đạt được trong tuần</td>
                <td className="pink text-right">{formatMoneyComma(dataTable?.target?.target_week?.total_target?.[0] || 0)}</td>
                <td className="pink text-right">{formatMoneyComma(dataTable?.target?.target_week?.total_target?.[1] || 0)}</td>
                <td className="pink text-right">{formatMoneyComma(dataTable?.target?.target_week?.total_target?.[2] || 0)}</td>
                <td className="pink text-right">{formatMoneyComma(dataTable?.target?.target_week?.total_target?.[3] || 0)}</td>
            </tr>
            <tr>
                <td colSpan={2}>TỈ lệ (so với tuần trước)</td>
                <td>Tăng/Giảm(%)</td>
                <td className="text-right">{dataTable?.target?.target_week?.rate?.[0] || 0}</td>
                <td className="text-right">{dataTable?.target?.target_week?.rate?.[1] || 0}</td>
                <td className="text-right">{dataTable?.target?.target_week?.rate?.[2] || 0}</td>
                <td className="text-right">{dataTable?.target?.target_week?.rate?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Doanh số thu hồi công nợ</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_recovery_debt?.[0] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_recovery_debt?.[1] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_recovery_debt?.[2] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_recovery_debt?.[3] || 0)}</td>
            </tr>
            <tr>
                <td colSpan={3}>Doanh số từ học viên mới</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_new?.[0] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_new?.[1] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_new?.[2] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.target?.target_week?.revenue_new?.[3] || 0)}</td>
            </tr>

            <tr>
                <td rowSpan={2} className={`text-center align-middle`}>2</td>
                <td rowSpan={2} className={`text-center align-middle`}>HỌC VIÊN</td>
                <td colSpan={4}>Số học viên mới trong tuần</td>
                <td className="blu text-right">{dataTable?.student?.number?.[0] || 0}</td>
                <td className="blu text-right">{dataTable?.student?.number?.[1] || 0}</td>
                <td className="blu text-right">{dataTable?.student?.number?.[2] || 0}</td>
                <td className="blu text-right">{dataTable?.student?.number?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={4}>Trung bình doanh số/ 1 học viên</td>
                <td className="text-right">{formatMoneyComma(dataTable?.student?.average_revenue?.[0] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.student?.average_revenue?.[1] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.student?.average_revenue?.[2] || 0)}</td>
                <td className="text-right">{formatMoneyComma(dataTable?.student?.average_revenue?.[3] || 0)}</td>
            </tr>

            <tr>
                <td rowSpan={15} className={`text-center align-middle`}>3</td>
                <td rowSpan={15} className={`text-center align-middle`}>THỐNG KÊ DATA VÀ QÚA TRÌNH CHỐT SALE</td>
                <td rowSpan={10} className={`text-center align-middle`}>DATA</td>
                <td rowSpan={2}>Data thu được</td>
                <td colSpan={2}>Tổng số</td>
                <td className="green text-right">{dataTable?.statistic_data?.data?.collected_data?.total?.[0] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.data?.collected_data?.total?.[1] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.data?.collected_data?.total?.[2] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.data?.collected_data?.total?.[3] || 0}</td>
            </tr>
            <tr>
                <td>Tỉ lệ</td>
                <td>Tăng/Giảm(%)</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.collected_data?.rate?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.collected_data?.rate?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.collected_data?.rate?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.collected_data?.rate?.[3] || 0}</td>
            </tr>

            <tr>
                <td>DATA từ các nguồn</td>
                <td colSpan={2}>Web</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.web?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.web?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.web?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.web?.[3] || 0}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={2}>Fanpage</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.fanpage?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.fanpage?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.fanpage?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.fanpage?.[3] || 0}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={2}>Inbox</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.inbox?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.inbox?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.inbox?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.inbox?.[3] || 0}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={2}>Zalo/khác</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.rest?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.rest?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.rest?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.source_data?.rest?.[3] || 0}</td>
            </tr>

            <tr>
                <td>Tỉ lệ DATA từ các nguồn</td>
                <td colSpan={2}>Web</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.web?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.web?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.web?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.web?.[3] || 0}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={2}>Fanpage</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.fanpage?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.fanpage?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.fanpage?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.fanpage?.[3] || 0}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={2}>Inbox</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.inbox?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.inbox?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.inbox?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.inbox?.[3] || 0}</td>
            </tr>
            <tr>
                <td></td>
                <td colSpan={2}>Zalo/khác</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.rest?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.rest?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.rest?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.data?.rate_source_data?.rest?.[3] || 0}</td>
            </tr>

            <tr>
                <td rowSpan={2} className={`text-center align-middle`}>Bài test gửi về</td>
                <td colSpan={3}>Tổng số</td>
                <td className="green text-right">{dataTable?.statistic_data?.test?.total?.[0] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.test?.total?.[1] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.test?.total?.[2] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.test?.total?.[3] || 0}</td>
            </tr>
            <tr>
                <td>Tỉ lệ (so với tuần trước)</td>
                <td colSpan={2}>Tăng/Giảm(%)</td>
                <td className="text-right">{dataTable?.statistic_data?.test?.rate?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.test?.rate?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.test?.rate?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.test?.rate?.[3] || 0}</td>
            </tr>

            <tr>
                <td rowSpan={2} className={`text-center align-middle`}>Cuộc gọi</td>
                <td colSpan={3}>Tổng số cuộc gọi</td>
                <td className="green text-right">{dataTable?.statistic_data?.call?.total?.[0] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.call?.total?.[1] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.call?.total?.[2] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.call?.total?.[3] || 0}</td>
            </tr>
            <tr>
                <td>Tỉ lệ (so với tuần trước)</td>
                <td colSpan={2}>Tăng/Giảm (%)</td>
                <td className="text-right">{dataTable?.statistic_data?.call?.rate?.[0] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.call?.rate?.[1] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.call?.rate?.[2] || 0}</td>
                <td className="text-right">{dataTable?.statistic_data?.call?.rate?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={4}>Tỉ lệ chốt (%)</td>
                <td className="green text-right">{dataTable?.statistic_data?.rate_success?.[0] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.rate_success?.[1] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.rate_success?.[2] || 0}</td>
                <td className="green text-right">{dataTable?.statistic_data?.rate_success?.[3] || 0}</td>
            </tr>

            <tr>
                <td rowSpan={13} className={`text-center align-middle`}>4</td>
                <td rowSpan={13} className={`text-center align-middle`}>HỌC VIÊN CHƯA CHỐT ĐƯỢC VÀ NGUYÊN NHÂN</td>
                <td colSpan={4}>Tổng học viên chưa chốt được</td>
                <td className="yellow text-right">{dataTable?.rejects?.total?.[0] || 0}</td>
                <td className="yellow text-right">{dataTable?.rejects?.total?.[1] || 0}</td>
                <td className="yellow text-right">{dataTable?.rejects?.total?.[2] || 0}</td>
                <td className="yellow text-right">{dataTable?.rejects?.total?.[3] || 0}</td>
            </tr>

            <tr>
                <td rowSpan={6} className={`text-center align-middle`}>Các nguyên nhân chính</td>
                <td colSpan={3}>Tài chính</td>
                <td className="text-right">{dataTable?.rejects?.reject?.finance?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.finance?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.finance?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.finance?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Tham khảo các trung tâm khác</td>
                <td className="text-right">{dataTable?.rejects?.reject?.check_out_other_centers?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects.reject?.check_out_other_centers?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.check_out_other_centers?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.check_out_other_centers?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Chưa thích học video</td>
                <td className="text-right">{dataTable?.rejects?.reject?.unlike_learning_videos?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.unlike_learning_videos?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.unlike_learning_videos?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.unlike_learning_videos?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Chưa liên hệ được</td>
                <td className="text-right">{dataTable?.rejects?.reject?.not_contact?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.not_contact?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.not_contact?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.not_contact?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Time chưa gấp nên chưa học ngay</td>
                <td className="text-right">{dataTable?.rejects?.reject?.time_is_not_urgent?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.time_is_not_urgent?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.time_is_not_urgent?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.time_is_not_urgent?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Đang xem xét và cân nhắc</td>
                <td className="text-right">{dataTable?.rejects?.reject?.considering_pondering?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.considering_pondering?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.considering_pondering?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.reject?.considering_pondering?.[3] || 0}</td>
            </tr>

            <tr>
                <td rowSpan={6} className={`text-center align-middle`}>Tỉ lệ (%)</td>
                <td colSpan={3}>Tài chính</td>
                <td className="text-right">{dataTable?.rejects?.rate?.finance?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.finance?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.finance?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.finance?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Tham khảo các trung tâm khác</td>
                <td className="text-right">{dataTable?.rejects?.rate?.check_out_other_centers?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.check_out_other_centers?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.check_out_other_centers?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.check_out_other_centers?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Chưa thích học video</td>
                <td className="text-right">{dataTable?.rejects?.rate?.unlike_learning_videos?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.unlike_learning_videos?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.unlike_learning_videos?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.unlike_learning_videos?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Chưa liên hệ được</td>
                <td className="text-right">{dataTable?.rejects?.rate?.not_contact?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.not_contact?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.not_contact?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.not_contact?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan={3}>Time chưa gấp nên chưa học ngay</td>
                <td className="text-right">{dataTable?.rejects?.rate?.time_is_not_urgent?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.time_is_not_urgent?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.time_is_not_urgent?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.time_is_not_urgent?.[3] || 0}</td>
            </tr>
            <tr>
                <td colSpan="3" className="yellow">Đang xem xét và cân nhắc</td>
                <td className="text-right">{dataTable?.rejects?.rate?.considering_pondering?.[0] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.considering_pondering?.[1] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.considering_pondering?.[2] || 0}</td>
                <td className="text-right">{dataTable?.rejects?.rate?.considering_pondering?.[3] || 0}</td>
            </tr>
            </tbody>
        </table>
    )
}
