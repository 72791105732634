import {snapshot} from "valtio";
import {questionProxy} from "../../store/proxy/question.proxy";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import ModalCustom from "../../components/Custom/ModalCustom";
import {isArray, isEmpty} from "lodash";
import {FormControl, FormGroup, RadioGroup, TextField} from "@mui/material";
import {useEffect, useState} from "react";

export default function TestModal({showModal, setShowModal}) {
    const {questionType} = snapshot(questionProxy)
    const [questions, setQuestion] = useState([]);
    const [formData, setFormData] = useState([]);
    let i = 0
    const getSurveyQuestion = () => {
        axiosCommon.get(ApiPath.SurveyQuestionsResult + "/" + showModal)
            .then((res) => {
                if (res.data?.survey_results) {
                    setQuestion(JSON.parse(res.data?.survey_qa))
                    setFormData(JSON.parse(res.data?.survey_results))
                }
            })
        return []
    }

    useEffect(() => {
        if (showModal) {
            getSurveyQuestion()
        }
    }, [showModal])

    return (
        <ModalCustom
            title={`Kết quả bài kiểm tra`}
            showModal={Boolean(showModal)}
            setShowModal={setShowModal}
            showCancelButton={false}
        >
            {questions.length > 0 ?
                <div>
                    {questions.map((question, index) => {
                        if (isEmpty(formData[index]?.value)) {
                            i++
                            return i === questions.length ?
                                <div className={`text-center`}>Chưa có kết quả chấm bài</div> : null
                        }
                        if (question.type === questionType[0]) {
                            return (
                                <div key={"question" + index} className={`form-field p-2 bg-white rounded`}>
                                    <div className={`mb-1 d-flex`}>
                                        <label htmlFor="name">Câu {index + 1}: {question.title}</label>
                                    </div>
                                    <div className={`mb-2`}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            fullWidth
                                            multiline
                                            variant="standard"
                                            value={formData[index]?.value}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        if (question.type === questionType[1]) {
                            return (
                                <div key={"question" + index} className={`form-field p-2 bg-white rounded`}>
                                    <div className={`d-flex`}>
                                        <label htmlFor="name">Câu {index + 1}: {question.title}</label>
                                    </div>
                                    <div>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                        >
                                            {isArray(question?.answers) &&
                                                question.answers.map((answer, ind) => {
                                                    if (formData[index]?.value !== answer) return
                                                    return <div>{answer}</div>
                                                })}
                                        </RadioGroup>
                                    </div>
                                </div>
                            )
                        }
                        if (question.type === questionType[2]) {
                            return (
                                <div key={"question" + index} className={`form-field p-2 bg-white rounded`}>
                                    <div className={`d-flex`}>
                                        <label htmlFor="name">Câu {index + 1}: {question.title}</label>
                                    </div>
                                    <div>
                                        <FormControl sx={{m: 1}} component="fieldset" variant="standard">
                                            <FormGroup>
                                                {question?.answers &&
                                                    question.answers.map((answer, ind) => {
                                                        if (!Boolean(formData[index]?.value?.["checkbox_" + ind])) return null
                                                        return <div>{answer}</div>
                                                    })
                                                }
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            )
                        }
                    })
                    }
                </div> :
                <div className={`text-center`}>Chưa có kết quả chấm bài</div>
            }
        </ModalCustom>
    )
}
