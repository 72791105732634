import React from "react";
import {Breadcrumb} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Routes} from "../../routes";

export default (props) => {
    const {firstLocation, secondLocation} = props

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item as={Link} to={Routes.Dashboard.path} active><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        { firstLocation && 
                        <Breadcrumb.Item as={Link} to={firstLocation.path} active>{firstLocation.name}</Breadcrumb.Item> 
                        }
                        { secondLocation && 
                        <Breadcrumb.Item as={Link} to="#" active>{secondLocation.name}</Breadcrumb.Item>
                        }
                    </Breadcrumb>
                    <h4>{secondLocation ? secondLocation.name: firstLocation.name}</h4>
                </div>
            </div>
        </>
    );
};
