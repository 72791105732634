import React, {Fragment, useEffect, useState} from "react";
import {ApiPath} from "../../apiPath";
import axiosCommon from '../../config/axiosCommon';
import {Col, Form, Row} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import {checkRoleNavBar, setPageTitle} from "../../common/function";
import DatePicker from "react-datepicker";
import moment from "moment";
import {toast} from "react-toastify";
import constants from "../../config/constants";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import CurrencyInput from "react-currency-input-field";

function ExternalRevenue() {
    const {currentUser} = useSnapshot(userProxy)
    const [companyTarget, setCompanyTarget] = useState({external_revenue: 0});
    const [dataOld, setDataOld] = useState({});
    const [dateSearch, setDateSearch] = useState(new Date());
    const [loaded, setLoaded] = useState(false);
    const inputs = {
        month_revenue: moment(new Date()).format("MM"),
        year_revenue: moment(new Date()).format("YYYY")
    }

    const searchGoal = (paramSearch = inputs) => {
        setLoaded(false);
        axiosCommon.get(ApiPath.externalRevenue, {params: paramSearch})
            .then((res) => {
                const targetCom = res.data?.[0]
                setCompanyTarget(targetCom || {external_revenue: 0})
                setDataOld(targetCom)
            })
            .finally(() => {
                setLoaded(true);
            });
    }

    useEffect(() => {
        setPageTitle('Doanh thu ngoài');
        searchGoal({
            month_revenue: moment(dateSearch).format("MM"),
            year_revenue: moment(dateSearch).format("YYYY")
        });
    }, [])

    const validateValue = (value) => {
        const rawValue = value === undefined ? 0 : value;
        if (Number(value) > constants.LIMIT_MONEY) {
            return;
        }
        setCompanyTarget({
            ...companyTarget,
            external_revenue: rawValue
        })
    };

    const updateTarget = (data) => {
        if (data?.external_revenue === dataOld?.external_revenue) return
        axiosCommon.post(ApiPath.externalRevenue, data)
            .then((res) => {
                searchGoal({
                        month_revenue: moment(dateSearch).format("MM"),
                        year_revenue: moment(dateSearch).format("YYYY")
                    }
                );
                toast.success('Lưu thành công!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    position: "top-right",
                    closeOnClick: true,
                })
            }).catch(err => {
            toast.error('Lưu thất bại, vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        })
            .finally(() => {
                setLoaded(true);
            });
    }

    const onSubmit = (e) => {
        searchGoal({
            month_revenue: moment(e).format("MM"),
            year_revenue: moment(e).format("YYYY")
        });
    }

    return (
        <Fragment>
            <Loading show={!loaded}/>
            <Row className="mb-1 border-b-1">
                <Col sm={8}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Tháng</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <DatePicker
                                name="date_search"
                                selected={dateSearch || new Date()}
                                onChange={(e) => {
                                    if (!e) return
                                    setDateSearch(e)
                                    onSubmit(e)
                                }}
                                className={`form-control`}
                                dateFormat={"MM/yyyy"}
                                showIcon
                                showMonthYearPicker
                                onClose={onSubmit}
                            />
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            <hr/>
            <div>
                <Row className="mb-1 border-b-1">
                    <Col sm={8}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Doanh số</Form.Label>
                            <Col sm={9}>
                                <CurrencyInput
                                    allowDecimals={false}
                                    disabled={!checkRoleNavBar([constants.roles.ADMIN.id, constants.roles.SALE_LEADER.id], currentUser.role_id)}
                                    className={`form-control`}
                                    onValueChange={validateValue}
                                    value={companyTarget.external_revenue}
                                    onBlur={(e) => {
                                        if (!checkRoleNavBar([constants.roles.ADMIN.id, constants.roles.SALE_LEADER.id], currentUser.role_id)) return;

                                        if (!companyTarget?.id) {
                                            updateTarget({
                                                ...companyTarget,
                                                month_revenue: moment(dateSearch).format("MM"),
                                                year_revenue: moment(dateSearch).format("YYYY"),
                                            })
                                            return
                                        }
                                        updateTarget(companyTarget)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
            </div>
        </Fragment>
    );
}

export default React.memo(ExternalRevenue)
