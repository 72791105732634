import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import {NavLink} from "react-router-dom";
import {BsPersonPlusFill, BsShieldLockFill} from "react-icons/bs";
import "../../scss/student.scss";
import {Button, Col, Form, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import axiosCommon from '../../config/axiosCommon';
import DeleteStudentModal from "../../components/students/DeleteStudentModal";
import constants from "../../config/constants";
import {
    addReturnUrlToParamString,
    changeQueryString,
    generateRandomPassword,
    getSearchParams,
    openStudentPdfView,
    setPageTitle,
    showToastSuccess
} from "../../common/function";
import AutoCreateAccountModal from "./AutoCreateAccountModal";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";

export default () => {
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [idStudent, setIdStudent] = useState();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(1);
    const [showAutoCreateAccountPopup, setShowAutoCreateAccountPopup] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [staff, setStaff] = useState(null)
    const [newPassword, setNewPassword] = useState('')
    const [staffs, setStaffs] = useState([]);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [isConfirmChangePasswordOpen, setIsConfirmChangePasswordOpen] = useState(false)

    const [inputs, setInputs] = useState({
        name_search: '',
        phone: '',
        email: '',
        per_page: constants.arrPerPage[0],
        page: 1,
    });
    const [key, setKey] = useState(Date.now());


    const handleAutoCreateAccountClick = (user) => {
        setCurrentUser(user)
        setShowAutoCreateAccountPopup(true)
    }

    const handleChangePasswordButtonClick = (row) => {
        setStaff(row)
        setNewPassword(generateRandomPassword())
        setIsConfirmChangePasswordOpen(true)
    }

    const [columns] = useState(
        [
            {
                name: 'STT',
                selector: (row, ind) => ind + 1,
                width: 5,
            },
            {
                name: 'Họ và tên',
                selector: row => row.full_name,
                sortable: true,
                grow: 2,

            },
            {
                name: 'SĐT',
                selector: row => row.phone,
                grow: 1,
            },
            {
                name: 'Email',
                selector: row => row.email,
                grow: 1,
            },
            {
                name: '',
                grow: 1.5,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa</Tooltip>}>
                                <NavLink className={'btn btn-info btn-sm me-1'}
                                         to={`${Routes.Students.path}/${row.id}?` + addReturnUrlToParamString()}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </NavLink>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Copy</Tooltip>}>
                                <NavLink className={'btn btn-success btn-sm me-1'}
                                         to={`${Routes.CopyStudent.path}/${row.id}?` + addReturnUrlToParamString()}>
                                    <i className="fa-regular fa-clone"></i>
                                </NavLink>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Xóa</Tooltip>}>
                                <Button className={'btn btn-danger btn-sm me-1'} onClick={() => openModal(row.id)}>
                                    <i className="fa-regular fa-trash-can"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Preview lịch học</Tooltip>}>
                                <Button className={'btn btn-warning btn-sm me-1'}
                                        onClick={() => openStudentPdfView(row.id)}>
                                    <i className="fas fa-print"></i>
                                </Button>
                            </OverlayTrigger>
                            {!row.user_id &&
                                <OverlayTrigger placement='top'
                                                overlay={<Tooltip id='tooltip-top'>Tự động tạo tài khoản</Tooltip>}>
                                    <Button className="btn btn-success btn-sm me-1"
                                            onClick={() => handleAutoCreateAccountClick(row)}>
                                        <i className="fa fa-user-shield"></i>
                                    </Button>
                                </OverlayTrigger>
                            }
                            {row.user_id &&
                                <OverlayTrigger placement='left' overlay={<Tooltip>Đổi mật khẩu</Tooltip>}>
                                    <Button className="btn btn-warning btn-sm"
                                            onClick={() => handleChangePasswordButtonClick(row)}>
                                        <BsShieldLockFill size={16} fill="white"/>
                                    </Button>
                                </OverlayTrigger>
                            }

                        </Fragment>
                    );
                },
            },
        ]
    );

    const newColumns2 = (col) => {
        const newArray = [...col];
        newArray[0] = {
            name: 'STT',
            selector: (row, ind) => ind + 1 + (inputs.page - 1) * 10,
            width: 5,
        };
        return newArray
    }

    const getColumnTableStudent = () => {
        let columnDisplay = columns
        columnDisplay = newColumns2(columns)

        return columnDisplay
    }

    const openModal = (id) => {
        setIdStudent(id);
        setOpen(true);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const getListStudent = (searchs = null) => {
        setIsLoading(true);
        let searchInfo = {...inputs}
        if (searchs) {
            searchInfo = {...searchs}
        } else {
            changeQueryString(searchInfo)
        }
        axiosCommon.get(ApiPath.students, {params: searchInfo})
            .then((res) => {
                setData(res.data.data.data)
                setTotalRows(res.data.data.total)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setPageTitle('Quản lý thời gian biểu');
        setKey(Date.now())
        const searchParams = getSearchParams()
        setInputs({...inputs, ...searchParams})
        getListStudent(searchParams);
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();
        getListStudent();
    }

    const deleteUser = () => {
        setIsLoading(true)
        axiosCommon.delete(ApiPath.deleteStudent + idStudent)
            .then((res) => {
                getListStudent();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };

    const handlePageChange = (page) => {
        const search = {
            ...inputs,
            page: page
        }
        setInputs(search)
        changeQueryString(search)
        getListStudent(search)
    }
    const handlePerPageChange = (newPerPage) => {
        const search = {
            ...inputs,
            per_page: newPerPage,
            page: 1
        }
        setInputs(search)
        changeQueryString(search)
        getListStudent(search)
    }

    const autoCreateAccount = (inputs) => {
        setIsLoading(true);
        axiosCommon.post(ApiPath.createAccount, currentUser)
            .then((res) => {
                showToastSuccess('Tạo tài khoảng cho nhân viên thành công.')
                getListStudent();
            }).catch((res) => {

        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleChangePasswordStaff = async ({user_id}, newPassword) => {
        setIsConfirmChangePasswordOpen(false)
        setIsLoading(true)
        const res = await axiosCommon.put(ApiPath.adminResetPassword + `/${user_id}`, {password: newPassword}).catch(() => null)
        if (res) {
            toast.success('Đổi mật khẩu thành công!', {
                autoClose: 3000,
                hideProgressBar: true,
                position: "top-right",
                closeOnClick: true,
            })
        } else {
            toast.error('Đổi mật khẩu thất bại, vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        }
        setIsLoading(false)
    }

    return (
        <Fragment>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.Students}/>
            <Row className="mb-5">
                <Col sm={12}>
                    <div className={'div-btn'}>
                        <NavLink className={'btn btn-tertiary background2e3650'}
                                 to={Routes.CreateStudent.path + '?' + addReturnUrlToParamString()}>
                            <BsPersonPlusFill className={'icon-add-student'}/>
                            <span>Thiết Lập Thời Gian Biểu Mới</span>
                        </NavLink>
                    </div>
                </Col>
            </Row>
            <Form onSubmit={onSubmit}>
                <Row className="mb-3">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Tên học viên</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="name_search" value={inputs.name_search}
                                              onChange={handleChange} placeholder="Nhập tên học viên"/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Email</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="email" value={inputs.email} onChange={handleChange}
                                              placeholder="Email học viên"/>
                            </Col>
                        </Form.Group>
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>SĐT</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="phone" value={inputs.phone} onChange={handleChange}
                                              placeholder="Số điện thoại"/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Button type="submit" className="btn btn-secondary float-end background2e3650">Tìm kiếm</Button>
                    </Col>
                </Row>
            </Form>
            <DataTable
                key={key}
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={{
                    rowsPerPageText: 'Số hàng mỗi trang:',
                    rangeSeparatorText: 'của',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Tất cả',
                }}
                onChangePage={handlePageChange}
                currentPage={inputs?.page}
                columns={getColumnTableStudent()}
                data={data}
                pagination
                title="Danh sách học viên"
                customStyles={customStyles}
                onChangeRowsPerPage={handlePerPageChange}
                paginationRowsPerPageOptions={constants.arrPerPage}
                paginationPerPage={parseInt(inputs?.per_page)}
                noDataComponent={<div className="p-4">Không tìm thấy học viên nào.</div>}
            />
            <DeleteStudentModal
                show={open}
                setShowModal={setOpen}
                actionOk={deleteUser}
                actionCancel={() => setIdStudent(0)}
            />
            <AutoCreateAccountModal
                show={showAutoCreateAccountPopup}
                setShowModal={setShowAutoCreateAccountPopup}
                actionOk={autoCreateAccount}
                actionCancel={() => setCurrentUser({})}
            />

            <Modal show={isConfirmChangePasswordOpen} onHide={() => setIsConfirmChangePasswordOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>"Xác nhận đổi mật khẩu"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Mật khẩu của "{staff?.full_name}" sẽ được đổi thành: </span><br/>
                    <span className="text-underline font-italic font-weight-normal">{newPassword}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setIsConfirmChangePasswordOpen(false)} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={() => handleChangePasswordStaff(staff, newPassword)}>OK</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
