const cookieHelper = {}

cookieHelper.setCookie = function (cname, cvalue, exSeconds) {
    const d = new Date()
    d.setTime(d.getTime() + (exSeconds * 1000))
    const expires = `expires=${d.toGMTString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
}

cookieHelper.getCookie = function (cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

cookieHelper.deleteCookie = function (cname) {
    this.setCookie(cname, '', -1)
}

export default cookieHelper
