import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import {NavLink, useNavigate} from "react-router-dom";
import "../../scss/student-info.scss";
import {ApiPath} from "../../apiPath";
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import {formatDate, setPageTitle, getReturnUrlFromParamString} from "../../common/function";
import axiosCommon from '../../config/axiosCommon';
import constants from "../../config/constants";
import DatePicker from "react-datepicker";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import ConfirmContinueModal from "../../components/student-infos/ConfirmContinueModal";

const CreateStudentInfo = () => {
    const navigate = useNavigate();
    const {saleList} = useSnapshot(userProxy)
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [inputs, setInputs] = useState({
        full_name: '',
        email: '',
        phone: '',
        contact_source: '',
        status:'',
        call_note:'',
        sale_note:'',
        MKT_note:'',
        call_day:'',
        call_hour:'',
        tuition_fees:'',
        writing_skill_test: '0',
        tuition_fee_paid:'',
        remaining_tuition_fees:'',
        tuition_payment_date:'',
		learning_path_date: '',
        status_reject:'',
        assigned_user:'',
		is_test_reminded: 0,
    });

    const handlerCreateStudentInfo = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setIsLoading(true);
        let studentInfo = {
            ...inputs,
            call_day: formatDate(inputs.call_day, '', 'YYYY-MM-DD'),
            tuition_payment_date: formatDate(inputs.tuition_payment_date, '', 'YYYY-MM-DD'),
            learning_path_date: formatDate(inputs.learning_path_date, '', 'YYYY-MM-DD'),
        }
        axiosCommon.post(ApiPath.studentInfos, studentInfo)
            .then((res) => {
                if (res.data.warning) {
                    setOpenConfirm(true)
                } else {
                    navigate(Routes.StudentInfos.path, {
                        state: {
                            notification: { type: 'success', message: "Tạo mới thông tin học viên thành công!" }
                        }
                    })
                }
            })
            .finally(()=> {
                setIsLoading(false);
            });
    }

    const continueSaveStudentInfo = () => {
        setIsLoading(true);
        let studentInfo = {
            ...inputs,
            call_day: formatDate(inputs.call_day, '', 'YYYY-MM-DD'),
            tuition_payment_date: formatDate(inputs.tuition_payment_date, '', 'YYYY-MM-DD'),
            learning_path_date: formatDate(inputs.learning_path_date, '', 'YYYY-MM-DD'),
            skip_warning: true
        }
        axiosCommon.post(ApiPath.studentInfos, studentInfo)
            .then((res) => {
                if (res.data.warning) {
                    setOpenConfirm(true)
                } else {
                    navigate(Routes.StudentInfos.path, {
                        state: {
                            notification: { type: 'success', message: "Tạo mới thông tin học viên thành công!" }
                        }
                    })
                }
            })
            .finally(()=> {
                setIsLoading(false);
            });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (value && event.target.attributes['number']?.value) {
            value = value.match(/\d/g)?.join('') ?? '';
        } else if (event.target.attributes['type']?.value == 'checkbox') {
			value = event.target.checked
		}
        setInputs(values => ({...values, [name]: value}))
    }

    const handleChangeDateTime = (data) => {
        setInputs(values => ({...values, ...data}))
    }

    const handleChangeFee = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        value = value ? value.match(/\d/g).join('') : value;
        setInputs(values => ({...values, [name]: value}))

        // Show fee
        let tuitionFees = name === 'tuition_fees' ? Number(value) : Number(inputs.tuition_fees)
        let tuitionFeePaid = name === 'tuition_fee_paid' ? Number(value) : Number(inputs.tuition_fee_paid)
        let remainingTuitionFees = tuitionFees - tuitionFeePaid
        setInputs(values => ({...values, remaining_tuition_fees: remainingTuitionFees}))
    }

    useEffect(() => {
        setPageTitle('Tạo mới thông tin học viên');
    }, [])

    return (
        <div>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.StudentInfos} secondLocation={Routes.CreateStudentInfo}/>
            <Form noValidate validated={validated} onSubmit={handlerCreateStudentInfo} id="submitForm">
                <Row>
                    <Col sm={4}>
                        <h5>Thông tin cơ bản</h5>
                    </Col>
                    <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                        <NavLink className={'btn btn-light me-2'} to={getReturnUrlFromParamString() ?? Routes.StudentInfos.path}>
                            Hủy
                        </NavLink>
                        <Button type="submit" variant="info">Lưu thông tin</Button>
                    </div>
                </Row>
                <hr className='col-md-10'/>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="full_name">
                            <Form.Label>Họ và tên <span className="text-danger">*</span></Form.Label>
                            <Form.Control required type="text" placeholder="Họ và tên" name="full_name" value={inputs.full_name} onChange={handleChange}/>
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng điền Họ và tên
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="phone">
                            <Form.Label>SĐT <span className="text-danger">*</span></Form.Label>
                            <Form.Control required number="true" type="text" placeholder="SĐT" maxLength='20' name="phone" value={inputs.phone} onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng điền SĐT
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="email">
                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control type="email" placeholder="Email" aria-describedby="inputGroupPrepend"
                                    required name="email" value={inputs.email} onChange={handleChange} />
                                <Form.Control.Feedback type="invalid">
                                    Xin vui lòng điền Email
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="contact_source">
                            <Form.Label>Nguồn liên lạc <span className="text-danger">*</span></Form.Label>
                            <Form.Select required name="contact_source" value={inputs.contact_source} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn nguồn liên lạc</option>
                                {
                                    constants.contact_sources.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng chọn nguồn liên lạc
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="status">
                            <Form.Label>Trạng thái</Form.Label>
                            <Form.Select name="status" value={inputs.status} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn trạng thái</option>
                                {
                                    constants.statuses.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="assigned_user">
                            <Form.Label>Người phụ trách</Form.Label>
                            <Form.Select name="assigned_user" value={inputs.assigned_user} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn người phụ trách</option>
                                {
                                    saleList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.full_name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        { constants.arr_reject_status_ids.includes(parseInt(inputs.status)) &&
                        <Form.Group as={Col} md="5" controlId="status_reject">
                            <Form.Label>Nguyên nhân từ chối <span className="text-danger">*</span></Form.Label>
                            <Form.Select required name="status_reject" value={inputs.status_reject} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn nguyên nhân</option>
                                {
                                    constants.status_rejects.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng chọn nguyên nhân từ chối
                            </Form.Control.Feedback>
                        </Form.Group>
                        }
                        { constants.arr_call_status_ids.includes(parseInt(inputs.status)) &&
                        <>
                        <Form.Group as={Col} md="3" controlId="status">
                            <Form.Label>Ngày gọi</Form.Label>
                            <DatePicker className="form-control" dateFormat={"dd/MM/yyyy"} showIcon placeholderText="Ngày xếp lịch gọi"
                                selected={inputs.call_day} onChange={value => handleChangeDateTime({call_day: value})}/>
                        </Form.Group>
                        <Form.Group as={Col} md="2" controlId="status">
                            <Form.Label>Giờ gọi</Form.Label>
                            <Form.Control type="time" name="call_hour" value={inputs.call_hour} onChange={handleChange}/>
                        </Form.Group>
                        </>
                        }
                        { constants.arr_test_status_ids.includes(parseInt(inputs.status)) &&
                        <>
                        <Form.Group as={Col} md="5" controlId="status">
                            <Form.Label>Dạng bài kiểm tra</Form.Label>
                            <div className="mt-2">
                                {
                                    constants.writing_skill_tests.map((item, index) => {
                                        return (
                                            <Form.Check key={index} inline label={item.title} name="writing_skill_test" type="radio" value={item.id}
                                             id={`writing_skill_test-${index}`} checked={inputs.writing_skill_test == item.id} onChange={handleChange}/>
                                        )
                                    })
                                }
                            </div>
                        </Form.Group>
                        </>
                        }
                        {constants.arr_learning_path_status_ids.includes(parseInt(inputs.status)) &&
                            <Form.Group as={Col} md="5" controlId="learning_path_date">
                                <Form.Label>Ngày làm lộ trình</Form.Label>
                                <DatePicker className="form-control"
                                            dateFormat={"dd/MM/yyyy"} showIcon placeholderText="Ngày làm lộ trình"
                                            selected={inputs.learning_path_date ? new Date(inputs.learning_path_date) : new Date()}
                                            onChange={value => handleChangeDateTime({learning_path_date: value})}/>
                            </Form.Group>
                        }
                        <Form.Group as={Col} md="5" controlId="is_test_reminded">
                            <Form.Label>Đã nhắc test</Form.Label>
                            <Form.Check type="switch" name="is_test_reminded" value="1" checked={inputs.is_test_reminded} onChange={handleChange} 
								/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3"></Row>
                </div>
                <Row>
                    <Col sm={4}>
                        <h5>Học phí</h5>
                    </Col>
                </Row>
                <hr className='col-md-10'/>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="tuition_fees">
                            <Form.Label>Tổng học phí
                            {constants.arr_tuition_fees_required_status_ids.includes(parseInt(inputs.status)) &&
                            	<span className="text-danger"> *</span>
                            }
                            </Form.Label>
                            <Form.Control required={constants.arr_tuition_fees_required_status_ids.includes(parseInt(inputs.status))} type="text" placeholder="Tổng học phí phải đóng" name="tuition_fees" 
                                value={inputs.tuition_fees && new Intl.NumberFormat().format(inputs.tuition_fees)} onChange={handleChangeFee}/>
							<Form.Control.Feedback type="invalid">
								Xin vui lòng điền Tổng học phí
							</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="tuition_fee_paid">
                            <Form.Label>Học phí đã nộp</Form.Label>
                            <Form.Control type="text" placeholder="Học phí đã nộp" name="tuition_fee_paid" 
                                value={inputs.tuition_fee_paid && new Intl.NumberFormat().format(inputs.tuition_fee_paid)} onChange={handleChangeFee}/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="remaining_tuition_fees">
                            <Form.Label>Học phí chưa nộp</Form.Label>
                            <Form.Control type="text" disabled placeholder="Học phí chưa nộp" name="remaining_tuition_fees" 
                            value={inputs.remaining_tuition_fees && new Intl.NumberFormat().format(inputs.remaining_tuition_fees)} onChange={handleChange}/>
                        </Form.Group>
                        { constants.arr_tuition_fees_status_ids.includes(parseInt(inputs.status)) &&
                        <Form.Group as={Col} md="5" controlId="tuition_payment_date">
                            <Form.Label>Ngày hẹn nộp học phí</Form.Label>
                            <DatePicker className="form-control" dateFormat={"dd/MM/yyyy"} showIcon placeholderText="Ngày xếp lịch gọi"
                                selected={inputs.tuition_payment_date} onChange={value => handleChangeDateTime({tuition_payment_date: value})}/>
                        </Form.Group>
                        }
                    </Row>
                </div>
                <Row>
                    <Col sm={4}>
                        <h5>Ghi chú</h5>
                    </Col>
                </Row>
                <hr className='col-md-10'/>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="call_note">
                            <Form.Label>Ghi chú cuộc gọi</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Ghi chú cuộc gọi" name="call_note" value={inputs.call_note} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="sale_note">
                            <Form.Label>Sale ghi chú</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Ghi chú của sale" name="sale_note" value={inputs.sale_note} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="MKT_note">
                            <Form.Label>MKT ghi chú</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Ghi chú MKT" name="MKT_note" value={inputs.MKT_note} onChange={handleChange} />
                        </Form.Group>
                    </Row>
                </div>
            </Form>
            <ConfirmContinueModal show={openConfirm} setShowModal={setOpenConfirm} actionOk={continueSaveStudentInfo} />
            <hr className='pt-3 col-md-10'/>
        </div>
    );
}

export default CreateStudentInfo;
