import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import "../../scss/student.scss";
import Loading from "../../components/main/Loading";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import axiosCommon from '../../config/axiosCommon';
import {addReturnUrlToParamString, checkLink, getNotificationContent, setPageTitle} from "../../common/function";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import constants from "../../config/constants";
import {cloneDeep, findIndex} from "lodash";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";
import {useSnapshot} from "valtio";
import {notificationProxy} from "../../store/proxy/notification.proxy";
import moment from "moment";


const useStyles = makeStyles()(() => ({
    icon: {
        position: 'relative',
        marginRight: '10px'
    },
    textNotification: {
        fontWeight: 'bold',
        fontSize: '14px',
        color: 'black'
    },
    dot: {
        width: '15px'
    },
    dotOutside: {
        position: 'absolute',
        top: '-10px',
        right: 0
    },
    dotHover: {
        backgroundColor: 'rgb(0, 191, 255)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    }

}));

export default function NotificationListAll() {
    const [isLoading, setIsLoading] = useState(false);
    const {dataNotification, setDataNotification} = useSnapshot(notificationProxy)


    const {classes} = useStyles();
    const navigate = useNavigate();

    const handleNotificationClick = ({id, link_url}, link = false) => {
        link_url = checkLink(link_url)
        if (link) {
            navigate(link_url + '?' + addReturnUrlToParamString())
        }
        axiosCommon.put(ApiPath.notifications + '/' + id, {
            status: constants.notification_status.SEEN
        })
            .then(() => {
                let dataClone = cloneDeep(dataNotification)
                let index = findIndex(dataClone, (val) => val.id === id)
                if (index > -1) {
                    dataClone[index].status = 0
                    setDataNotification([...dataClone])
                }
            })
            .catch(console.error)
    }

    const columns =
        [
            {
                name: '',
                cell: (row) => {
                    return (
                        <Fragment>
                            <div className={``} style={{width: '100%'}}
                                 onClick={() => handleNotificationClick(row, true)}>
                                <div
                                    className={`${(row.status === constants.notification_status.UNSEEN) && classes.textNotification}`}
                                >{getNotificationContent(row)}</div>
                                <div style={{fontSize: 10}}>{moment(row?.notification_date).format('DD/MM/YYYY')}</div>
                            </div>
                        </Fragment>
                    );
                },
            },
            {
                name: '',
                width: '1%',
                cell: (row) => {
                    return (
                        <Fragment>
                            <FiberManualRecordIcon
                                onClick={() => handleNotificationClick(row)}
                                style={{
                                    zIndex: 10,
                                    position: "absolute",
                                    top: '23%',
                                    right: '30%',
                                    cursor: 'pointer'
                                }}
                                className={`poi text-success align-self-center ${classes.dot} ${(row.status === constants.notification_status.SEEN) && 'invisible'}`}
                            />
                        </Fragment>
                    );
                },
            },
        ]

    useEffect(() => {
        setPageTitle('Danh sách thông báo');
    }, [])


    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
        rows: {
            style: {
                cursor: 'pointer',
                "&:hover": {
                    background: "#e4e4e4"
                },
            },
        },
    };
    return (
        <Fragment>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.Notification}/>
            <DataTable
                columns={columns}
                data={dataNotification}
                pagination
                customStyles={customStyles}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
                noDataComponent={<div className="p-4">Không có thông báo nào.</div>}
            />
        </Fragment>
    );
}
