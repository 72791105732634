import React, {useEffect, useRef} from 'react';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const LineChart = ({data}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const myChart = new Chart(chartRef.current, {
            type: 'line',
            showTooltips: false,
            data: {
                labels: data?.label,
                datasets: data?.data,
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        display: true
                    }
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                },
            },
        });

        return () => {
            myChart.destroy();
        };
    }, [data]);


    return (
        <div className={`p-2`}>
            <canvas ref={chartRef}/>
        </div>
    );
};

export default React.memo(LineChart);
