import React, {useEffect, useState, Fragment} from "react";
import DataTable from 'react-data-table-component';
import {Routes} from "../../routes";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import axiosCommon from '../../config/axiosCommon';
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import DeleteLessonModal from "../../components/lesson/DeleteLessonModal";
import AddLessonModal from "../../components/lesson/AddLessonModal";
import EditLessonModal from "../../components/lesson/EditLessonModal";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {ApiPath} from "../../apiPath";
import {setPageTitle, changeQueryString, getSearchParams} from "../../common/function";
import constants from "../../config/constants";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";

export default () => {
    const { id } = useParams();
    const {currentUser} = useSnapshot(userProxy)
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [inputs, setInputs] = useState({
        lesson_name: '',
        subskill_id: id,
    });
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [currentLesson, setCurrentLesson] = useState({});

    const [columns] = useState(
        [
            {
                name: 'ID',
                selector: row => row.id,
                width: 5,
                sortable: true,
            },
            {
                name: 'Tên bài học',
                selector: row => row.name,
                sortable: true,
                grow: 4,
            },
            {
                name: 'Tên Subskill',
                selector: row => row.subskill_name,
                sortable: true,
                grow: 3,
            },
            {
                name: 'Ngày tạo',
                selector: row => row.created_at,
                width: 50,
                sortable: true,
                center: true,
            },
            {
                name: '',
                width: 50,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Chỉnh sửa bài học</Tooltip>}>
                                <Button className={'btn btn-info btn-sm me-1'} onClick={() => processDisplayEditPopup(row)}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </Button>
                            </OverlayTrigger>
                            {currentUser?.role_id === constants.roles.ADMIN.id &&
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Xóa bài học</Tooltip>}>
                            <Button className={'btn btn-danger btn-sm me-1'} onClick={() => confirmDeleteLesson(row)}>
                                    <i className="fa-regular fa-trash-can"></i>
                            </Button>
                            </OverlayTrigger>
                            }
                        </Fragment>
                    );
                },
            },
        ]
    );
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const onSubmit = (e) => {
        e.preventDefault();
        searchLessons();
    }

    const searchLessons = (searchs = null) => {
        setLoaded(false);
		let searchInfo = {...inputs}
		if (searchs) {
			searchInfo = {...searchs}
		} else {
			changeQueryString(searchInfo)
		}
        axiosCommon.get(ApiPath.lessons, { params: searchInfo })
            .then((res) => {
                setData(res.data)
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }

    const confirmDeleteLesson = (lesson) => {
        setCurrentLesson(lesson)
        setShowDeletePopup(true)
    }

    const processDisplayEditPopup = (lesson) => {
        setCurrentLesson(lesson)
        setShowEditPopup(true)
    }

    const deleteLesson = () => {
        setLoaded(false);
        axiosCommon.delete(ApiPath.lessons + `/${currentLesson.id}`)
            .then((res) => {
                searchLessons();
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }

    const addLesson = (lesson) => {
        setLoaded(false);
        axiosCommon.post(ApiPath.lessons, {...lesson, subskill_id: id})
            .then((res) => {
                searchLessons();
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }
   
    const editLesson = (lesson) => {
        setLoaded(false);
        axiosCommon.put(ApiPath.lessons, {...lesson, subskill_id: id})
            .then((res) => {
                searchLessons();
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }

    useEffect(() => {
        setPageTitle('Quản lý bài học');
        if (!id) navigate(Routes.NotFound)
		const searchParams = { ...inputs, ...getSearchParams() }
		setInputs(searchParams)
        searchLessons(searchParams);
    },[])
    
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };
    
    return (
        <Fragment>
            <Loading show={!loaded}/>
            <Breadcrumbs firstLocation={Routes.Lessons}/>
            <Row className="mb-5">
                <Col sm={12}>
                    <Button className="btn btn-tertiary float-end" style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}} onClick={() => setShowAddPopup(true)}>
                        <i className="fa-solid fa-plus"></i>
                        Thêm mới bài học
                    </Button>
                </Col>
            </Row>
            <Form onSubmit={onSubmit}>
                <Row className="mb-3">
                    <Col sm={5}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Tên bài học</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="lesson_name" value={inputs.lesson_name} onChange={handleChange} placeholder="Tên bài học" />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={5}>
                        <Button type="submit" className="btn btn-secondary" style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}>Tìm kiếm</Button>
                    </Col>
                </Row>
            </Form>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
                title="Danh sách bài học"
                paginationRowsPerPageOptions={constants.arrPerPage}
                noDataComponent={<div className="p-4">Không tìm thấy bài học nào.</div>}
            />
            <DeleteLessonModal show={showDeletePopup} setShowModal={setShowDeletePopup} 
                actionOk={deleteLesson}  actionCancel={() => setCurrentLesson({})}/>
            { showAddPopup && <AddLessonModal show={showAddPopup} setShowModal={setShowAddPopup} actionOk={addLesson}/> }
            { showEditPopup && <EditLessonModal show={showEditPopup} setShowModal={setShowEditPopup} actionOk={editLesson} lesson={currentLesson}/> }
        </Fragment>
    );
}
