
import React from 'react';
import { Image } from 'react-bootstrap';

import ReactLogo from "../../assets/img/xuanphi-logo.png";

export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
        <Image className="preloader-element" src={ReactLogo} height={150} />
    </div>
  );
};
