import React, {Fragment, useEffect, useState} from "react";
import {Link, NavLink, useNavigate, useParams} from "react-router-dom";
import {ApiPath} from "../../apiPath";
import {Routes} from "../../routes";
import TimetableCourse from "./TimetableCourse";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {
    getCourseDatail,
    getReturnUrlFromParamString, openStudentPdfView,
    setPageTitle,
    setStudentCourseDetail, showToastSuccess,
} from "../../common/function";
import axiosCommon from '../../config/axiosCommon';
import Loading from "../../components/main/Loading";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../store/proxy/timeTable.proxy";
import {cloneDeep} from "lodash";
import {Grid} from "@mui/material";
import Button from "react-bootstrap/Button";
import {toast} from "react-toastify";
import {faAddressBook, faBackspace, faBackwardFast} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default () => {
    const {id} = useParams();
    const navigate = useNavigate()
    const [enteredName, setEnteredName] = useState('');
    const [enteredPhone, setEnteredPhone] = useState('');
    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredTimeStudy, setEnteredTimeStudy] = useState('');
    const [enteredTypeTimeStudy, setEnteredTypeTimeStudy] = useState('');
    const [enteredNote, setEnteredNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const handleGetStudent = () => {
        setIsLoading(true);
        axiosCommon.get(ApiPath.detailStudent + id)
            .then((res) => {
                const student = res.data;
                setEnteredName(student.data.full_name);
                setEnteredPhone(student.data.phone);
                setEnteredEmail(student.data.email);
                setEnteredTimeStudy(student.data.time_study ?? "");
                setEnteredTypeTimeStudy(student.data.type_time_study);
                setEnteredNote(student.data.note ?? "");
                setStudentCourseDetail(student.data.course_detail);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    async function handlerUpdateStudent(event) {
        event.preventDefault();
        setIsLoading(true);
        const studentEdit = {
            full_name: enteredName,
            phone: enteredPhone,
            email: enteredEmail,
            time_study: enteredTimeStudy,
            type_time_study: enteredTypeTimeStudy,
            note: enteredNote,
            course_detail: getCourseDatail()
        };
        axiosCommon.put(ApiPath.detailStudent + id, studentEdit)
            .then((res) => {
                    showToastSuccess('Cập nhật thời gian biểu thành công.')
                },
                error => {
                    toast.error('Cập nhật thời gian biểu thất bại, vui lòng thử lại!', {
                        autoClose: false,
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                    })
                })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        setPageTitle('Chi tiết khóa học');
        handleGetStudent()
    }, [])

    return (
        <div className={`p-3`}>
            <Loading show={isLoading}/>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <h4>{Routes.StudentDetail.name}</h4>
                <Link to="/" className="">
                <Button type="button" variant="outline-primary">
                    <FontAwesomeIcon icon={faBackspace} className="me-2"/>Back
                </Button>
                </Link>
            </div>
            <Form onSubmit={handlerUpdateStudent}>
                <Grid className='pt-3'>
                    <Row>
                        <Col sm={4}>
                            <h5>Thông tin cơ bản</h5>
                        </Col>
                        <Col className='div-btn fixed-bottom' style={{bottom: 75}}>
                            <Button type="submit" variant="info">Lưu</Button>
                        </Col>
                    </Row>
                    <hr/>
                </Grid>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Họ và tên</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Họ và tên"
                                value={enteredName}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>SĐT</Form.Label>
                            <Form.Control
                                required
                                disabled
                                type="number"
                                placeholder="SĐT"
                                value={enteredPhone}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                            <Form.Label>Email</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    disabled
                                    value={enteredEmail}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Thời gian học</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Thời gian học"
                                value={enteredTimeStudy}
                                disabled
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Đơn vị</Form.Label>
                            <Form.Select
                                disabled
                                name="type_time_study" value={enteredTypeTimeStudy}>
                                <option value="1">Ngày</option>
                                <option value="2">Tuần</option>
                                <option value="3">Tháng</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="8">
                            <Form.Label>Ghi Chú</Form.Label>
                            <Form.Control
                                disabled
                                as="textarea"
                                rows="3"
                                placeholder="Ghi Chú"
                                value={enteredNote}
                            />
                        </Form.Group>
                    </Row>
                </div>
            </Form>
            <TimetableCourse/>
        </div>
    );
};
