import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableReport from "./TableReport";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import {Grid} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {Button, Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {cloneDeep, isEmpty} from "lodash";
import moment from "moment";
import Loading from "../../components/main/Loading";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import {checkRoleNavBar, openRepostPdfView} from "../../common/function";
import {toast} from "react-toastify";
import GoalList from "../goal-setting/GoalList";
import SaleList from "../goal-setting/SaleList";
import {Routes} from "../../routes";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import ExternalRevenue from "../goal-setting/ExternalRevenue";
import constants from "../../config/constants";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";

const totalRevenueVal = ['Tài chính', 'Tham khảo thêm trung tâm khác', 'Không thích học video', 'Không liên lạc được', 'Thời gian chưa gấp nên chưa học ngay', 'Đang xem xét và cân nhắc']

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function a12yProps(index) {
    return {
        id: `simple-tab-repost-${index}`,
        'aria-controls': `simple-tabpanel-repost-${index}`,
    };
}

const styleLine = {
    fillColor: "#79D1CF",
    strokeColor: "#79D1CF",
    fill: false,
    borderColor: 'rgb(7, 192, 192)',
    tension: 0.1
}
export default function SynthesisReport() {
    const {currentUser} = useSnapshot(userProxy)
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = React.useState(0);
    const [value1, setValue1] = React.useState(0);
    const chartRef = useRef(null);
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [formSearch, setFormSearch] = useState([
        {start_date: firstDay, end_date: new Date()}
    ]);
    const [searchChart, setSearchChart] = useState({start_date: firstDay, end_date: new Date()});
    const [isDisableBtnSearch, setIsDisableBtnSearch] = useState(false)
    const [dataTable, setDataTable] = useState()
    const [dataLineSale, setDataLineSale] = useState({})
    const [dataPies, setDataPies] = useState([])
    const [dataPies1, setDataPies1] = useState([])
    const [dataLines, setDataLines] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChange1 = (event, newValue) => {
        setValue1(newValue);
    };

    const previewPDF = async () => {
        setIsLoading(true)
        const element = chartRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        setIsLoading(false)
        window.open(pdf.output('bloburl'), '_blank');
        // pdf.save('print.pdf');
    };

    const handleChangeSearch = (data, index) => {
        let newData = cloneDeep(formSearch)
        newData[index] = {
            ...newData[index],
            ...data,
        }
        setFormSearch([...newData])
    }

    const handleChangeSearchChart = (data) => {
        setSearchChart({
            ...searchChart,
            ...data
        })
    }

    const handleAddNewDate = () => {
        setFormSearch([...formSearch, {start_date: firstDay, end_date: new Date()}])
    }
    const handleDeleteDate = (index) => {
        formSearch.splice(index, 1)
        setFormSearch([...formSearch])
    }

    const handleSearch = () => {
        getDataTable()
    }
    const handleSearchChart = () => {
        getDataChart()
    }

    const getDataTable = () => {
        let newDateSearch = []
        formSearch.map((date) => {
            newDateSearch.push({
                'start-date': moment(date.start_date).format('YYYY-MM-DD'),
                'end-date': moment(date.end_date).format('YYYY-MM-DD'),
            })
        })
        setIsLoading(true)
        axiosCommon.get(ApiPath.companyReport, {
            params: {
                type: "",
                date: JSON.stringify(newDateSearch)
            }
        })
            .then(response => {
                setDataTable(response.data)
            }).catch(err => {
            toast.error('vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        })
            .finally(() => setIsLoading(false))
    }

    const getDataTablePDF = () => {
        let newDateSearch = []
        formSearch.map((date) => {
            newDateSearch.push({
                'start-date': date.start_date ? moment(date.start_date).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
                'end-date': date.end_date ? moment(date.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            })
        })
        setIsLoading(true)
        openRepostPdfView(newDateSearch)
        setIsLoading(false)
    }

    const getDataChart = () => {
        setIsLoading(true)
        axiosCommon.get(ApiPath.companyReportChart, {
            params: {
                'start-date': moment(searchChart.start_date).format("YYYY-MM-DD"),
                'end-date': moment(searchChart.end_date).format("YYYY-MM-DD"),
            }
        })
            .then(response => {
                convertData(response?.data)
            }).catch(err => {
            toast.error('vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        })
            .finally(() => setIsLoading(false))
    }

    const convertDataTarget = (data) => {
        let name = data?.employee?.full_name
        let label = []
        let dataPie = []
        if (name?.length > 0) {
            let sum = 0
            name.map((val, index) => {
                label.push(val)
                dataPie.push(data?.employee?.total?.[index])
                sum += data?.employee?.total?.[index]
            })
            if (data?.company - sum > 0) {
                label.push('Còn thiếu')
                dataPie.push(data?.company - sum)
            }
        }
        return {
            label: label,
            data: dataPie,
            title: "Doanh số của từng cá nhân so với KPI công ty",
            type: 'money'
        }
    }

    const convertDataCompany = (data) => {
        if (isEmpty(data)) return
        let dataChart = []
        let nameChart = {
            revenue: "Doanh số của cả công ty",
            test: "Số bài test gửi về",
            call: "Số cuộc gọi",
            sale: "Tỉ lệ chốt",
        }

        Object.keys(data).map((val, index) => {
            const type = Object.keys(data?.[val])[1]
            let labelData = val
            if (val === 'call') {
                dataChart.push(convertDataCall(data, nameChart[val]));
                return
            }
            if (val === 'test') {
                labelData = 'Số bài test'
            }
            if (val === 'sale') {
                labelData = 'Tỉ lệ chốt của sale'
            }
            if (val === 'revenue') {
                labelData = 'Doanh số'
            }

            dataChart.push({
                label: data?.[val]?.date,
                data: [{
                    ...styleLine,
                    data: data?.[val]?.[type],
                    label: labelData,
                }],
                title: nameChart[val]
            })
        });
        return dataChart
    }

    const convertDataCall = (data, title) => {
        let dataChart = []
        const revenue = data?.call
        if (!revenue?.full_name) return
        revenue.full_name.map((val, index) => {
            let red = (7 + index * 7) % 255;
            let green = (192 + index * 192) % 255;
            let blue = (192 + index * 150) % 255;
            if (!revenue?.full_name[index]) return
            dataChart.push({
                ...styleLine,
                data: revenue?.data[index]?.count_student_informations,
                label: val,
                borderColor: 'rgb(' + red + ',' + green + ',' + blue + ')',
            })
        });
        return {
            label: revenue?.data[0]?.date,
            title: title,
            data: dataChart
        }
    }

    const convertDataSale = (data) => {
        let dataChart = []
        const revenue = data?.revenue
        if (!revenue) return
        Object.keys(revenue).map((val, index) => {
            let red = (7 + index * 7) % 255;
            let green = (192 + index * 192) % 255;
            let blue = (192 + index * 150) % 255;

            dataChart.push({
                ...styleLine,
                data: revenue?.[val]?.sum_tuition_fee,
                label: data?.full_name[index],
                borderColor: 'rgb(' + red + ',' + green + ',' + blue + ')',
            })
        });

        return {
            label: revenue?.[0]?.selected_date,
            title: "Doanh số của sale",
            data: dataChart
        }
    }
    const convertData = (data) => {
        const dataTargetPie = convertDataTarget(data?.target)
        const dataTotalRevenue = {
            label: ['Hiện tại', 'Còn thiếu'],
            data: [data?.total_revenue?.total?.now, data?.total_revenue?.total?.target - data?.total_revenue?.total?.now > 0 ? data?.total_revenue?.total?.target - data?.total_revenue?.total?.now : 0],
            title: "Doanh số hiện tại so với target tháng",
            type: 'money'
        }

        let labelColl = Object.keys(data?.collected_data?.source_data)
        labelColl.forEach(function (value, index) {
            if (value === 'rest') {
                labelColl[index] = "khác";
            }
        });
        const dataCollPie = {
            label: labelColl,
            data: Object.values(data?.collected_data?.source_data),
            title: "Data từ các nguồn"
        }
        const dataRejectPie = {
            label: totalRevenueVal,
            data: Object.values(data?.reject?.rejects),
            title: "Học viên chưa chốt được và nguyên nhân"
        }
        setDataPies([dataCollPie, dataRejectPie])
        setDataPies1([dataTargetPie, dataTotalRevenue])
        setDataLines(convertDataCompany(data?.company))
        setDataLineSale(convertDataSale(data?.sales))
    }

    useEffect(() => {
        let i = 0
        formSearch.map((value) => {
            if (!value.start_date || !value.end_date || moment(value.start_date).diff(value.end_date, 'minutes') > 0) {
                i++
            }
        })
        if (i > 0) {
            setIsDisableBtnSearch(true)
        } else {
            setIsDisableBtnSearch(false)
        }
    }, [formSearch])


    useEffect(() => {
        getDataTable()
        getDataChart()
    }, [])

    return (
        <>
            <Breadcrumbs firstLocation={Routes.SynthesisReport}/>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Thiết lập mục tiêu" {...a11yProps(0)} />
                        <Tab label="Doanh số cá nhân" {...a11yProps(1)} />
                        <Tab label="Báo cáo số liệu" {...a11yProps(2)} />
                        <Tab label="Báo cáo biểu đồ" {...a11yProps(3)} />
                        {checkRoleNavBar([constants.roles.ADMIN.id], currentUser.role_id) &&
                            <Tab label="Doanh thu ngoài" {...a11yProps(4)} />
                        }
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <GoalList/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SaleList/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Loading show={isLoading}/>
                    {formSearch.map((search, index) => {
                        return (
                            <Row key={"search_" + index} className="mb-1 border-b-1">
                                <Col sm={4}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={6} className={`text-end`}>Ngày bắt đầu</Form.Label>
                                        <Col sm={6} className={`m-auto`}>
                                            <DatePicker
                                                name="start_date"
                                                selected={search?.start_date}
                                                onChange={(e) => {
                                                    handleChangeSearch({start_date: e}, index)
                                                }}
                                                className={`form-control`}
                                                dateFormat={"dd/MM/yyyy"}
                                                showIcon
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={6} className={`text-end`}>Ngày kết thúc</Form.Label>
                                        <Col sm={6} className={`m-auto`}>
                                            <DatePicker
                                                name="end_date"
                                                selected={search?.end_date}
                                                onChange={(e) => {
                                                    handleChangeSearch({end_date: e}, index)
                                                }}
                                                className={`form-control`}
                                                dateFormat={"dd/MM/yyyy"}
                                                showIcon
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                {index === formSearch.length - 1 && formSearch.length < 4 ?
                                    <Col sm={1}>
                                        <Button className={`btn btn-success`} onClick={handleAddNewDate}>
                                            <i className="fa-sharp fa-solid fa-plus"></i>
                                        </Button>
                                    </Col> :
                                    <Col sm={1}>
                                        <Button className={`btn btn-success`} onClick={() => handleDeleteDate(index)}>
                                            <i className="fa-solid fa-minus"></i>
                                        </Button>
                                    </Col>
                                }
                                {index === formSearch.length - 1 &&
                                    <Col sm={3}>
                                        <Button onClick={handleSearch} disabled={isDisableBtnSearch}
                                                className="btn btn-secondary float-end"
                                                style={{
                                                    backgroundColor: '#2e3650',
                                                    borderColor: '#2e3650',
                                                    color: 'white'
                                                }}
                                        >Tìm kiếm</Button>
                                    </Col>
                                }
                            </Row>
                        )
                    })}
                    <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                        <Button type="submit" onClick={() => {
                            getDataTablePDF()
                        }} className="me-4" variant="warning">Preview</Button>
                    </div>
                    <TableReport dataTable={dataTable}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Loading show={isLoading}/>
                    <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                        <Button type="submit" onClick={previewPDF} className="me-4" variant="warning">Preview</Button>
                    </div>
                    <Row className="mb-1 border-b-1">
                        <Col sm={4}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={6} className={`text-end`}>Ngày bắt đầu</Form.Label>
                                <Col sm={6} className={`m-auto`}>
                                    <DatePicker
                                        name="start_date"
                                        selected={searchChart?.start_date}
                                        onChange={(e) => {
                                            handleChangeSearchChart({start_date: e})
                                        }}
                                        className={`form-control`}
                                        dateFormat={"dd/MM/yyyy"}
                                        showIcon
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={6} className={`text-end`}>Ngày kết thúc</Form.Label>
                                <Col sm={6} className={`m-auto`}>
                                    <DatePicker
                                        name="end_date"
                                        selected={searchChart?.end_date}
                                        onChange={(e) => {
                                            handleChangeSearchChart({end_date: e})
                                        }}
                                        className={`form-control`}
                                        dateFormat={"dd/MM/yyyy"}
                                        showIcon
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={4}>
                            <Button onClick={handleSearchChart} className="btn btn-secondary float-end"
                                    style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}>Tìm
                                kiếm</Button>
                        </Col>
                    </Row>
                    <Box>
                        <div className={`justify-content-center d-flex`}>
                            <Tabs value={value1} onChange={handleChange1} aria-label="basic tabs center">
                                <Tab label="Biểu đồ dữ liệu" {...a12yProps(0)} />
                                <Tab label="Biểu đồ doanh số" {...a12yProps(1)} />
                            </Tabs>
                        </div>
                        <TabPanel value={value1} index={0}>
                            <Grid container ref={chartRef} className={`px-5 center`}>
                                <Grid item xs={12} className={`p-3`}>
                                    <h1 className={`m-auto`} align={"center"}>Biểu đồ dữ liệu</h1>
                                </Grid>
                                {dataPies.length > 0 && dataPies.map((dataPie, index) => {
                                    return (
                                        <Grid key={'pie_' + index} item xs={6} className={`p-6`}>
                                            <div><PieChart data={dataPie}/></div>
                                            <div align={"center"} className={`p-3`}
                                                 style={{fontStyle: "italic", fontSize: 14}}>
                                                {dataPie.title}
                                            </div>
                                        </Grid>
                                    )
                                })}

                                {dataLines.length > 0 && dataLines.map((dataLine, index) => {
                                    if (index === 0) return
                                    if (index === 3)
                                        return (
                                            <Grid container key={"line_" + index} xs={12}>
                                                <Grid item xs={3}/>
                                                <Grid key={"line_" + index} item xs={6} className={`pt-6`}>
                                                    <div><LineChart data={dataLine}/></div>
                                                    <div align={"center"}
                                                         style={{
                                                             fontStyle: "italic",
                                                             fontSize: 14
                                                         }}>{dataLine?.title}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3}/>
                                            </Grid>
                                        )
                                    return (
                                        <Grid key={"line_" + index} item xs={6} className={`p-3`}>
                                            <div><LineChart data={dataLine}/></div>
                                            <div align={"center"}
                                                 style={{fontStyle: "italic", fontSize: 14}}>{dataLine?.title}</div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value1} index={1}>
                            <Grid container ref={chartRef} className={`px-5 center`}>
                                <Grid item xs={12} className={`p-3`}>
                                    <h1 className={`m-auto`} align={"center"}>Biểu đồ doanh số</h1>
                                </Grid>
                                {dataPies1.length > 0 && dataPies1.map((dataPie, index) => {
                                    return (
                                        <Grid key={'pie_' + index} item xs={6} className={`p-6`}>
                                            <div><PieChart data={dataPie} type={dataPie?.type}/></div>
                                            <div align={"center"} className={`p-3`}
                                                 style={{fontStyle: "italic", fontSize: 14}}>
                                                {dataPie.title}
                                            </div>
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={2}/>
                                {dataLines.length > 0 &&
                                    <Grid item xs={8} className={`p-3`}>
                                        <div><LineChart data={dataLines[0]}/></div>
                                        <div align={"center"}
                                             style={{fontStyle: "italic", fontSize: 14}}>{dataLines[0]?.title}</div>
                                    </Grid>
                                }
                                <Grid item xs={2}/>
                                <Grid item xs={2}/>
                                <Grid item xs={8} className={`p-3`}>
                                    <div><LineChart data={dataLineSale}/></div>
                                    <div align={"center"}
                                         style={{fontStyle: "italic", fontSize: 14}}>{dataLineSale?.title}</div>
                                </Grid>
                                <Grid item xs={2}/>
                            </Grid>
                        </TabPanel>
                    </Box>
                </TabPanel>
                {checkRoleNavBar([constants.roles.ADMIN.id], currentUser.role_id) &&
                    <TabPanel value={value} index={4}>
                        <ExternalRevenue/>
                    </TabPanel>
                }
            </Box>
        </>
    );
}
