import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import NotificationsIcon from "@mui/icons-material/Notifications";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {makeStyles} from "tss-react/mui";
import constants from 'config/constants';
import axiosCommon from 'config/axiosCommon';
import {ApiPath} from 'apiPath';
import {Image} from "react-bootstrap";
import manager from '../../assets/img/manager.png';
import {Popover} from "antd";
import {cloneDeep, findIndex} from "lodash";
import {checkLink, getNotificationContent} from "../../common/function";
import {useSnapshot} from "valtio";
import {notificationProxy} from "../../store/proxy/notification.proxy";


const useStyles = makeStyles()(() => ({
    notification: {
        cursor: "pointer",
        width: 300,
        border: "solid 1px #e4e4e4",
        padding: "10px",

        "&:hover": {
            background: "#e4e4e4"
        },
        fontSize: '.85rem',
    },
    difftime: {
        fontSize: 10,
        color: "#a3a3a3",
    },
    icon: {
        position: 'relative',
        marginRight: '10px'
    },
    dot: {
        width: '15px'
    },
    dotOutside: {
        position: 'absolute',
        top: '-10px',
        right: 0
    },
    dotHover: {
        backgroundColor: 'rgb(0, 191, 255)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    }

}));

export default function NotificationList({dataNotifications}) {
    const {classes} = useStyles();
    const navigate = useNavigate();
    const root = useRef();
    const [open, setOpen] = useState(false);
    const {
        dataNotification,
        setDataNotification,
        setNumberNotificationUnseen,
        numberNotificationUnseen
    } = useSnapshot(notificationProxy)
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const handleNotificationClick = ({id, link_url}, link = false) => {
        link_url = checkLink(link_url)
        if (link) {
            navigate(link_url)
        }
        axiosCommon.put(ApiPath.notifications + '/' + id, {
            status: constants.notification_status.SEEN
        })
            .then(() => {
                let dataClone = cloneDeep(dataNotification)
                let index = findIndex(dataClone, (val) => val.id === id)
                if (index > -1) {
                    dataClone[index].status = 0
                    setDataNotification([...dataClone])
                    setNumber([...dataClone])
                }
            })
            .catch(console.error)
    }

    const content = (
        <div style={{maxHeight: 300, overflow: 'auto'}}>
            {
                (dataNotification && dataNotification.length)
                    ?
                    dataNotification.map((notification, index) => (
                        <div key={index} className={`position-relative`}>
                            <div className={`d-flex ${classes.notification}`}
                                 onClick={() => handleNotificationClick(notification, true)}>
                                <Image src={manager} className="user-avatar md-avatar rounded-circle me-3"/>
                                <div className='flex-grow-1'>{getNotificationContent(notification)}</div>
                            </div>
                            <FiberManualRecordIcon
                                onClick={() => handleNotificationClick(notification)}
                                style={{zIndex: 10, position: "absolute", top: '38%', right: 5, cursor: 'pointer'}}
                                className={`poi text-success align-self-center ${classes.dot} ${(notification.status === constants.notification_status.SEEN) && 'invisible'}`}
                            />
                        </div>
                    ))
                    :
                    <center className={`${classes.notification} py-3`}>Không có thông báo mới.</center>
            }
        </div>
    );

    const getListNotification = () => {

        const userId = sessionStorage?.id
        axiosCommon.get(ApiPath.notifications + '/' + userId)
            .then((res) => {
                setDataNotification(res.data.notifications)
                setNumber(res.data.notifications)
            })
    }

    const setNumber = (data) => {
        const filteredArr = data.filter(noti => noti.status === constants.notification_status.UNSEEN);
        const count = filteredArr.length;
        setNumberNotificationUnseen(count)
    }

    const removeParentheses = (str) => {
        const regex = /\([^)]*\)/g;
        return str.replace(regex, '');
    };

    useEffect(() => {
        getListNotification();
    }, [])

    useEffect(() => {
        setTimeout(() => {
            let currentTitle = document.title;
            currentTitle = removeParentheses(currentTitle)
            if (!numberNotificationUnseen) return document.title = currentTitle;
            const newTitle = numberNotificationUnseen > 9 ? '9+' : numberNotificationUnseen
            document.title = `(${newTitle})${currentTitle}`;
        })
    }, [numberNotificationUnseen, window.location.href]);

    return (
        <div>
            <Popover
                content={content}
                title={
                    <div className={`position-relative`}>
                        <div>Thông báo</div>
                        <div
                            style={{color: "blue", position: "absolute", right: 10, top: 0, cursor: "pointer"}}
                            onClick={() => navigate('/list-notification')}
                        >Xem tất cả
                        </div>
                    </div>
                }
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className={`${classes.icon} position-relative`} ref={root}>
                    <NotificationsIcon
                        className="me-2"
                        onClick={() => setOpen(!open)}
                    />
                    {numberNotificationUnseen > 0 &&
                        <div
                            style={{fontSize: 8, padding: 3, minWidth: 17, top: '-4px', right: 1}}
                            className={`position-absolute rounded-circle text-white bg-danger text-center`}
                        >{numberNotificationUnseen > 9 ? '9+' : numberNotificationUnseen}</div>
                    }
                </div>
            </Popover>
        </div>
    );
}
