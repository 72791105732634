import {proxy} from "valtio/vanilla";

const toastNotificationProxy = proxy({

    toastNotification: {},

    setToastNotification: (data) => {
        toastNotificationProxy.toastNotification = {...data}
    },

})

export {
    toastNotificationProxy
}
