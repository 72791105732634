import React, {memo, useEffect, useState} from "react";
import UnitTimeTable from "./UnitNumberTable";
import UnitNumberTable from "./UnitNameTable";
import {convertDataSubSkill, distributeUnit, getUnitDefault} from "../../../common/function";
import {cloneDeep, cloneDeepWith, isObject} from "lodash";
import WeekHead from "./WeekHead";
import {useSnapshot} from "valtio";
import {toastProxy} from "../../../store/proxy/toast.proxy";
import {timeTableProxy} from "../../../store/proxy/timeTable.proxy";
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ModalCustom from "../../Custom/ModalCustom";
import SetupUnitModal from "../../Custom/SetupUnitModal";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function SkillTable({skills, indexSkill, dataAllSubSkill}) {
    const {setContentToast} = useSnapshot(toastProxy)
    const {dataTimeTable, setDataTimeTable} = useSnapshot(timeTableProxy)
    const [unitAll, setUnitAll] = useState()
    const [arrUnit, setArrUnit] = useState()
    const [countNote, setCountNote] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showUnitModal, setShowUnitModal] = useState(false)

    const sync = () => {
        if (Number(unitAll) <= 0) {
            setContentToast('Vui lòng nhập đúng giá trị cho số Unit/buổi')
            return
        }

        let _dataTimeTable = cloneDeep(dataTimeTable)
        _dataTimeTable.map((value) => {
            if (value.skill === skills.skill) {
                value.detail.map((val) => {
                    val.unit = getUnitDefault(val.day, Number(unitAll))
                })
            }
        })
        setDataTimeTable([..._dataTimeTable])
        setUnitAll('')
    }

    const deleteLearningSkill = () => {
        let _dataTimeTable = cloneDeep(dataTimeTable)
        _dataTimeTable.splice(indexSkill, 1);
        setDataTimeTable([..._dataTimeTable])
    }

    const moveItem = (currentIndex, newIndex) => {
        if (newIndex < 0 || newIndex >= dataTimeTable.length) return;
        const _dataTimeTable = [...dataTimeTable];
        const item = _dataTimeTable[currentIndex];
        _dataTimeTable.splice(currentIndex, 1);
        _dataTimeTable.splice(newIndex, 0, item);

        setDataTimeTable(_dataTimeTable);
    }

    const handleClickLeft = (index) => {
        if (index === 0) {
            moveItem(index, dataTimeTable.length - 1);
        } else {
            moveItem(index, index - 1);
        }
    }

    const handleClickRight = (index) => {
        if (index === dataTimeTable.length - 1) {
            moveItem(index, 0);
        } else {
            moveItem(index, index + 1);
        }
    }

    const handleChangeSkill = (val) => {
        let _dataTimeTable = cloneDeep(dataTimeTable);
        _dataTimeTable[indexSkill].skill = val;
        setDataTimeTable(_dataTimeTable)
    }

    useEffect(() => {
        let newUnit = []
        let _skills = cloneDeep(skills)
        _skills.detail.map((skill) => {
                newUnit.push(...skill.unit)
            }
        )
        setArrUnit(cloneDeepWith(newUnit))
        const sumUnit = newUnit.reduce((total, num) => total + num, 0)
        let i = 0
        let notes = skills?.notes
        if (isObject(notes)) {
            Object.keys(notes).forEach(function (key, index) {
                if (notes[key] !== '' && index < sumUnit) {
                    i++
                }
            });
        }
        setCountNote(i);
    }, [skills])

    return (
        <div>
            <table className="table table-bordered bg-white m-0 text-center"
                   style={{position: 'sticky', top: 0, zIndex: 10}}>
                <thead>
                <tr style={{position: 'sticky', left: 0, zIndex: 1}}>
                    {
                        indexSkill === 0 &&
                        <th rowSpan={2} style={{
                            width: 189,
                            verticalAlign: "middle",
                            position: 'sticky',
                            left: 0,
                            zIndex: 1
                        }}
                        >Timetable</th>
                    }
                    <th className={`position-relative`}>
                        <ChevronLeftIcon
                            onClick={() => {
                                handleClickLeft(indexSkill, indexSkill - 1)
                            }}
                            style={{cursor: "pointer"}}
                            className={`border border-secondary bgLightBlueCustom position-absolute left-0 top-[8px]`}
                        />
                        <ChevronRightIcon
                            onClick={() => {
                                handleClickRight(indexSkill, indexSkill - 1)
                            }}
                            style={{cursor: "pointer"}}
                            className={`border border-secondary bgLightBlueCustom position-absolute right-0 top-[8px]`}
                        />
                        <h6>
                            <input
                                type='text'
                                className="text-center ms-2 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={skills.skill}
                                onChange={e => handleChangeSkill(e.target.value)}
                            /></h6>
                        <Button size="sm" variant="secondary" onClick={() => setShowUnitModal(true)}>Thiết lập
                            Unit</Button>
                        <Button size="sm" className="ms-1" variant="danger"
                                onClick={() => setShowDeleteModal(true)}>Xóa</Button>
                    </th>
                </tr>
                <tr>
                    <th className={`position-relative`}>Số Unit/buổi:
                        <input
                            type='number'
                            style={{width: 40, marginTop: 0}}
                            className="ms-2 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={unitAll}
                            onChange={e => setUnitAll(e.target.value)}
                        />
                        <OverlayTrigger placement='top'
                                        overlay={<Tooltip id='tooltip-top'>Thiết lập số Unit/buổi cho tất cả ngày
                                            học</Tooltip>}>
                            <i className="fa-solid fa-arrows-rotate text-info"
                               style={{cursor: 'pointer', fontSize: 13, marginLeft: 3}} onClick={sync}></i>
                        </OverlayTrigger>
                        <div className={`position-absolute`} style={{
                            bottom: 0,
                            right: 2,
                            fontSize: 14,
                            textTransform: 'capitalize',
                            fontWeight: 'normal',
                            color: "red"
                        }}>{countNote} Note
                        </div>
                    </th>
                </tr>
                </thead>
            </table>
            <div className={`d-flex`}>
                <div>
                    {skills.detail.map((skill, ind) => {
                        return (
                            <div className={`d-flex`} key={ind + 'skill_table'}>
                                {
                                    indexSkill === 0 &&
                                    <table className="table table-bordered bg-white m-0 text-center"
                                           style={{width: 40.2}}>
                                        <thead className="align-middle">
                                        <tr className={`border-bottom-0`}>
                                            <th style={{padding: 10}}>
                                                <WeekHead
                                                    week={skill.week}
                                                />
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                }
                                <div>
                                    <UnitTimeTable
                                        day={skill.day}
                                        unit={skill.unit}
                                        indexSkill={indexSkill}
                                        indexDetail={ind}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <UnitNumberTable
                        arrUnitIds={distributeUnit(arrUnit, skills?.unitId)}
                        arrSubSillIds={convertDataSubSkill(arrUnit, skills?.subSkillId)}
                        notes={skills?.notes}
                        indexSkill={indexSkill}
                    />
                </div>
            </div>
            <DeleteLearningSkillModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                actionOk={deleteLearningSkill}
            />
            <SetupUnitModal
                showModal={showUnitModal}
                setShowModal={setShowUnitModal}
                dataAllSubSkill={dataAllSubSkill}
                indexSkill={indexSkill}
            />
        </div>
    )
}

function DeleteLearningSkillModal({showModal, setShowModal, actionOk}) {
    return (
        <ModalCustom
            title={`Xác nhận xóa Kỹ năng học`}
            actionOk={actionOk}
            showModal={showModal}
            setShowModal={setShowModal}
        >
            <div>Toàn bộ dữ liệu học của kỹ năng này sẽ bị xóa và không thể hồi phục.</div>
            <div>Bạn có thực sự muốn xóa?</div>
        </ModalCustom>
    )
}

export default memo(SkillTable);
