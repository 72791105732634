import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import {NavLink} from "react-router-dom";
import "../../scss/student.scss";
import {Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import axiosCommon from '../../config/axiosCommon';
import {
    addReturnUrlToParamString,
    changeQueryString,
    formatDate,
    getSearchParams,
    getTitleFromId,
    setPageTitle
} from "../../common/function";
import constants from "../../config/constants";
import DatePicker from "react-datepicker";
import moment from "moment";
import {cloneDeep} from "lodash";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import Modal from "react-bootstrap/Modal";

const customStyles = {
    headCells: {
        style: {
            fontWeight: 'bold',
            fontSize: '16px',
        },
    },
};


export default function Test() {
    const {salesPlusList} = useSnapshot(userProxy)
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(null);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(1);
    const [key, setKey] = useState(Date.now());
    const [formSearch, setFormSearch] = useState({
        writing_skill_test: 'all',
        per_page: constants.arrPerPage[0],
        page: 1,
    });

    const handleChange = (data) => {
        setFormSearch({
            ...formSearch,
            ...data
        })
    }

    const [columns] = useState(
        [
            {
                name: 'Mã học viên',
                selector: row => row.student_information_id,
                sortable: true,
                grow: 1,
            },
            {
                name: 'Tên học viên',
                selector: row => row.full_name,
                grow: 2,
            },
            {
                name: 'SĐT',
                selector: row => row.phone ?? "-",
                grow: 1,
            },
            {
                name: 'Email',
                selector: row => row.email ?? "-",
                grow: 1,
                sortable: true,
            },
            {
                name: 'Người chữa test',
                selector: row => row.full_name_user ?? "-",
                grow: 1,
                sortable: true,
            },
            {
                name: 'Dạng bài kiểm tra',
                sortable: true,
                selector: row => getTitleFromId(row.writing_skill_test, constants.writing_skill_tests),
                grow: 1,
            },
            {
                name: 'Tình trạng chấm bài',
                selector: row => constants.gradingStatus[row.status_mark_test],
                grow: 1,
                sortable: true,
            },
            {
                name: 'Tình trạng chữa bài',
                selector: row => constants.unpostedStatus[row.status_correct_test],
                grow: 1,
                sortable: true,
            },
            {
                name: 'Ngày tạo',
                cell: row => formatDate(row.mark_and_correct_the_tests_created_at),
                selector: row => row.mark_and_correct_the_tests_created_at,
                grow: 1,
                sortable: true,
            },
            {
                name: '',
                width: 20,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa thông tin</Tooltip>}>
                                <NavLink className={'btn btn-info btn-sm me-1'}
                                         to={`/test/${row.id}?` + addReturnUrlToParamString()}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </NavLink>
                            </OverlayTrigger>
                            <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa người chữa bài</Tooltip>}>
                                <Button className={'btn btn-secondary btn-sm'} onClick={() => {
                                    setShowModal({
                                        id: row.id,
                                        user: row?.mark_and_correct_the_tests_assigned_user
                                    })
                                }}>
                                    <i className="fa-solid fa-user text-white"></i>
                                </Button>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
            },
        ]
    );

    const getListStudentInfo = (inputs = null) => {
        setIsLoading(true)
        let _formSearch = cloneDeep(formSearch)
        _formSearch = {
            ..._formSearch,
            start_date: _formSearch.start_date ? moment(_formSearch.start_date).format("YYYY-MM-DD") : '',
            end_date: _formSearch.end_date ? moment(_formSearch.end_date).format("YYYY-MM-DD") : '',
        }
        if (inputs) {
            _formSearch = {..._formSearch, ...inputs}
        } else {
            changeQueryString(_formSearch)
        }
        axiosCommon.get(ApiPath.markAndCorrect, {params: _formSearch})
            .then((res) => {
                setData(res.data.data)
                setTotalRows(res.data.total)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getSearchInfo = (searchs = null) => {
        let searchInfo = {
            ...formSearch,
            start_date: formSearch.start_date ? moment(formSearch.start_date).format("YYYY-MM-DD") : '',
            end_date: formSearch.end_date ? moment(formSearch.end_date).format("YYYY-MM-DD") : '',
        }
        if (searchs) {
            searchInfo = {...searchInfo, ...searchs}
        } else {
            changeQueryString(searchInfo)
        }
        return searchInfo
    }

    useEffect(() => {
        setPageTitle('Danh sách chấm và chữa bài kiểm tra đầu vào');
        const searchParams = getSearchParams()
        setFormSearch({
            ...formSearch,
            ...searchParams,
            start_date: searchParams.start_date ? new Date(searchParams.start_date) : '',
            end_date: searchParams.end_date ? new Date(searchParams.end_date) : '',
        })
        setKey(Date.now())
        getListStudentInfo(searchParams);
    }, [])

    const handlePageChange = (page) => {
        let searchParams = {
            ...formSearch,
            page: page
        }
        setFormSearch(searchParams)
        searchParams = {...getSearchInfo(), page: page}
        changeQueryString(searchParams)
        getListStudentInfo(searchParams)
    }
    const handlePerPageChange = (newPerPage) => {
        let searchParams = {
            ...formSearch,
            per_page: newPerPage,
            page: 1
        }
        setFormSearch(searchParams)
        searchParams = {...getSearchInfo(), per_page: newPerPage, page: 1}
        changeQueryString(searchParams)
        getListStudentInfo(searchParams)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        getListStudentInfo()
    }

    return (
        <Fragment>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.Test}/>
            <Form onSubmit={onSubmit}>
                <div className="row mb-3">
                    <div className="col">
                        <Form.Label>Tên học viên</Form.Label>
                        <Form.Control
                            type="text"
                            name="full_name"
                            value={formSearch?.full_name}
                            onChange={(e) => handleChange({full_name: e.target.value})}
                            placeholder="Nhập tên học viên"
                        />
                    </div>
                    <div className="col">
                        <Form.Label>SĐT</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={formSearch?.phone}
                            onChange={(e) => handleChange({phone: e.target.value})}
                            placeholder="Nhập số điện thoại"
                        />
                    </div>
                    <div className="col">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            value={formSearch?.email}
                            onChange={(e) => handleChange({email: e.target.value})}
                            placeholder="Nhập email học viên"
                        />
                    </div>
                    <div className="col">
                        <Form.Label>Người chữa test</Form.Label>
                        <Form.Select
                            aria-label="Default select example"
                            name={"assigned_user"}
                            value={formSearch?.assigned_user}
                            onChange={e => {
                                handleChange({assigned_user: e.target.value});
                            }}
                        >
                            <option value="" defaultValue>Tất cả người phụ trách</option>
                            <option value="none">Chưa có người phụ trách</option>
                            {
                                salesPlusList && salesPlusList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.full_name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Form.Label>Tình trạng chữa bài</Form.Label>
                        <Form.Select
                            aria-label="Default select example"
                            name={"status_correct_test"}
                            value={formSearch?.status_correct_test}
                            onChange={e => {
                                handleChange({status_correct_test: e.target.value});
                            }}
                        >
                            <option value="" defaultValue>Tình trạng chữa bài</option>
                            {constants.unpostedStatus.map((gra, index) => {
                                return (
                                    <option key={"gran" + index} value={index}>{gra}</option>
                                )
                            })}
                        </Form.Select>
                    </div>
                    <div className="col">
                        <Form.Label>Ngày bắt đầu</Form.Label>
                        <div class="w-100">
	                        <DatePicker
	                            className={`form-control`}
	                            dateFormat={"dd/MM/yyyy"}
	                            showIcon
	                            placeholderText={`--/--/----`}
	                            selected={formSearch?.start_date}
	                            onChange={(e) => handleChange({start_date: e})}
	                        />
                        </div>
                    </div>
                    <div className="col">
                        <Form.Label>Ngày kết thúc</Form.Label>
                        <div class="w-100">
	                        <DatePicker
	                            className={`form-control`}
	                            dateFormat={"dd/MM/yyyy"}
	                            placeholderText={`--/--/----`}
	                            showIcon
	                            selected={formSearch?.end_date}
	                            onChange={(e) => handleChange({end_date: e})}
	                        />
                        </div>
                    </div>
                    <div className="col">
                        <Form.Label>Tình trạng chấm bài</Form.Label>
                        <Form.Select
                            aria-label="Default select example"
                            name={"status_mark_test"}
                            value={formSearch?.status_mark_test}
                            onChange={e => {
                                handleChange({status_mark_test: e.target.value});
                            }}
                        >
                            <option value="" defaultValue>Tình trạng chấm bài</option>
                            {constants.gradingStatus.map((gra, index) => {
                                return (
                                    <option key={"gran" + index} value={index}>{gra}</option>
                                )
                            })}
                        </Form.Select>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <Form.Label>Dạng bài kiểm tra</Form.Label>
                        <div>
                            <Form.Check inline label="Tất cả" name="writing_skill_test" type="radio" value={'all'}
                                        id="writing_skill_test-all"
                                        checked={formSearch?.writing_skill_test === 'all'}
                                        onChange={e => {
                                            handleChange({writing_skill_test: e.target.value});
                                        }}/>
                            {
                                constants.writing_skill_tests.map((item, index) => {
                                    return (
                                        <Form.Check key={index} inline label={item.title} name="writing_skill_test"
                                                    type="radio" value={item.id}
                                                    id={`writing_skill_test-${index}`}
                                                    checked={formSearch?.writing_skill_test == item.id}
                                                    onChange={e => {
                                                        handleChange({writing_skill_test: e.target.value});
                                                    }}/>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col"></div>
                    <div className="col">
                        <Button type="submit" className="btn btn-secondary float-end"
                                style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}>Tìm
                            kiếm</Button>
                    </div>
                </div>
            </Form>
            <DataTable
                key={key}
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={{
                    rowsPerPageText: 'Số hàng mỗi trang:',
                    rangeSeparatorText: 'của',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Tất cả',
                }}
                onChangePage={handlePageChange}
                currentPage={formSearch?.page}
                columns={columns}
                data={data}
                pagination
                title="Danh sách học viên"
                customStyles={customStyles}
                onChangeRowsPerPage={handlePerPageChange}
                paginationRowsPerPageOptions={constants.arrPerPage}
                paginationPerPage={parseInt(formSearch?.per_page)}
                noDataComponent={<div className="p-4">Không tìm thấy bài kiểm tra nào.</div>}
            />
            {showModal?.id &&
                <EditUserModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    getListStudentInfo={getListStudentInfo}
                />
            }
        </Fragment>
    );
}

function EditUserModal({showModal, setShowModal, getListStudentInfo}) {

    const [assignedUser, setAssignedUser] = useState()
    const {salesPlusList} = useSnapshot(userProxy)
    const handleChangeAssignUser = async () => {
        const data = {assigned_user_id: assignedUser, type: "changeAssignedUser"}
        const res = await axiosCommon.put(ApiPath.markAndCorrect + "/" + showModal?.id, data)
            .catch(() => null)
        if (res) {
            setShowModal(null)
            await getListStudentInfo()
        }
    }

    useEffect(() => {
        if (!showModal?.user) return
        setAssignedUser(showModal?.user)
    }, [showModal])

    return (
        <Modal show={Boolean(showModal)} onHide={() => setShowModal(null)}>
            <Modal.Header closeButton className="d-flex align-content-center">
                <Modal.Title>Thay đổi người chữa bài</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-2 flex-column px-3">
                <Form.Select
                    type="text"
                    placeholder="Người làm lộ trình"
                    value={assignedUser}
                    onChange={e => setAssignedUser(e.target.value)}
                >
                    <option value="">Người chữa bài</option>
                    {salesPlusList.map(sale => <option value={sale.id} key={sale.id}>{sale.full_name}</option>)}
                </Form.Select>
                <Button className="mt-3 mb-4" variant='info' onClick={handleChangeAssignUser}>Thay đổi</Button>
            </Modal.Body>
        </Modal>
    )
}

