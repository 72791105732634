import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';

export default (props) => {
    const {
        show,
        setShowModal,
        listFeePaids,
		actionOk,
    } = props

    const [columns] = useState(
        [
            {
                name: 'No',
                selector: row => row.row_no,
                sortable: true,
                width: 10,
            },
            {
                name: 'Ngày nộp',
                sortable: true,
                selector: row => row.create_date,
                grow: 2,
            },
            {
                name: 'Số tiền nộp',
                selector: row => new Intl.NumberFormat().format(row.tuition_fee_paid),
                grow: 2,
            },
            {
                name: 'Người phụ trách',
                sortable: true,
                selector: row => row.user_full_name,
                grow: 2,
            },
            {
                name: 'Ghi chú',
                sortable: true,
                selector: row => row.tuition_fee_paid < 0 ? 'Hoàn học phí' : '',
                grow: 2,
            },
        ]
    );

    const handleClose = () => {
		setShowModal(false);
	};

    const handleOk = () => {
        actionOk()
        handleClose()
    };
    
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title>Lịch sử đóng học phí</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        columns={columns}
                        data={listFeePaids}
                        // pagination
                        title="Bảng lịch sử đóng học phí"
                        customStyles={customStyles}
                        noDataComponent={<div className="p-4">Không tìm thấy dữ liệu nào.</div>}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
