import React, {useEffect, useState} from 'react';
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import {Button, Form, Modal} from "react-bootstrap";

function EditAssignedUserModal({show, setShowModal, currentRow, actionOk, actionCancel = ''}) {
    const [assignedUser, setAssignedUser] = useState()
    const {saleList} = useSnapshot(userProxy)

    useEffect(() => {
        if (show) {
            setAssignedUser(currentRow.assigned_user ?? '')
        }
    }, [show])

    const handleClose = () => {
        if (actionCancel) {
            actionCancel();
        }
        setShowModal(false);
    };

    const handleOk = () => {
        actionOk(assignedUser)
        handleClose()
    };

    return (
        <Modal show={show} onHide={() => setShowModal(null)}>
            <Modal.Header closeButton className="d-flex align-content-center">
                <Modal.Title>Thay đổi người phụ trách</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-2 flex-column px-3">
                <Form.Select name="assignedUser" value={assignedUser} onChange={e => setAssignedUser(e.target.value)}>
                    <option value="" defaultValue>Vui lòng chọn người phụ trách</option>
                    {
                        saleList.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>{item.full_name}</option>
                            )
                        })
                    }
                </Form.Select>
                <Button className="mt-3 mb-4" variant='info' onClick={handleOk}>Thay đổi</Button>
            </Modal.Body>
        </Modal>
    )
}

export default EditAssignedUserModal;
