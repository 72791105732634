import React, {useEffect, useState} from "react";
import {NavLink, useParams, useNavigate} from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import {BsArrowReturnLeft} from "react-icons/bs"

import Loading from "components/main/Loading";
import Breadcrumbs from "components/main/Breadcrumbs";
import {Routes} from "routes";
import {setPageTitle, getReturnUrlFromParamString} from "common/function";
import constants from "config/constants"
import { useFormik } from "formik";
import * as Yup from "yup"
import axiosCommon from "config/axiosCommon";
import { ApiPath } from "apiPath";
import { toast } from 'react-toastify';

export default function EditStaff() {
	const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams()

    const handleFormSubmit = async () => {
		setIsLoading(true)
		const res = await axiosCommon.put(ApiPath.staffs + `/${id}`, formik.values)
			.catch(e => e.response.data)
		setIsLoading(false)
		if(!res.error) {
			navigate(getReturnUrlFromParamString() ?? Routes.Staffs.path, {
				state: {
					notification: {
						type: 'success',
						message: 'Cập nhật nhân viên thành công!'
					}
				}
			})
		} else {
			const errors = {
				email: 'Email đã tồn tại!',
				user_name: 'Username đã tồn tại!'
			}
			for(let error in errors) {
				if(res.error[error]) {
					toast.error(errors[error], {
						autoClose: false,
						position: "top-right",
						hideProgressBar: true,
						closeOnClick: true,
					})
				}
			}
		}
    }
  
	const formik = useFormik({
		initialValues: {
			user_name: '',
			full_name: '',
			phone: '',
			email: '',
			role_id: constants.roles.SALE.id,
			is_active: false
		}, 
		validationSchema: Yup.object({
			user_name: Yup.string()
				.required('Vui lòng cung cấp tên tài khoản!'),
			full_name: Yup.string()
				.required('Vui lòng cung cấp tên nhân viên'),
			phone: Yup.string()
				.matches(/^\+?[\d- ]+$/, 'Số điện thoại không hợp lệ!')
				.required('Vui lòng cung cấp số điện thoại!'),
			email: Yup.string()
				.email('Email không hợp lệ!')
				.required('Vui lòng cung cấp email!')
		}),
		onSubmit: handleFormSubmit
	});

	const fetchStaff = async (id) => {
		setIsLoading(true)
		const res = await axiosCommon.get(ApiPath.staffs + `/${id}`).catch(() => null)
		if(res) {
			formik.setValues(res.data.data)
		} else {
			toast.error('Vui lòng tải lại trang!', {
				autoClose: false,
				position: "top-right",
				hideProgressBar: true,
				closeOnClick: true,
			})
		}
		setIsLoading(false)
	}


	useEffect(() => {
		setPageTitle('Chỉnh sửa nhân viên');
		fetchStaff(id)
	}, []);

	return (
		<>
			<Loading show={isLoading}/>
			<div className="row mb-3">
				<div className="col">
					<Breadcrumbs firstLocation={Routes.EditStaff}/>
				</div>
				<div className="col d-flex justify-content-end align-items-center">
					<NavLink className="btn btn-danger d-flex align-items-center" to={getReturnUrlFromParamString() ?? Routes.Staffs.path}>
						<BsArrowReturnLeft size={20}/> &nbsp; Quay lại
					</NavLink>
				</div>
			</div>
			<Form onSubmit={formik.handleSubmit}>
				<h5 className="text-center">Thông tin nhân viên</h5>
				<div className="border border-primary p-5 mx-4 rounded bg-white">
					<div className="row gap-3 mb-4">
						<div className="col">
							<Form.Label>Tên nhân viên</Form.Label>
							<Form.Control
								type="text"
								placeholder="Tên nhân viên"
								name="full_name"
								value={formik.values.full_name}
								onChange={formik.handleChange}
								isInvalid={formik.touched.full_name && formik.errors.full_name}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.full_name}</Form.Control.Feedback>
						</div>
					
						<div className="col">
							<Form.Label>Số điện thoại</Form.Label>
							<Form.Control
							type="text"
							placeholder="Số điện thoại"
							name="phone"
							value={formik.values.phone}
							onChange={formik.handleChange}
							isInvalid={formik.touched.phone && formik.errors.phone}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
						</div>
					</div>
					<div className="row gap-3">
						<div className="col">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="text"
								placeholder="Email"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								isInvalid={formik.touched.email && formik.errors.email}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
						</div>
						<div className="col"></div>
					</div>
				</div>

				<h5 className="text-center mt-5">Thông tin tài khoản và quyền hạn</h5>
				<div className="border border-primary p-5 mx-4  rounded bg-white">
					<div className="row mb-4 gap-3">
						<div className="col">
							<Form.Label>Tên tài khoản</Form.Label>
							<Form.Control
								type="text"
								placeholder="Tên tài khoản"
								name="user_name"
								value={formik.values.user_name}
								onChange={formik.handleChange}
								isInvalid={formik.touched.user_name && formik.errors.user_name}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.user_name}</Form.Control.Feedback>
						</div>
					
						<div className="col">
							<Form.Label>Quyền hạn</Form.Label>
							<Form.Select
								type="text"
								placeholder="Quyền hạn"
								name="role_id"
								value={formik.values.role_id}
								onChange={formik.handleChange}
							>
								{Object.entries(constants.roles).map(([key, role]) => <option value={role.id} key={role.id}>{role.name}</option>)}
							</Form.Select>
						</div>
					</div>
					<div className="row gap-3">
						<div className="col">
							<Form.Label>Kích hoạt</Form.Label>
							<Form.Check
								type="switch"
								name="is_active"
								checked={formik.values.is_active}
								onChange={formik.handleChange}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-4 mx-4 gap-3">
					<Button type="submit" className="btn btn-success">Cập nhật</Button>
				</div>
			</Form>
		</>
	)
}
