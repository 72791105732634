import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import axiosCommon from '../../config/axiosCommon';
import {Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import {checkRoleNavBar, setPageTitle} from "../../common/function";
import DatePicker from "react-datepicker";
import moment from "moment";
import ModalCustom from "../../components/Custom/ModalCustom";
import {toast} from "react-toastify";
import constants from "../../config/constants";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import CurrencyInput from "react-currency-input-field";

function GoalList() {
    const {currentUser} = useSnapshot(userProxy)
    const [data, setData] = useState([]);
    const [companyTarget, setCompanyTarget] = useState({target_of_month: 0});
    const [dataOld, setDataOld] = useState({});
    const [dateSearch, setDateSearch] = useState(new Date());
    const [loaded, setLoaded] = useState(false);
    const inputs = {
        month_sale: moment(new Date()).format("MM"),
        year_sale: moment(new Date()).format("YYYY")
    }
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [currentGoal, setCurrentGoal] = useState({});

    const [columns] = useState(
        [
            {
                name: 'STT',
                selector: (row, ind) => ind + 1,
                width: '85px',
                sortable: true,
            },
            {
                name: 'Tên Sale',
                selector: row => row.full_name,
                grow: 3,
            },
            {
                name: 'Mục tiêu',
                selector: row => row.target_of_month ? new Intl.NumberFormat().format(row.target_of_month) : 0,
                sortable: true,
                width: 50
            },
            {
                name: '',
                width: 50,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top'
                                            overlay={<Tooltip id='tooltip-top'>Chỉnh sửa</Tooltip>}>
                                <Button className={'btn btn-info btn-sm me-1'}
                                        onClick={() => processDisplayEditPopup(row)}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </Button>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
            },
        ]
    );

    const onSubmit = (e) => {
        searchGoal({
            month_sale: moment(e).format("MM"),
            year_sale: moment(e).format("YYYY")
        });
    }

    const searchGoal = (paramSearch = inputs) => {
        setLoaded(false);
        axiosCommon.get(ApiPath.goal, {params: paramSearch})
            .then((res) => {
                setData(res.data.sales);
                const targetCom = res.data?.company
                setCompanyTarget(targetCom[targetCom.length - 1] || {target_of_month: 0})
                setDataOld(targetCom[targetCom.length - 1] || 0)
            })
            .finally(() => {
                setLoaded(true);
            });
    }

    const processDisplayEditPopup = (data) => {
        setCurrentGoal({
            ...data,
            target_of_month: data?.target_of_month ? data?.target_of_month : 0
        })
        setShowEditPopup(true)
    }

    const updateTarget = (data) => {
        if (data.target_of_month === dataOld.target_of_month) return
        axiosCommon.post(ApiPath.goal, data)
            .then((res) => {
                searchGoal({
                        month_sale: moment(dateSearch).format("MM"),
                        year_sale: moment(dateSearch).format("YYYY")
                    }
                );
                toast.success('Lưu thành công!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    position: "top-right",
                    closeOnClick: true,
                })
            }).catch(err => {
            toast.error('Lưu thất bại, vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        })
            .finally(() => {
                setLoaded(true);
            });
    }
    const editGoal = (data) => {
        let newData = {
            id: data?.target_id,
            user_id: data?.id,
            target_of_month: Number(data?.target_of_month),
        }
        if (!data?.target_id) {
            newData = {
                id: data?.target_id,
                user_id: data?.id,
                type_target: constants.typeTarget.user,
                target_of_month: Number(data?.target_of_month),
                month_sale: moment(dateSearch).format("MM"),
                year_sale: moment(dateSearch).format("YYYY"),
            }
        }
        updateTarget(newData)
    }


    useEffect(() => {
        setPageTitle('Báo cáo tổng hợp');
        searchGoal({
            month_sale: moment(dateSearch).format("MM"),
            year_sale: moment(dateSearch).format("YYYY")
        });
    }, [])

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };

    const validateValue = (value) => {
        const rawValue = value === undefined ? 0 : value;
        if (Number(value) > constants.LIMIT_MONEY) {
            return;
        }
        setCompanyTarget({
            ...companyTarget,
            target_of_month: rawValue
        })
    };

    return (
        <Fragment>
            <Loading show={!loaded}/>
            {/*<Breadcrumbs firstLocation={Routes.GoalSetting}/>*/}
                <Row className="mb-1 border-b-1">
                    <Col sm={8}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Tháng</Form.Label>
                            <Col sm={9} className={`m-auto`}>
                                <DatePicker
                                    name="date_search"
                                    selected={dateSearch || new Date()}
                                    onChange={(e) => {
                                        if (!e) return
                                        setDateSearch(e)
                                        onSubmit(e)
                                    }}
                                    className={`form-control`}
                                    dateFormat={"MM/yyyy"}
                                    showIcon
                                    showMonthYearPicker
                                    onClose={onSubmit}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
            <div>
                <Row className="mb-1 border-b-1">
                    <Col sm={8}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Doanh số</Form.Label>
                            <Col sm={9}>
                                <CurrencyInput
                                    allowDecimals={false}
                                    disabled={!checkRoleNavBar([constants.roles.ADMIN.id, constants.roles.SALE_LEADER.id], currentUser.role_id)}
                                    className={`form-control`}
                                    onValueChange={validateValue}
                                    value={companyTarget.target_of_month}
                                    onBlur={(e) => {
                                        if (!checkRoleNavBar([constants.roles.ADMIN.id, constants.roles.SALE_LEADER.id], currentUser.role_id)) return;
                                        if (!companyTarget?.id) {
                                            updateTarget({
                                                ...companyTarget,
                                                user_id: -1,
                                                type_target: constants.typeTarget.company,
                                                month_sale: moment(dateSearch).format("MM"),
                                                year_sale: moment(dateSearch).format("YYYY"),
                                            })
                                            return
                                        }
                                        updateTarget(companyTarget)
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
            </div>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
                title="Danh sách mục tiêu"
                paginationRowsPerPageOptions={constants.arrPerPage}
                noDataComponent={<div className="p-4">Không tìm thấy mục tiêu nào.</div>}
            />
            <EditTargetModal
                show={showEditPopup}
                setShowModal={setShowEditPopup}
                actionOk={editGoal}
                currentGoal={currentGoal}
                monthSale={moment(dateSearch).format("MM")}
                yearSale={moment(dateSearch).format("YYYY")}
            />
        </Fragment>
    );
}

const EditTargetModal = (props) => {
    const {show, setShowModal, actionOk, currentGoal, monthSale, yearSale} = props
    const [newData, setNewData] = useState({target_of_month: 0})

    const validateValue = (value) => {
        const rawValue = value === undefined ? 0 : value;
        if (Number(value) > constants.LIMIT_MONEY) {
            return;
        }
        setNewData({
            ...currentGoal,
            target_of_month: rawValue
        })
    };

    useEffect(() => {
        setNewData({
            ...currentGoal,
            target_of_month: currentGoal?.target_of_month ? currentGoal?.target_of_month : 0
        })
    }, [currentGoal])
    return (
        <ModalCustom
            title={`Thiết lập doanh số sale tháng ${monthSale}/${yearSale}`}
            actionOk={() => actionOk(newData)}
            showModal={show}
            setShowModal={setShowModal}
        >
            <Form.Group className="mb-4">
                <Form.Label>Nhân viên</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        value={newData.full_name}
                        disabled
                        readOnly
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group id="numOfUnit" className="mb-4">
                <Form.Label>Mục tiêu</Form.Label>
                <InputGroup>
                    <CurrencyInput
                        allowDecimals={false}
                        className={`form-control`}
                        onValueChange={validateValue}
                        value={newData.target_of_month}
                    />
                </InputGroup>
            </Form.Group>
        </ModalCustom>
    )
}

export default React.memo(GoalList)
