import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Alert, Form} from "react-bootstrap";
import {cloneDeep, range, uniq, uniqBy} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../store/proxy/timeTable.proxy";
import Accordion from 'react-bootstrap/Accordion';

function SetupUnitModal(props) {
    const {
        showModal,
        setShowModal,
        disabled = false,
        dataAllSubSkill,
        indexSkill,
    } = props

    const [step, setStep] = useState(1)
    const {dataTimeTable, setDataTimeTable} = useSnapshot(timeTableProxy)
    const [dragUnit, setDragUnit] = useState([])
    const [showMessage, setShowMessage] = useState(false)
    const [showModalDel, setShowModalDel] = useState(false)
    const [checkedSubSkill, setCheckedSubSkill] = useState([]);
    const [checkedUnit, setCheckedUnit] = useState([]);
    const [pickedUnit, setPickedUnit] = useState([]);
    const [listPickedUnitId, setListPickedUnitId] = useState([]);
    const [checkedAllSubSkill, setCheckedAllSubSkill] = useState([]);
    const [checkedAllUnit, setCheckedAllUnit] = useState([]);
    const [dataModal, setDataModal] = useState({skill: "", totalUnit: 0});
    const [countPickedId, setCountPickedId] = useState({});

    let listUnit = []
    let listSubSkill = []
    let countUnitId = {}

    // set data modal from timtable data
    useEffect(() => {
        if (showModal) {
            setStep(1)
            let _dataTimetable = cloneDeep(dataTimeTable)
            let unitId = []
            let subSkillId = []
            _dataTimetable.map((value, key) => {
                if (key === indexSkill) {
                    let count = 0
                    value.detail.map(v => {
                        count += v.unit.reduce((a, b) => a + b, 0)
                    })
                    unitId = value.unitId.filter(n => Number(n))
                    setDataModal({skill: value.skill, totalUnit: count})
                    subSkillId = value.subSkillId.filter(n => n)
                    subSkillId = subSkillId.map(function (x) {
                        return parseInt(x);
                    });
                    setCheckedSubSkill(prev => {
                        return uniq([...prev, ...subSkillId])
                    })
                }
            })
            if (subSkillId.length > 0) {
                setStep(2)
            }
            if (unitId.length > 0) {
                loadPickedUnitId(unitId, subSkillId)
            }
        } else {
            setCheckedUnit([])
            setPickedUnit([])
            setCheckedAllUnit([])
            setListPickedUnitId([])
            setDragUnit([])
            setCountPickedId({})
        }
    }, [showModal])

    const loadPickedUnitId = (unitId, subSkillId) => {
        let picked = []
        let sortPicked = []
        for (let [_, skill] of Object.entries(dataAllSubSkill)) {
            skill.sub_skills.map((item) => {
                if (subSkillId.includes(Number(item.id))) {
                    item.lessons.map((v) => {
                        if (unitId.includes(v.id)) {
                            picked.push(v)
                        }
                    })
                }
            })
        }
        let listCount = {}
        let uniqueSubSkill = uniq(subSkillId)
        uniqueSubSkill.map(value => {
            listCount[value] = 0
        })
        setCountPickedId(listCount)
        unitId.map(u => {
            const found = picked.find(p => p.id === u)
            sortPicked.push(found)
            for (let [k] of Object.entries(listCount)) {
                if (Number(k) === Number(u.subskill_id)) {
                    listCount[u.subskill_id] = listCount[u.subskill_id] + 1
                }
            }
        })
        setCountPickedId(listCount)
        setPickedUnit(uniqBy(sortPicked, 'id'))
    };

    // set drag and drop list by picked units
    useEffect(() => {
        let unit = []
        let _dataTimeTable = cloneDeep(dataTimeTable)
        let dragging = []
        let listId = []
        let listCount = {}
        checkedSubSkill.map(value => {
            listCount[value] = 0
        })
        pickedUnit.map(pick => {
            if (!pick?.id || !pick?.name || !pick?.subskill_id) return setShowModalDel(true)
            unit.push({id: pick.id, name: pick.name, subskill_id: pick.subskill_id})
            listId.push(pick.id)
            for (let [k] of Object.entries(listCount)) {
                if (Number(k) === Number(pick.subskill_id)) {
                    listCount[pick.subskill_id] = listCount[pick.subskill_id] + 1
                }
            }
        })
        setCountPickedId(listCount)
        setListPickedUnitId(listId)
        _dataTimeTable.map((value, key) => {
            if (key === indexSkill) {
                value.detail.map((drag) => {
                    drag.unit.map((num, key) => {
                        const pick = unit.slice(0, num)
                        unit = unit.slice(num)
                        let child = {week: drag.week, day: Number(key + 1), unit: []}
                        pick.map((item) => child["unit"].push(item))
                        dragging.push(child)
                    })
                })
            }
        });
        setDragUnit(dragging)
    }, [pickedUnit])

    //refresh picked unit when change sub skill
    useEffect(() => {
        let unit = []
        let listId = []
        pickedUnit.map(pick => {
            if (!pick?.id || !pick?.name || !pick?.subskill_id) return setShowModalDel(true)
            if (checkedSubSkill.includes(Number(pick.subskill_id))) {
                unit.push(pick)
                listId.push(pick.id)
            }
        })
        setPickedUnit(uniqBy(unit, 'id'))
        setListPickedUnitId(listId)
    }, [checkedSubSkill])

    const pStyle = {
        marginLeft: '25px',
    };
    const mt15 = {
        marginTop: '15px',
    };

    const handleClose = () => {
        setShowMessage(false)
        if (step === 1) {
            setShowModal(false)
        } else {
            setStep(1)
        }
    };

    // checkbox sub skill
    const lastCheckedSubSkill = useRef(null);
    const handleChange = useCallback((e) => {

        ///get data-index
        const skillBoxes = document.querySelectorAll('.skill_box');
        const dataIndexArray = Array.from(skillBoxes).map(skillBox => {
            const inputElement = skillBox.querySelector('input[data-index]');
            if (inputElement) {
                return parseInt(inputElement.getAttribute('data-index'))
            }
        });
        //end get data-index

        const index = Number(e.target.dataset.index);
        if (lastCheckedSubSkill.current !== null && e.nativeEvent.shiftKey) {
            setCheckedSubSkill((prev) => {
                const start = Math.min(lastCheckedSubSkill.current, index);
                const end = Math.max(lastCheckedSubSkill.current, index);
                const foundDataIndexValues = getSubArray(dataIndexArray, [start, end])
                return uniq([...prev, ...foundDataIndexValues]);
            });
            return;
        }
        if (e.target.checked) {
            lastCheckedSubSkill.current = index;
            setCheckedSubSkill((prev) => [...prev, index]);
        } else {
            lastCheckedSubSkill.current = null;
            setCheckedSubSkill((prev) => prev.filter((i) => i !== index));
        }
    }, []);

    const getSubArray = (array, valuesToFind) => {
        const startIndex = array.indexOf(valuesToFind[0]);
        const endIndex = array.indexOf(valuesToFind[1]);
        if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
            return array.slice(startIndex, endIndex + 1);
        }
        return [];
    };

    //check all sub skill
    const lastCheckedAllSubSkill = useRef(null);
    const handleChangeAllSubSkill = useCallback((e) => {
        const index = Number(e.target.dataset.index);
        if (lastCheckedAllSubSkill.current !== null && e.nativeEvent.shiftKey) {
            setCheckedAllSubSkill((prev) => {
                const start = Math.min(lastCheckedAllSubSkill.current, index);
                const end = Math.max(lastCheckedAllSubSkill.current, index);
                return uniq([...prev, ...range(start, end), end]);
            });
            return;
        }
        if (e.target.checked) {
            lastCheckedAllSubSkill.current = index;
            setCheckedAllSubSkill((prev) => [...prev, index]);
        } else {
            let oldChecked = checkedSubSkill
            lastCheckedAllSubSkill.current = null;
            setCheckedAllSubSkill((prev) => prev.filter((i) => i !== index));
            let pluckId = []
            const subSkills = document.querySelectorAll('.skill_box .form-check-input[data-parent="' + index + '"]')
            if (subSkills.length) {
                subSkills.forEach(function (ele) {
                    pluckId.push(Number(ele.dataset.id))
                })
            }
            let remove = oldChecked.filter(el => pluckId.indexOf(el) < 0);
            setCheckedSubSkill(remove)
        }
    }, [checkedSubSkill]);

    //check all unit
    const lastCheckedAllUnit = useRef(null);
    const handleChangeAllUnit = useCallback((e) => {
        const index = Number(e.target.dataset.index);
        if (lastCheckedAllUnit.current !== null && e.nativeEvent.shiftKey) {
            const start = Math.min(lastCheckedAllUnit.current, index);
            const end = Math.max(lastCheckedAllUnit.current, index);
            let arr = [];
            for (let i = start; i <= end; i++) {
                const checkbox = document.querySelector('.unit_all .form-check-input[data-index="' + i + '"]');
                if (checkbox) {
                    arr.push(i);
                }
            }
            setCheckedAllUnit((prev) => {
                return uniq([...prev, ...arr]);
            });
            return;
        }
        if (e.target.checked) {
            lastCheckedAllUnit.current = index;
            setCheckedAllUnit((prev) => [...prev, index]);
        } else {
            let oldChecked = checkedUnit
            lastCheckedAllUnit.current = null;
            let pluckId = []
            const units = document.querySelectorAll('.unit_checkbox .form-check-input[data-parent="' + index + '"]')
            if (units.length) {
                units.forEach(function (ele) {
                    pluckId.push(Number(ele.dataset.id))
                })
            }
            let remove = oldChecked.filter(el => pluckId.indexOf(el) < 0);
            setCheckedUnit(remove)
            setCheckedAllUnit((prev) => prev.filter((i) => i !== index));
        }
    }, [checkedUnit]);

    //watch check all sub skill
    useEffect(() => {
        if (checkedAllSubSkill.length) {
            checkedAllSubSkill.map(value => {
                if (value in dataAllSubSkill) {
                    let arr = []
                    dataAllSubSkill[value].sub_skills.map(subSkill => {
                        arr.push(subSkill.id)
                    })
                    setCheckedSubSkill((prev) => {
                        return uniq([...prev, ...arr]);
                    });
                }
            })
        }
    }, [checkedAllSubSkill])

    //watch check all unit
    useEffect(() => {
        if (checkedAllUnit.length) {
            let pluckId = [];
            checkedAllUnit.map(value => {
                const units = document.querySelectorAll('.unit_checkbox .form-check-input[data-parent="' + value + '"]')
                if (units.length) {
                    units.forEach(function (ele) {
                        pluckId.push(Number(ele.dataset.id))
                    })
                }
            })
            setCheckedUnit(prev => {
                return uniq([...prev, ...pluckId])
            })
        }
    }, [checkedAllUnit])

    // checkbox unit
    const lastCheckedUnit = useRef(null);
    const handleChangeUnit = useCallback((e) => {
        const index = Number(e.target.dataset.index);
        const subSkillId = Number(e.target.dataset.parent);
        if (lastCheckedUnit.current !== null && e.nativeEvent.shiftKey) {
            const start = Math.min(lastCheckedUnit.current, index);
            const end = Math.max(lastCheckedUnit.current, index);
            let arr = []
            for (let i = start; i <= end; i++) {
                const checkbox = document.querySelector('.unit_checkbox .form-check-input[data-index="' + i + '"]');
                if (checkbox) {
                    arr.push(Number(checkbox.dataset.id));
                }
            }
            setCheckedUnit(prev => {
                return uniq([...prev, ...arr]);
            });
            return
        }
        const checkbox = document.querySelector('.unit_checkbox .form-check-input[data-index="' + index + '"]');
        if (e.target.checked) {
            lastCheckedUnit.current = index;
            setCheckedUnit((prev) => uniq([...prev, Number(checkbox.dataset.id)]))
        } else {
            lastCheckedUnit.current = null;
            setCheckedUnit((prev) => prev.filter((i) => i !== Number(checkbox.dataset.id)))
            setCheckedAllUnit((prev) => prev.filter((i) => i !== subSkillId))
        }
    }, []);

    //drag and drop
    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.unit.splice(startIndex, 1);
        list.unit.splice(endIndex, 0, removed);
        return list;
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = source;
        const destClone = destination;
        const [removed] = sourceClone.unit.splice(droppableSource.index, 1);
        destClone.unit.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        if (sInd === dInd) {
            // order unit
            const items = reorder(dragUnit[sInd], source.index, destination.index);
            const newDragUnit = [...dragUnit];
            newDragUnit[sInd] = items;
            reOrderPickedUnit(newDragUnit)
        } else {
            //move unit
            const result = move(dragUnit[sInd], dragUnit[dInd], source, destination);
            const newDragUnit = [...dragUnit];
            newDragUnit[sInd] = result[sInd];
            newDragUnit[dInd] = result[dInd];
            reOrderPickedUnit(newDragUnit)
        }
    }

    const removeUnit = (item) => {
        let newPicked = pickedUnit.filter(p => p.id !== item.id)
        setPickedUnit(uniqBy(newPicked, 'id'))
        setCheckedAllUnit((prev) => prev.filter((i) => i !== Number(item.subskill_id)))
    }

    const reOrderPickedUnit = (list) => {
        let picked = []
        list.map(item => {
            if (item.unit.length > 0) {
                item.unit.map(u => {
                    picked.push(u)
                })
            }
        })
        setPickedUnit(uniqBy(picked, 'id'))
    }

    const getListUnitNotes = (arrUnitIds, oldNotes) => {
        let newNotes = {};
        if (!arrUnitIds.length) return newNotes
        arrUnitIds.map(unitId => newNotes['unit_id_' + unitId] = oldNotes['unit_id_' + unitId] ?? '')
        return newNotes
    }

    const convertDataFinalList = () => {
        let unitFinalList = []
        let subSkillFinalList = []
        pickedUnit.map((pick) => {
            unitFinalList.push(pick.id)
            subSkillFinalList.push(pick.subskill_id)
        })
        let _dataTimeTable = cloneDeep(dataTimeTable)
        _dataTimeTable.map((value, index) => {
            if (index === indexSkill) {
                value.unitId = unitFinalList
                value.subSkillId = subSkillFinalList
                value.notes = getListUnitNotes(unitFinalList, value.notes)
                value.isComplete = getListUnitNotes(unitFinalList, value.isComplete)
            }
        })
        setDataTimeTable([..._dataTimeTable])
        setShowModal(false)
    }

    const addUnit = () => {
        setShowMessage(false)
        let unit = pickedUnit
        let restOfUnit = []
        checkedUnit.map(check => {
            const checkbox = document.querySelector('.unit_checkbox .form-check-input[data-id="' + check + '"]');
            if (checkbox && unit.length < dataModal.totalUnit) {
                unit.push({
                    id: Number(checkbox.dataset.id),
                    name: checkbox.dataset.name,
                    subskill_id: checkbox.dataset.parent
                })
            } else {
                restOfUnit.push(Number(checkbox.dataset.id))
            }
        })
        setPickedUnit(prev => {
            return uniqBy([...prev, ...unit], 'id')
        })
        setCheckedUnit(restOfUnit)
    }

    const handleOk = () => {
        if (step === 1) {
            if (checkedSubSkill.length === 0) {
                setShowMessage(true)
                return;
            }
            setStep(2)
        } else if (step === 2) {
            setShowMessage(false)
            setShowModalDel(false)
            convertDataFinalList()
        }
    };

    //render sub skill, unit and drag item
    if (showModal) {
        if (step === 1) {
            //set sub skill list
            Object.keys(dataAllSubSkill).map(k => {
                listSubSkill.push(
                    <div key={k}>
                        <Form.Group style={mt15}>
                            <Form.Check
                                onChange={handleChangeAllSubSkill}
                                checked={checkedAllSubSkill.includes(Number(k))} data-index={k}
                                label={dataAllSubSkill[k].skill_name} key={k} id={"checkboxAllSub" + k}
                                htmlFor={"checkboxAllSub" + k}/>
                            <div className={`ml-2`} style={pStyle}>
                                {
                                    dataAllSubSkill[k].sub_skills.map((v) => {
                                        return (
                                            <Form.Check
                                                className={`skill_box`} onChange={handleChange}
                                                data-index={v.id} label={v.name} key={v.id}
                                                checked={checkedSubSkill.includes(v.id)}
                                                id={"checkboxSub" + v.id} htmlFor={"checkboxSub" + v.id}
                                                data-parent={k}/>
                                        )
                                    })
                                }
                            </div>
                        </Form.Group>
                    </div>
                )
            })
        }

        if (step === 2) {
            for (let [c, skill] of Object.entries(dataAllSubSkill)) {
                skill.sub_skills.map((item, k) => {
                    if (checkedSubSkill.includes(Number(item.id))) {
                        countUnitId[item.id] = item.lessons.length
                        listUnit.push(
                            <Accordion key={`${c}_${k}`} defaultActiveKey="0"
                                       style={{marginTop: countPickedId[item.id] == countUnitId[item.id] ? '0' : '15px'}}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <Form.Check
                                            className={countPickedId[item.id] == countUnitId[item.id] ? 'd-none unit_all' : 'unit_all'}
                                            onChange={handleChangeAllUnit}
                                            onClick={e => e.stopPropagation()}
                                            checked={checkedAllUnit.includes(item.id)} data-index={item.id}
                                            label={item.name} key={k} id={"checkboxAllUnit" + k}
                                            htmlFor={"checkboxAllUnit" + k}/>
                                    </Accordion.Header>
                                    <Accordion.Body className={`ml-2`} style={pStyle}>
                                        {
                                            item.lessons.map((v, n) => {
                                                if (!listPickedUnitId.includes(v.id)) {
                                                    return (
                                                        <Form.Check
                                                            className={`unit_checkbox`}
                                                            onChange={handleChangeUnit} data-index={n+1 + (k*100)}
                                                            data-id={v.id} id={"checkbox" + v.id}
                                                            checked={checkedUnit.includes(v.id)} label={v.name}
                                                            key={v.id} htmlFor={"checkbox" + v.id}
                                                            data-parent={v.subskill_id}
                                                            data-sub={v.sub_skill_name} data-name={v.name}/>
                                                    )
                                                }
                                            })
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    }
                })
            }
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: 8,
        margin: `0 0 12px 0`,
        background: isDragging ? `rgb(227, 252, 239)` : `rgb(255, 255, 255)`,
        transform: `rotate(10deg)`,
        borderRadius: `5px`,
        border: isDragging ? `1px solid #091e42` : `1px solid #d9d9d9`,
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? `#fffae6` : `#ebecf0`,
        padding: 8,
        margin: `5px 0`,
        borderRadius: `10px`,
        marginBottom: `12px`,
        position: `relative`
    });

    const onHide = () => {
        setShowModal(false)
        setShowMessage(false)
    }

    return (
        <>
            <Modal show={showModal} onHide={onHide} className={`modal_unit `}>
                <Modal.Header closeButton>
                    <Modal.Title>Thiết lập Unit - {dataModal.skill}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert
                        variant="danger"
                        show={showMessage}
                        onClose={() => setShowMessage(false)}>
                        <div className="row w-100">
                            <div className={`col-11`}>Vui lòng lựa chọn ít nhất 1 subskill!</div>
                            <div className={`col-1 m-auto`}>
                                <Button style={{float: "right"}} variant="close" size="xs"
                                        onClick={() => setShowMessage(false)}/>
                            </div>
                        </div>
                    </Alert>
                    <div className={`modal_step`}>
                        <div><b>{step === 1 ? 'Bước 1/2: Lựa chọn subskill' : 'Bước 2/2: Lựa chọn unit'}</b></div>
                    </div>
                    {step === 2 &&
                        <div className="modal_step">
                            <span className="text-danger col col-md-5 text-end">Đã chọn: {checkedUnit.length}</span>
                            <span className="col col-md-2"></span>
                            <span className="text-danger col col-md-5 text-end"></span>
                        </div>
                    }
                    <div className={`row`}>
                        <div className={step === 1 ? 'col col-md-12 card' : 'col col-md-5 card'}>
                            {
                                step === 1 ? listSubSkill : listUnit
                            }
                        </div>
                        {
                            step === 2 &&
                            <div className={`col col-md-2`}>
                                <Button variant="outline-success" className="m-1"
                                        disabled={Object.values(countPickedId).reduce((a, b) => a + b, 0) >= dataModal.totalUnit}
                                        onClick={addUnit}>Thêm <FontAwesomeIcon
                                    icon={faAngleDoubleRight} className="me-1"/></Button>
                            </div>
                        }
                        {
                            step === 2 &&
                            <div className={`col col-md-5 card`}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {dragUnit.map((el, ind) => (
                                        <Droppable key={ind} droppableId={`${ind}`}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                    {...provided.droppableProps}
                                                >
                                                    <div className={`modal_week`}>Tuần {el.week} - Buổi {el.day}</div>
                                                    {el.unit.map((item, index) => (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={`${item.id}`}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center"
                                                                        }}
                                                                    >
                                                                        <div>{item.name}</div>
                                                                        <Button variant="outline-danger"
                                                                                onClick={() => removeUnit(item)}
                                                                                className="m-1">Xóa</Button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                                </DragDropContext>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        {step === 1 ? "Hủy" : "Quay lại"}
                    </Button>
                    <Button disabled={disabled} variant="info" onClick={handleOk}>
                        {step === 2 ? "OK" : "Tiếp theo"}
                    </Button>
                </Modal.Footer>
            </Modal>
            {showModalDel &&
                <DeleteUnitModal
                    showModal={showModalDel}
                    setShowModalDel={setShowModalDel}
                    setShowModal={setShowModal}
                    actionOk={handleOk}
                />
            }
        </>
    );
}

function DeleteUnitModal({showModal, setShowModal, setShowModalDel, actionOk}) {

    const handleClose = () => {
        setShowModal(false)
        setShowModalDel(false)
    }
    return (

        <Modal 
            show={showModal} 
            onHide={handleClose}
            className={``} 
            style={{background: 'rgb(0 0 0 / 55%)', }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Xác nhận thiết lập kỹ năng</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>- Toàn bộ bài học đã bị xóa của kỹ năng này sẽ bị xóa và không thể hồi phục.</div>
                <div>- Những bài học chưa bị xóa sẽ được giữ nguyên trên thời khóa biểu.</div>
                <div>Bạn có thực sự muốn xóa?</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>
                    Hủy
                </Button>
                {
                    actionOk &&
                    <Button variant="info" onClick={actionOk}>
                        OK
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default SetupUnitModal;
