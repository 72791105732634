import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";

// components
import Sidebar from "../components/main/Sidebar";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";
import Preloader from "../components/main/Preloader";
import ScrollToTop from "../components/main/ScrollToTop";
import axiosCommon from "../config/axiosCommon";
import authUtil from "../config/auth-util";
import cookieHelper from '../config/cookie-helper';
import constants from '../config/constants';
import {Routes} from "../routes";
import {useSnapshot} from "valtio";
import {userProxy} from "../store/proxy/user.proxy";
import {ApiPath} from "../apiPath";

function Main() {
    const location = useLocation();
    const {pathname} = location;
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const {setCurrentUser, setSaleList, setSalesPlusList, currentUser} = useSnapshot(userProxy)

    useEffect(() => {
        getCommonData()
    }, [pathname])

    useEffect(() => {
    	if (cookieHelper.getCookie(constants.refreshToken)) {
            getCommonData()
		} else {
		    cookieHelper.setCookie(constants.refreshToken, 'processing', 60)
	        axiosCommon.post('/api/refresh')
	        .then(
	            response => {
	                authUtil.setAuth(response.data)
	                getCommonData()
	            },
	            error => {
	                authUtil.removeAuth()
	                navigate(Routes.Login.path);
	            },
	        )
	        .finally(() => {
	        	setLoaded(true)
	        });
		}
    }, []);

    const getCommonData = () => {
        axiosCommon.post(ApiPath.commonData)
            .then((res) => {
                setCurrentUser(res.data.user)
                setSaleList(res.data.sales)
                setSalesPlusList(res.data.salesPlus)
            })
	        .finally(() => {
	        	setLoaded(true)
	        });
    }

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem('settingsVisible') !== 'false'
    }

    const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

    const toggleSettings = () => {
        setShowSettings(!showSettings);
        localStorage.setItem('settingsVisible', !showSettings);
    }

    return (
        <>
            <Preloader show={loaded ? false : true}/>
            <ScrollToTop/>
            <Sidebar/>
            {loaded && 
            <main className="content mb-6">
                <Navbar/>
                <Outlet/>
            </main>
            }
            <Footer toggleSettings={toggleSettings} showSettings={showSettings}/>
        </>
    );
}

export default Main;
