import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import {NavLink, useNavigate} from "react-router-dom";
import "../../scss/student.scss";
import {ApiPath} from "../../apiPath";
import Timetable from "../../components/page/timetable/Timetable";
import Button from 'react-bootstrap/Button';
import {Col, Form} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Grid} from "@mui/material";
import Loading from "../../components/main/Loading";
import {changeStudyDefault, changeTimeTableDefault, getCourseDatail, setPageTitle, openStudentPdfView, getReturnUrlFromParamString} from "../../common/function";
import axiosCommon from '../../config/axiosCommon';
import { toast } from "react-toastify";

const CreateStudent = (props) => {
    const navigate = useNavigate();
    const [enteredName, setEnteredName] = useState('');
    const [enteredPhone, setEnteredPhone] = useState('');
    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredTimeStudy, setEnteredTimeStudy] = useState('');
    const [enteredTypeTimeStudy, setEnteredTypeTimeStudy] = useState('');
    const [enteredCourseName, setEnteredCourseName] = useState('');
    const [enteredTotalAssistantsSession, setEnteredTotalAssistantsSession] = useState('');
    const [enteredTotalTestSession, setEnteredTotalTestSession] = useState('');
    const [enteredTotalAdminCorrectTask, setTotalAdminCorrectTask] = useState('');
    const [enteredTotalExaminerCorrectTask, setEnteredTotalExaminerCorrectTask] = useState('');
    const [enteredNote, setEnteredNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [previewPdf, setPreviewPdf] = useState(false);

    const handlerCreateStudent = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setIsLoading(true);
        const student = {
            full_name: enteredName,
            phone: enteredPhone,
            email: enteredEmail,
            time_study: enteredTimeStudy,
            type_time_study: enteredTypeTimeStudy,
            course_name: enteredCourseName,
            total_assistants_session: enteredTotalAssistantsSession,
            total_test_session: enteredTotalTestSession,
            total_admin_correct_task: enteredTotalAdminCorrectTask,
            total_examiner_correct_task: enteredTotalExaminerCorrectTask,
            note: enteredNote,
            course_detail: getCourseDatail()
        };
        axiosCommon.post(ApiPath.storeStudent, student)
            .then((res) => {
                    let data = res.data
                    if (data?.data?.student_id && previewPdf) {
                    openStudentPdfView(data.data.student_id)
                        navigate(Routes.Students.path + '/' + data.data.student_id)
                    } else {
                        navigate(Routes.Students.path, {
                            state: {
                                notification: {
                                    type: 'success',
                                    message: 'Thiết lập thời gian biểu thành công!'
                                }
                            }
                        })
                    }
                },
                error => {
                    toast.error('Thiết lập thời gian biểu thất bại, vui lòng thử lại!', {
                        autoClose: false,
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                    })
                })
            .finally(()=> {
                setIsLoading(false);
                setPreviewPdf(false)
            });
    }

    const nameChangeHandler = (event) => {
        setEnteredName(event.target.value);
    }
    const emailChangeHandler = (event) => {
        setEnteredEmail(event.target.value);
    }
    const phoneChangeHandler = (event) => {
        setEnteredPhone(event.target.value);
    }
    const timeStudyChangeHandler = (event) => {
        setEnteredTimeStudy(event.target.value);
    }
    const typeTimeStudyChangeHandler = (event) => {
        setEnteredTypeTimeStudy(event.target.value);
    }
    const noteChangeHandler = (event) => {
        setEnteredNote(event.target.value);
    }
    useEffect(() => {
        setPageTitle('Tạo mới học viên');
        //Init data
        changeStudyDefault()
        changeTimeTableDefault()
    }, [])

    return (
        <div>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.Students} secondLocation={Routes.CreateStudent}/>
            <Form noValidate validated={validated} onSubmit={handlerCreateStudent} id="submitForm">
                <Grid className='pt-3'>
                    <Row>
                        <Col sm={4}>
                            <h5>Thông tin cơ bản</h5>
                        </Col>
                        <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                            <NavLink className={'btn btn-light me-2'} to={getReturnUrlFromParamString() ??  Routes.Students.path}>
                                Hủy
                            </NavLink>
                            <Button type="submit" onClick={() => {
                                setPreviewPdf(true)
                            }} className="me-2" variant="warning">Preview</Button>
                            <Button type="submit" variant="info">Lưu thông tin</Button>
                        </div>
                    </Row>
                    <hr/>
                </Grid>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Họ và tên</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Họ và tên"
                                value={enteredName}
                                onChange={nameChangeHandler}
                            />
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng điền họ và tên
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>SĐT</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="SĐT"
                                value={enteredPhone}
                                onChange={phoneChangeHandler}
                            />
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng điền SĐT
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                            <Form.Label>Email</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    value={enteredEmail}
                                    onChange={emailChangeHandler}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Xin vui lòng điền Email
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="2" >
                            <Form.Label>Thời gian học</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Thời gian học"
                                value={enteredTimeStudy}
                                onChange={timeStudyChangeHandler}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2">
                            <Form.Label>Đơn vị</Form.Label>
                            <Form.Select name="type_time_study" value={enteredTypeTimeStudy} onChange={typeTimeStudyChangeHandler}>
                                <option value="1">Ngày</option>
                                <option value="2">Tuần</option>
                                <option value="3">Tháng</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6">
                            <Form.Label>Tên khóa học</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Tên khóa học"
                                value={enteredCourseName}
                                onChange={event => setEnteredCourseName(event.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="2" >
                            <Form.Label>Số buổi học với trợ giảng</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Số buổi học với trợ giảng"
                                value={enteredTotalAssistantsSession}
                                onChange={event => setEnteredTotalAssistantsSession(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" >
                            <Form.Label>Số bài Test với GVNN</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Số bài Test với GVNN"
                                value={enteredTotalTestSession}
                                onChange={event => setEnteredTotalTestSession(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" >
                            <Form.Label>Số bài chữa với thầy Phi</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Số bài chữa với thầy Phi"
                                value={enteredTotalAdminCorrectTask}
                                onChange={event => setTotalAdminCorrectTask(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" >
                            <Form.Label>Số bài chữa với cựu Examiner</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Số bài chữa với cựu Examiner"
                                value={enteredTotalExaminerCorrectTask}
                                onChange={event => setEnteredTotalExaminerCorrectTask(event.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="8">
                            <Form.Label>Ghi Chú</Form.Label>
                            <Form.Control 
                                as="textarea"
                                rows="3"
                                placeholder="Ghi Chú"
                                onChange={noteChangeHandler}
                                value={enteredNote}
                            />
                        </Form.Group>
                    </Row>
                </div>
            </Form>
            <Timetable />
            <Grid className='pt-3'>
                <hr/>
            </Grid>
        </div>
    );
}

// function ConfirmPreviewModal({showModal, setShowModal, actionOk}) {
//     return (
//         <ModalCustom
//             title={`Xác nhận Preview`}
//             actionOk={actionOk}
//             showModal={showModal}
//             setShowModal={setShowModal}
//         >
//             <div>Bạn có muốn lưu data và Preview ko?</div>
//         </ModalCustom>
//     )
// }

export default CreateStudent;
