import React, {useEffect, useState} from "react";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../../store/proxy/timeTable.proxy";
import {convertDataSubSkill, distributeUnit} from "../../../common/function";

// Create Document Component
function TimetablePdf() {
    const {dataTimeTable, studyDefault, maxDayTimetable, subskills, lessons} = useSnapshot(timeTableProxy)
    const [newSkillIds, setNewSkillIds] = useState([])
    const [newUnitIds, setNewUnitIds] = useState([])
    const [newNote, setNewNote] = useState([])
    const [newSubSkillIds, setNewSubSkillIds] = useState([])
    const [newDays, setNewUnitDays] = useState([])
    const [colWeek, setColWeek] = useState([])
    let ccc = 1

    const convertData = (data, lengCol) => {
        let vl = []
        for (let i = 0; i < lengCol; i++) {
            let cc = []
            data.map((value, index) => {
                cc.push(value[i])
            })
            vl.push(cc)
        }
        return vl
    }
    useEffect(() => {
        let _newSkillIds = []
        let _newUnitIds = []
        let _newNote = []
        let _newSubSkillIds = []
        let _newDays = []
        let _colWeek = []
        let lengCol = 0
        dataTimeTable.map((skills, indexSkill) => {
            let arrUnit = []
            skills.detail.map((skill) => {
                arrUnit.push(...skill.unit)
            })
            _newSkillIds.push(skills.skill);
            _newSubSkillIds.push(convertDataSubSkill(arrUnit, skills?.subSkillId));
            _newUnitIds.push(distributeUnit(arrUnit, skills?.unitId))
            _newNote.push(skills?.notes)

            lengCol = distributeUnit(arrUnit, skills?.unitId).length
        })
        setNewSkillIds(_newSkillIds)

        maxDayTimetable.map((val) => {
            for (let i = 1; i <= val; i++) {
                _newDays.push(i)
                if (i === 1) {
                    _colWeek.push(val)
                } else {
                    _colWeek.push(0)
                }
            }
        })
        setColWeek(_colWeek)
        setNewUnitDays(_newDays);

        setNewUnitIds(convertData(_newUnitIds, lengCol));
        setNewNote(_newNote);
        setNewSubSkillIds(convertData(_newSubSkillIds, lengCol));
    }, [])

    if (!newSkillIds.length) return

    return (
        <div>
            <table
                className={`table-bordered bg-white w-100`}
                style={{
                    fontSize: 14,
                    fontFamily: '"Nunito Sans", sans-serif',

                }}
            >
                <thead>
                <tr className={`text-center`}>
                    <th colSpan={2}>TIMETABLE</th>
                    {newSkillIds.map((val) => {
                        return <th>{val}</th>
                    })}

                </tr>
                </thead>
                <tbody>
                {newUnitIds.map((units, index) => {
                    return (
                        <>
                            <tr>
                                {colWeek[index] !== 0 &&
                                    <td className={`text-center`} style={{width: 50}}
                                        rowSpan={colWeek[index]}>Tuần: {ccc++}</td>
                                }
                                <td className={`text-center`} style={{width: 50}}>Ngày: {newDays[index]}</td>
                                {units.length > 0 && units.map((unit, ind) => {
                                    return (
                                        <td style={{
                                            width: 250,
                                            wordWrap: 'break-word',
                                            padding: 4
                                        }}>
                                            {unit.length > 0 && unit.map((val, i) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={`text-danger`}>{subskills['subskill_id_' + newSubSkillIds[index][ind][i]] ?? newSubSkillIds[index][ind][i]}</div>
                                                        <div>-{lessons['lesson_id_' + val] ?? val}</div>
                                                        {newNote[ind]['unit_id_' + val] ? <div
                                                            className={`bg-warning`}>{newNote[ind]['unit_id_' + val]}</div> : ''}
                                                        {lessons?.['lesson_deleted_id_' + val] &&
                                                            <div className={`text-danger fs-8`}>(Bài học đã bị xóa)</div>
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        </>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
}

export default TimetablePdf;
