import React from 'react';

function PaginationCustom(props) {
    const {page, setPage, lastPage} = props

    const previous = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const next = () => {
        if (page < lastPage) {
            setPage(page + 1)
        }
    }
    return (
        <div className={``} style={{float: "right"}}>
            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    {page > 1 &&
                        <li className="page-item">
                            <a
                                onClick={previous}
                                className={`page-link`}
                                aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                    }
                    {(page < lastPage) &&
                        <li className="page-item">
                            <a
                                onClick={next}
                                className="page-link" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    }

                </ul>
            </nav>
        </div>
    );
}

export default PaginationCustom;