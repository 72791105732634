import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import Modal from 'react-bootstrap/Modal';
import {toast} from "react-toastify";

export default function SaleInfoModal({ visible, hide }) {

    const [data, setData] = useState({
        password: '',
        rePassword: '',
        oldPassword: ''
    })
    
    useEffect(() => {
        setData({
            password: '',
            rePassword: '',
            oldPassword: ''
        })
    }, [visible])
 

    const updateField = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const handleUpdate = async () => {
        if(data.password !== data.rePassword) {
            toast.error('Mật khẩu không khớp, vui lòng kiểm tra lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
            return
        }
        if(!data.password || data.password.length < 6) {
            toast.error('Mật khẩu tối thiểu 6 kí tự!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
            return
        }
        if(data.password == data.oldPassword) {
            toast.error('Mật khẩu mới trùng với mật khẩu cũ!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
            return
        }

        const userId = sessionStorage.id
        const res = await axiosCommon.put(
            ApiPath.resetPassword + `/${userId}`, {
                old_password: data.oldPassword,
                new_password: data.password
            }
        ).catch(e => e.response.data)
        if(res.data !== false) {
            toast.success('Đổi mật khẩu thành công!', {
                autoClose: 3000,
                hideProgressBar: true,
                position: "top-right",
                closeOnClick: true,
            })
            hide()
        } else {
            toast.error(res.message, {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        }
    }

    if (!visible) return
    return (
        <Modal show={visible} onHide={hide}>
            <Modal.Header closeButton className="d-flex align-content-center">
                <Modal.Title>Đổi mật khẩu</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-2 flex-column px-3">
                <Form.Group>
                    <Form.Label>Mật khẩu cũ</Form.Label>
                    <Form.Control type="password" name="oldPassword" value={data.oldPassword} required onChange={updateField}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Mật khẩu mới</Form.Label>
                    <Form.Control type="password" name="password" value={data.password} required onChange={updateField}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nhập lại mật khẩu mới</Form.Label>
                    <Form.Control type="password" name="rePassword" value={data.rePassword} onChange={updateField}/>
                </Form.Group>
                <Button className="mt-3 mb-4" variant='info' onClick={handleUpdate}>Thay đổi</Button>
            </Modal.Body>
        </Modal>
    )
}

