import React, {useState} from 'react';
import {ApiPath} from "../../apiPath";
import axiosCommon from "../../config/axiosCommon";
import ModalCustom from "../../components/Custom/ModalCustom";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";
import {BsFillCloudUploadFill} from "react-icons/bs";

function UploadFile({getListStudentInfo}) {
    const [showModal, setShowModal] = useState(false)
    const [file, setFile] = useState()
    const [errors, setErrors] = useState({})

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    function handleSubmit() {
        const formData = new FormData();
        formData.append('import_file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axiosCommon.post(ApiPath.studentImport, formData, config).then((response) => {
            getListStudentInfo()
            toast.success('Import thành công!', {
                autoClose: 3000,
                hideProgressBar: true,
                position: "top-right",
                closeOnClick: true,
            })
            setShowModal(false)
        }).catch(err => {
            if (err?.response?.status === 400) {
                let result = err?.response?.data?.error.reduce(function (r, a) {
                    r[a.row] = r[a.row] || [];
                    r[a.row].push(a);
                    return r;
                }, Object.create(null));
                setErrors(result)
            }
            ;
        });
    }

    return (
        <div className="App">
            <Button className={'background2e3650 mr-10'} variant={`secondary`} onClick={() => setShowModal(true)}>
                <BsFillCloudUploadFill className={'icon-add-student-info'}/>
                Upload File
            </Button>
            <ModalCustom
                title={`Import Excel: Thông Tin Học Viên`}
                actionOk={handleSubmit}
                statusShowModal
                showModal={showModal}
                setShowModal={setShowModal}
            >
                <input style={{width: '100%'}} type="file" accept=".xls,.xlsx" onChange={handleChange}/>
                <div className={`m-2`}>
                    {!isEmpty(errors) && Object.keys(errors).map((err, index) => {
                        return <div key={'errors-' + index} style={{fontSize: 12}}>
                            <div className={`text-danger`}>Dòng {err} :</div>
                            <div>{errors[err].map((er, ind) => {
                                return <div key={'err-' + ind} className={`text-danger ml-10`}>- {er.errors[0]}</div>
                            })}</div>

                        </div>
                    })
                    }
                </div>
            </ModalCustom>
        </div>
    );
}

export default UploadFile;