import {Fragment, memo, useState} from "react";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../../store/proxy/timeTable.proxy";
import {cloneDeep} from "lodash";
import UnitNoteModal from "./UnitNoteModal";

function UnitNumberTable({arrUnitIds, notes, indexSkill, arrSubSillIds}) {
    const {lessons, dataTimeTable, setDataTimeTable, subskills} = useSnapshot(timeTableProxy)
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [currentUnit, setCurrentUnit] = useState({});

    const showNoteModal = (unitId) => {
        setCurrentUnit({
            unitId,
            'lesson': lessons['lesson_id_' + unitId] ?? '', 
            'note': notes['unit_id_' + unitId] ??  ''
        })
        setShowEditPopup(true)
    }

    const updateNotes = (note) => {
        let _dataTimeTable = cloneDeep(dataTimeTable)
        let _notes = cloneDeep(notes)
        _notes['unit_id_' + currentUnit.unitId] = note
        if (_dataTimeTable?.length > 0) {
            _dataTimeTable.map((skills, index) => {
                if (index === indexSkill) {
                    skills.notes = _notes
                }
            })
            setDataTimeTable([..._dataTimeTable])
        }
    }

    return (
        <Fragment>
            <table
                className="table-bordered bg-white m-0"
                style={{
                    fontSize: 14,
                    fontFamily: '"Nunito Sans", sans-serif',
                    borderColor: '#eaedf2'
                }}
            >
                <tbody>
                {arrUnitIds.map((arrUnitId, i) => {
                        return (
                            <tr key={i + `arr_unit`}
                                style={{padding: '0.35rem', height: (i + 1) % 4 === 0 ? 151.5 : 151}}
                                className={`border-bottom-0`}>
                                <td>
                                    <div className="th-unit-name">
                                        {
                                            arrUnitId.map((unitId, indU) => {
                                                return (
                                                    <div key={indU + 'unit_id'} className="unit-name-list">
                                                        <div className="text-danger subskill-name-div">
                                                            {subskills['subskill_id_' + arrSubSillIds[i][indU]] ?? arrSubSillIds[i][indU]}
                                                        </div>
                                                        {lessons['lesson_id_' + unitId] ?? unitId}
                                                        {lessons?.['lesson_deleted_id_' + unitId] ?
                                                            <div className={`text-danger fs-8`}>(Bài học đã bị xóa)</div> :
                                                            <button type="button" onClick={() => showNoteModal(unitId)}
                                                                    className={`btn btn-link ${ !notes['unit_id_' + unitId] ? 'text-primary' : 'text-note-contain'}  py-0 text-decoration-underline`}>Note
                                                            </button>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            { showEditPopup && <UnitNoteModal show={showEditPopup} setShowModal={setShowEditPopup} actionOk={updateNotes} currentUnit={currentUnit}/> }
        </Fragment>
        
    )
}

export default memo(UnitNumberTable);
