import React, {Fragment, useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {ApiPath} from "../../apiPath";
import {Routes} from "../../routes";
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {formatDate, setPageTitle, getReturnUrlFromParamString} from "../../common/function";
import axiosCommon from '../../config/axiosCommon';
import constants from "../../config/constants";
import Loading from "../../components/main/Loading";
import HistoryFeePaidListModal from "../../components/student-infos/HistoryFeePaidListModal";
import HistoryUpdateModal from "../../components/student-infos/HistoryUpdateModal";
import DatePicker from "react-datepicker";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import TestModal from "./TestModal";
import ConfirmContinueModal from "../../components/student-infos/ConfirmContinueModal";
import {Box,Tabs,Tab} from '@mui/material';

const StudentInfoDetail = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {saleList, currentUser} = useSnapshot(userProxy)
    const [oldTuitionFeePaid, setOldTuitionFeePaid] = useState('');
    const [tabFee, setTabFee] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [listFeePaids, setListFeePaids] = useState(false);
    const [changeHistories, setChangeHistories] = useState(false);
    const [idShowTest, setIdShowTest] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [inputs, setInputs] = useState({
        full_name: '',
        email: '',
        phone: '',
        contact_source: '',
        status: '',
        call_note: '',
        sale_note: '',
        MKT_note: '',
        call_day: '',
        call_hour: '',
        input_fees: '',
        return_fees: '',
        tuition_fees: '',
        writing_skill_test: '0',
        tuition_fee_paid: '',
        remaining_tuition_fees: '',
        tuition_payment_date: '',
		learning_path_date: '',
        status_reject: '',
        assigned_user: '',
		is_test_reminded: '0',
    });

    useEffect(() => {
        setIsLoading(true);
        setPageTitle('Chỉnh sửa thông tin học viên');
        handleGetStudentInfo(id)
    }, [id])

    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (value && event.target.attributes['number']?.value) {
            value = value.match(/\d/g)?.join('') ?? '';
        } else if (event.target.attributes['type']?.value == 'checkbox') {
			value = event.target.checked
		}
        setInputs(values => ({...values, [name]: value}))
    }

    const handleChangeTab = (event, newValue) => {
    	setTabFee(newValue);
    	if (newValue == 0) {
    		handleChangeFee({target:{name: 'input_fees', value: inputs.input_fees ?? 0}})
		} else {
    		handleChangeFee({target:{name: 'return_fees', value: inputs.return_fees ?? 0}})
		}
    };
    
    const handleChangeDateTime = (data) => {
        setInputs(values => ({...values, ...data}))
    }

    const handleChangeFee = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        value = value ? value.match(/\d/g).join('') : value;
        setInputs(values => ({...values, [name]: value}))

        // Show fee
        let inputFees = name === 'input_fees' ? Number(value) : Number(inputs.input_fees ?? 0)
        inputFees = name === 'return_fees' ? Number(value) * -1 : inputFees
        let tuitionFees = name === 'tuition_fees' ? Number(value) : Number(inputs.tuition_fees)
        let tuitionFeePaid = Number(oldTuitionFeePaid) + inputFees
        if (tuitionFeePaid > tuitionFees) {
        	tuitionFeePaid = tuitionFees
		} else if (tuitionFeePaid < 0) {
			tuitionFeePaid = 0
		}
        let remainingTuitionFees = tuitionFees - tuitionFeePaid
        setInputs(values => ({
            ...values,
            tuition_fee_paid: tuitionFeePaid,
            remaining_tuition_fees: remainingTuitionFees
        }))
    }

    const handleGetStudentInfo = (studentId) => {
        setIsLoading(true);
        axiosCommon.get(ApiPath.detailStudentInfo + studentId)
            .then((res) => {
                setInputs({
                    ...res.data.student_info,
                    call_day: res.data.student_info.call_day ? new Date(res.data.student_info.call_day) : '',
                    tuition_payment_date: res.data.student_info.tuition_payment_date ? new Date(res.data.student_info.tuition_payment_date) : '',
                    learning_path_date: res.data.student_info.learning_path_date ? new Date(res.data.student_info.learning_path_date) : '',
                })
                setListFeePaids(res.data.paid_history)
                setChangeHistories(res.data.change_history)
                setOldTuitionFeePaid(res.data.student_info.tuition_fee_paid)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlerUpdateStudentInfo = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }
        setIsLoading(true);
        let studentInfo = {
            ...inputs,
            call_day: formatDate(inputs.call_day, '', 'YYYY-MM-DD'),
            tuition_payment_date: formatDate(inputs.tuition_payment_date, '', 'YYYY-MM-DD'),
            learning_path_date: formatDate(inputs.learning_path_date, '', 'YYYY-MM-DD'),
        }
        axiosCommon.put(ApiPath.detailStudentInfo + id, studentInfo)
            .then((res) => {
                if (res.data.warning) {
                    setOpenConfirm(true)
                } else {
                    navigate(getReturnUrlFromParamString() ?? Routes.StudentInfos.path, {
                        state: {
                            notification: {type: 'success', message: "Chỉnh sửa thông tin học viên thành công!"}
                        }
                    })
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const continueSaveStudentInfo = () => {
        setIsLoading(true);
        let studentInfo = {
            ...inputs,
            call_day: formatDate(inputs.call_day, '', 'YYYY-MM-DD'),
            tuition_payment_date: formatDate(inputs.tuition_payment_date, '', 'YYYY-MM-DD'),
            learning_path_date: formatDate(inputs.learning_path_date, '', 'YYYY-MM-DD'),
            skip_warning: true
        }
        axiosCommon.put(ApiPath.detailStudentInfo + id, studentInfo)
            .then((res) => {
                if (res.data.warning) {
                    setOpenConfirm(true)
                } else {
                    navigate(getReturnUrlFromParamString() ?? Routes.StudentInfos.path, {
                        state: {
                            notification: {type: 'success', message: "Chỉnh sửa thông tin học viên thành công!"}
                        }
                    })
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    
    const a11yProps = index => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    
    return (
        <Fragment>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.StudentInfos} secondLocation={Routes.StudentInfoDetail}/>
            <Form noValidate validated={validated} onSubmit={handlerUpdateStudentInfo}>
                <Row>
                    <Col sm={4}>
                        <h5>Thông tin cơ bản</h5>
                    </Col>
                    <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                        <NavLink className={'btn btn-light me-2'} to={getReturnUrlFromParamString() ?? Routes.StudentInfos.path}>
                            Hủy
                        </NavLink>
						{currentUser?.role_id == constants.ROLE_ID_ADMIN && 
                        <Button type="button" className={'me-2'} variant="warning" onClick={() => setShowUpdateModal(true)}>
							Lịch sử thay đổi
						</Button>
						}
                        <Button type="button" className={'me-2'} variant="success" onClick={() => setIdShowTest(id)}>kết
                            quả bài kiểm
                            tra</Button>
                        <Button type="submit" variant="info">Lưu thông tin</Button>
                    </div>
                </Row>
                <hr className='col-md-10'/>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="full_name">
                            <Form.Label>Họ và tên <span className="text-danger">*</span></Form.Label>
                            <Form.Control required type="text" placeholder="Họ và tên" name="full_name"
                                          value={inputs.full_name} onChange={handleChange}/>
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng điền Họ và tên
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="phone">
                            <Form.Label>SĐT <span className="text-danger">*</span></Form.Label>
                            <Form.Control required type="text" number="true" placeholder="SĐT" maxLength='20'
                                          name="phone" value={inputs.phone} onChange={handleChange}/>
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng điền SĐT
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="email">
                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control type="email" placeholder="Email" aria-describedby="inputGroupPrepend"
                                              required name="email" value={inputs.email} onChange={handleChange}/>
                                <Form.Control.Feedback type="invalid">
                                    Xin vui lòng điền Email
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="contact_source">
                            <Form.Label>Nguồn liên lạc <span className="text-danger">*</span></Form.Label>
                            <Form.Select required name="contact_source" value={inputs.contact_source}
                                         onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn nguồn liên lạc</option>
                                {
                                    constants.contact_sources.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Xin vui lòng chọn nguồn liên lạc
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="status">
                            <Form.Label>Trạng thái</Form.Label>
                            <Form.Select name="status" value={inputs.status} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn trạng thái</option>
                                {
                                    constants.statuses.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="assigned_user">
                            <Form.Label>Người phụ trách</Form.Label>
                            <Form.Select name="assigned_user" value={inputs.assigned_user} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn người phụ trách</option>
                                {
                                    saleList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.full_name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        {constants.arr_reject_status_ids.includes(parseInt(inputs.status)) &&
                            <Form.Group as={Col} md="5" controlId="status_reject">
                                <Form.Label>Nguyên nhân từ chối <span className="text-danger">*</span></Form.Label>
                                <Form.Select required name="status_reject" value={inputs.status_reject}
                                             onChange={handleChange}>
                                    <option value="" defaultValue>Vui lòng chọn nguyên nhân</option>
                                    {
                                        constants.status_rejects.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.title}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Xin vui lòng chọn nguyên nhân từ chối
                                </Form.Control.Feedback>
                            </Form.Group>
                        }
                        {constants.arr_call_status_ids.includes(parseInt(inputs.status)) &&
                            <>
                                <Form.Group as={Col} md="3" controlId="status">
                                    <Form.Label>Ngày gọi</Form.Label>
                                    <DatePicker className="form-control" dateFormat={"dd/MM/yyyy"} showIcon
                                                placeholderText="Ngày xếp lịch gọi"
                                                selected={inputs.call_day}
                                                onChange={value => handleChangeDateTime({call_day: value})}/>
                                </Form.Group>
                                <Form.Group as={Col} md="2" controlId="status">
                                    <Form.Label>Giờ gọi</Form.Label>
                                    <Form.Control type="time" name="call_hour" value={inputs.call_hour}
                                                  onChange={handleChange}/>
                                </Form.Group>
                            </>
                        }
                        {constants.arr_test_status_ids.includes(parseInt(inputs.status)) &&
                            <>
                                <Form.Group as={Col} md="5" controlId="status">
                                    <Form.Label>Dạng bài kiểm tra</Form.Label>
                                    <div className="mt-2">
                                        {
                                            constants.writing_skill_tests.map((item, index) => {
                                                return (
                                                    <Form.Check key={index} inline label={item.title}
                                                                name="writing_skill_test" type="radio" value={item.id}
                                                                id={`writing_skill_test-${index}`}
                                                                checked={inputs.writing_skill_test == item.id}
                                                                onChange={handleChange}/>
                                                )
                                            })
                                        }
                                    </div>
                                </Form.Group>
                            </>
                        }
                        {constants.arr_learning_path_status_ids.includes(parseInt(inputs.status)) &&
                            <Form.Group as={Col} md="5" controlId="learning_path_date">
                                <Form.Label>Ngày làm lộ trình</Form.Label>
                                <DatePicker className="form-control"
                                            dateFormat={"dd/MM/yyyy"} showIcon placeholderText="Ngày làm lộ trình"
                                            selected={inputs.learning_path_date ? new Date(inputs.learning_path_date) : new Date()}
                                            onChange={value => handleChangeDateTime({learning_path_date: value})}/>
                            </Form.Group>
                        }
                        <Form.Group as={Col} md="5" controlId="is_test_reminded">
                            <Form.Label>Đã nhắc test</Form.Label>
                            <Form.Check type="switch" name="is_test_reminded" value="1" checked={inputs.is_test_reminded} onChange={handleChange} 
								/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3"></Row>
                </div>
                <div>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabFee} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab className={inputs.check_edit_fees ? 'text-danger' : ''} label="Nộp học phí" {...a11yProps(0)} />
                            <Tab className={inputs.check_edit_fees ? 'text-danger' : ''} label="Hoàn học phí" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabFee} index={0}>
	                    <Row className="mb-3">
		                    <Form.Group as={Col} md="5" controlId="input_fees">
		                        <Form.Label>Số tiền nộp</Form.Label>
		                        <Form.Control className={inputs.check_edit_fees ? 'text-danger' : ''} type="text"
		                                      placeholder="Học phí nộp lần này"
		                                      name="input_fees"
		                                      value={inputs.input_fees && new Intl.NumberFormat().format(inputs.input_fees)}
		                                      onChange={handleChangeFee}/>
		                    </Form.Group>
		                </Row>
                    </TabPanel>
                    <TabPanel value={tabFee} index={1}>
	                    <Row className="mb-3">
		                    <Form.Group as={Col} md="5" controlId="return_fees">
		                        <Form.Label>Số tiền hoàn</Form.Label>
		                        <Form.Control className={inputs.check_edit_fees ? 'text-danger' : ''} type="text"
		                                      placeholder="Học phí hoàn lần này"
		                                      name="return_fees"
		                                      value={inputs.return_fees && new Intl.NumberFormat().format(inputs.return_fees)}
		                                      onChange={handleChangeFee}/>
		                    </Form.Group>
		                </Row>
	                </TabPanel>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="tuition_fees">
                            <Form.Label>Tổng học phí
                            {constants.arr_tuition_fees_required_status_ids.includes(parseInt(inputs.status)) &&
                            	<span className="text-danger"> *</span>
                            }
                            </Form.Label>
                            <Form.Control  required={constants.arr_tuition_fees_required_status_ids.includes(parseInt(inputs.status))} className={inputs.check_edit_fees ? 'text-danger' : ''} type="text"
                                          placeholder="Tổng học phí phải đóng"
                                          name="tuition_fees"
                                          value={inputs.tuition_fees && new Intl.NumberFormat().format(inputs.tuition_fees)}
                                          onChange={handleChangeFee}/>
	                        <Form.Control.Feedback type="invalid">
		                        Xin vui lòng điền Tổng học phí
		                    </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="tuition_fee_paid">
                            <Form.Label>Học phí đã nộp</Form.Label>
                            <Form.Control className={inputs.check_edit_fees ? 'text-danger' : ''} type="text" disabled
                                          placeholder="Học phí đã nộp"
                                          name="tuition_fee_paid"
                                          value={inputs.tuition_fee_paid && new Intl.NumberFormat().format(inputs.tuition_fee_paid)}
                                          onChange={handleChangeFee}/>
                        </Form.Group>
                        <Form.Group as={Col} md="5" className="position-relative">
                            <Button type="button" variant="success" className="position-absolute" style={{bottom: 0}}
                                    onClick={() => setShowModal(true)}>Lịch sử nộp học phí</Button>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="remaining_tuition_fees">
                            <Form.Label>Học phí chưa nộp</Form.Label>
                            <Form.Control className={inputs.check_edit_fees ? 'text-danger' : ''} type="text" disabled
                                          placeholder="Học phí chưa nộp"
                                          name="remaining_tuition_fees"
                                          value={inputs.remaining_tuition_fees && new Intl.NumberFormat().format(inputs.remaining_tuition_fees)}
                                          onChange={handleChangeFee}/>
                        </Form.Group>
                        {constants.arr_tuition_fees_status_ids.includes(parseInt(inputs.status)) &&
                            <Form.Group as={Col} md="5" controlId="tuition_payment_date">
                                <Form.Label>Ngày hẹn nộp học phí</Form.Label>
                                <DatePicker disabled={inputs.remaining_tuition_fees <= 0} className="form-control"
                                            dateFormat={"dd/MM/yyyy"} showIcon placeholderText="Ngày hẹn nộp học phí"
                                            selected={inputs.tuition_payment_date}
                                            onChange={value => handleChangeDateTime({tuition_payment_date: value})}/>
                            </Form.Group>
                        }
                    </Row>
                    <Row className="mb-3"></Row>
                </div>
                <Row>
                    <Col sm={4}>
                        <h5>Ghi chú</h5>
                    </Col>
                </Row>
                <hr className='col-md-10'/>
                <div>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="call_note">
                            <Form.Label>Ghi chú cuộc gọi</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Ghi chú cuộc gọi" name="call_note"
                                          value={inputs.call_note} onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="sale_note">
                            <Form.Label>Sale ghi chú</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Ghi chú của sale" name="sale_note"
                                          value={inputs.sale_note} onChange={handleChange}/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="5" controlId="MKT_note">
                            <Form.Label>MKT ghi chú</Form.Label>
                            <Form.Control as="textarea" rows="3" placeholder="Ghi chú MKT" name="MKT_note"
                                          value={inputs.MKT_note} onChange={handleChange}/>
                        </Form.Group>
                    </Row>
                </div>
            </Form>
            <ConfirmContinueModal show={openConfirm} setShowModal={setOpenConfirm} actionOk={continueSaveStudentInfo} />
            <HistoryFeePaidListModal show={showModal} setShowModal={setShowModal} listFeePaids={listFeePaids}
                                     actionOk={() => setShowModal(false)}/>
            {<HistoryUpdateModal show={showUpdateModal} setShowModal={setShowUpdateModal} listData={changeHistories}
                                     actionOk={() => setShowUpdateModal(false)}/>}
            {
                Boolean(idShowTest) &&
                <TestModal
                    showModal={idShowTest}
                    setShowModal={setIdShowTest}
                />
            }
        </Fragment>
    );
};

const TabPanel = props => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="mt-2">{children}</div>
            )}
        </div>
    );
}

export default StudentInfoDetail;
