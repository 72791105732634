import React, {useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import Modal from 'react-bootstrap/Modal';
import {toast} from "react-toastify";
import {pick} from "lodash";

export default function SaleInfoModal({ visible, hide }) {

    const [me, setMe] = useState({})
    const fetchMe = async () => {
        const res = await axiosCommon.post(ApiPath.me).catch(() => null)
        if(res) {
            setMe(res.data)
        }
    }

    const updateField = (event) => {
        setMe({
            ...me,
            [event.target.name]: event.target.value
        })
    }

    const handleUpdate = async () => {
        const userId = sessionStorage.id
        const res = await axiosCommon.put(ApiPath.updateMe, 
            pick(me, ['full_name', 'phone', 'email'])
        ).catch(e => e.response.data)
        if(!res.error) {
            toast.success('Cập nhật thành công!', {
                autoClose: 3000,
                hideProgressBar: true,
                position: "top-right",
                closeOnClick: true,
            })
            hide()
        } else {
            if(res.error.email) {
                return toast.error('Email đã tồn tại!', {
                    autoClose: false,
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                })
            }
            toast.error('Cập nhật thất bại, vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        }
    }

    useEffect(() => {
       fetchMe()
    }, [visible])

    if (!visible) return
    return (
        <Modal show={visible} onHide={hide}>
            <Modal.Header closeButton className="d-flex align-content-center">
                <Modal.Title>Thông tin cá nhân {me?.role_id !== 6 &&  '- NV' + me.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex gap-2 flex-column px-3">
                <div className='row'>
                    <Form.Group className='col'>
                        <Form.Label>Họ và tên</Form.Label>
                        <Form.Control type="text" name="full_name" value={me.full_name} required onChange={updateField}/>
                    </Form.Group>
                    <Form.Group className='col'>
                        <Form.Label>Số điện thoại</Form.Label>
                        <Form.Control type="text" name="phone" value={me.phone} onChange={updateField}/>
                    </Form.Group>
                </div>
                <div className='row'>
                    <Form.Group className='col'>
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" value={me.user_name} disabled/>
                    </Form.Group>
                    {me?.role_id !== 6 &&
                        <Form.Group className='col'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" name="email" value={me.email} onChange={updateField}/>
                        </Form.Group>
                    }
                  
                </div>
                <Button className="mt-3 mb-4" variant='info' onClick={handleUpdate}>Update</Button>
            </Modal.Body>
        </Modal>
    )
}

