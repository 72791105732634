import React from 'react';
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import Loading from "../../components/main/Loading";
import constants from "../../config/constants";
import DashboardStudent from "./DashboardStudent";
import Dashboard from "./DashboardAdmin";

function Index() {
    const {currentUser} = useSnapshot(userProxy)
    const role = currentUser?.role_id
    if (!role) return <Loading show={true}/>
    return currentUser?.role_id === constants.roles.STUDENT.id ?
        <DashboardStudent/> : <Dashboard/>
    
}

export default Index;
