import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Grid} from "@mui/material";
import {Button} from "react-bootstrap";
import axiosCommon from "../../../config/axiosCommon";
import {ApiPath} from "../../../apiPath";
import {isEmpty} from "lodash";
import {setStudentCourseDetail} from "../../../common/function";

export function CopyTimeTable() {
    const [selectTable, setSelectTable] = useState(null)

    const [dataOption, setDataOption] = useState([])
    const getListStudent = () => {
        axiosCommon.get(ApiPath.students, {params: {per_page: 1000000}})
            .then((res) => {
                let dataApi = res.data.data.data
                let newData = []
                dataApi.map((item => {
                    newData.push({...item, label: item.full_name, value: item.id})
                }))
                setDataOption(newData)
            })
    }

    const handleCopy = () => {
        axiosCommon.get(ApiPath.detailStudent + selectTable.id)
            .then((res) => {
                const student = res.data;
                setStudentCourseDetail(student.data.course_detail);
            })
    }

    useEffect(() => {
        getListStudent();
    }, [])

    return (
        <>
            <Grid container className={"my-2"}>
                <Grid item xs={12} md={2} className={`d-flex`}>
                    <label
                        className="form-check-label my-auto">
                        Copy Timetable
                    </label>
                </Grid>
                <Grid item xs={6} md={3} style={{zIndex: 30}}>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={selectTable}
                        onChange={(e) => {
                            setSelectTable(e)
                        }}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={dataOption}
                    />
                </Grid>
                <Grid item xs={6} md={3} className={`d-flex`}>
                    <Button
                        disabled={isEmpty(selectTable)}
                        className={`btn btn-sm mx-4 m-auto`}
                        onClick={handleCopy}
                    >Copy</Button>
                </Grid>
            </Grid>
        </>
    );
}
