import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useSnapshot} from "valtio";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import moment from "moment/moment";
import ModalCustom from "./ModalCustom";
import DatePicker from "react-datepicker";
import {saleRepostIdProxy} from "../../store/proxy/saleRepostId.proxy";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {formatMoney, formatMoneyComma} from 'common/function';

function SalesReportModal() {
    const chartRef = useRef(null);
    const {saleRepostId, setSaleRepostId} = useSnapshot(saleRepostIdProxy)
    const [width, setWidth] = useState(0)
    const [data, setData] = useState({
        "targets": {
            "target_of_month": {
                "id": 1,
                "user_id": 1,
                "type_target": 1,
                "target_of_month": 0,
                "month_sale": 1,
                "year_sale": 2023,
                "created_at": "",
                "updated_at": ""
            },
            "target_now": 0
        },
        "revenue_new": 0,
        "revenue_complete": 0,
        "revenue_recovery_debt": 0,
        "number_sold": 0,
        "number_sale": 0,
        "rate_sold": 0,
        "average_revenue": 0
    })
    const [disableSearch, setDisableSearch] = useState(false);
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    const [searchForm, setSearchForm] = useState({
        user_id: saleRepostId,
        start_date: firstDay,
        end_date: new Date()
    });


    const getInfoUser = () => {
        axiosCommon.get(ApiPath.employee + '/' + saleRepostId, {
            params: {
                user_id: saleRepostId,
                start_date: searchForm.start_date ? moment(searchForm.start_date).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
                end_date: searchForm.end_date ? moment(searchForm.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            }
        })
            .then((res) => {
                setData(res.data);
            })
            .finally(() => {
            });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        getInfoUser();
    }

    const previewPDF = async () => {
        const element = chartRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        window.open(pdf.output('bloburl'), '_blank');
    };

    useEffect(() => {
        if (moment(searchForm.start_date).diff(searchForm.end_date, 'minutes') > 0) {
            setDisableSearch(true)
        } else {
            setDisableSearch(false)
        }
    }, [searchForm])

    useEffect(() => {
        if (!saleRepostId) return
        getInfoUser(saleRepostId)
    }, [saleRepostId])

    useEffect(() => {
        let widthData = 0
        if (data?.targets?.target_of_month.target_of_month && data?.targets?.target_now) {
            widthData = data.targets.target_now * 100 / data.targets.target_of_month.target_of_month
        } else if (data.targets.target_now) {
            widthData = 100
        }
        setWidth(widthData)
    }, [data])

    if (!saleRepostId) return
    return (
        <ModalCustom
            title={`Doanh số cá nhân`}
            titleOk={'View PDF'}
            actionOk={() => previewPDF()}
            showModal={Boolean(saleRepostId)}
            setShowModal={() => setSaleRepostId(null)}
            showCancelButton={false}
        >
            <Form onSubmit={onSubmit}>
                <Row className="mb-1 border-b-1">
                    <Col sm={5}>
                        <Form.Group>
                            <Form.Label>Ngày bắt đầu</Form.Label>
                            <DatePicker
                                name="start_date"
                                selected={searchForm?.start_date}
                                onChange={newDate => setSearchForm({ ...searchForm, start_date: newDate})}
                                className={`form-control`}
                                dateFormat={"dd/MM/yyyy"}
                                showIcon
                                maxDate={searchForm.end_date}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={5}>
                        <Form.Group>
                            <Form.Label>Ngày kết thúc</Form.Label>
                            <DatePicker
                                name="end_date"
                                selected={searchForm.end_date}
                                onChange={newDate => setSearchForm({ ...searchForm, end_date: newDate})}
                                className={`form-control`}
                                dateFormat={"dd/MM/yyyy"}
                                showIcon
                                minDate={searchForm?.start_date}
                            />
                            
                        </Form.Group>
                    </Col>
                    <Col sm={1}>
                        <Form.Group>
                            <Form.Label>&nbsp;</Form.Label>
                            <Button
                                disabled={disableSearch}
                                type="submit"
                                className="btn btn-secondary pr-5 pl-2"
                            >
                                <FontAwesomeIcon icon={faSearch}/> 
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <hr/>
            <div ref={chartRef} style={{padding: 20, color: "black", margin: "-20px"}}>
                <h6>Báo cáo doanh số cá nhân</h6>
                <div className={`d-flex my-4 `}>
                    <div style={{width: 400, height: 20, background: "tomato", position: "relative"}}>
                        <div style={{
                            width: `${Math.min(100, width)}%`,
                            height: 20,
                            background: "green",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "white",
                            textAlign: 'center'
                        }}>
                            {`${Math.round(width)}%`}
                        </div>
                    </div>
                    <p className={`ml-10 px-3 text-nowrap font-weight-bold`}>{formatMoneyComma(data.targets.target_now) + ' / ' + formatMoney(data?.targets.target_of_month?.target_of_month)}</p>
                </div>
                <Container className={`container`}>
                    <Row className="my-2">
                        <Col sm={7}>Commission</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data && new Intl.NumberFormat().format(data.commission)}</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Doanh số học viên mới</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data && new Intl.NumberFormat().format(data.revenue_new)}</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Doanh số hoàn thành hoc phí</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data && new Intl.NumberFormat().format(data.revenue_complete)}</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Doanh số thu hồi công nợ</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data && new Intl.NumberFormat().format(data.revenue_recovery_debt)}</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Số lượng chốt</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data?.number_sold}</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Số lượng được nhận</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data?.number_sale}</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Tỉ lệ chốt</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data?.rate_sold}%</Col>
                    </Row>
                    <Row className="my-2">
                        <Col sm={7}>Doanh số trung bình</Col>
                        <Col sm={1}>:</Col>
                        <Col sm={4}>{data && new Intl.NumberFormat().format(data.average_revenue)}</Col>
                    </Row>
                </Container>
            </div>
        </ModalCustom>
    )
}

export default SalesReportModal;
