import React, { useEffect, useState} from "react";
import { Form, Modal, Button, InputGroup } from 'react-bootstrap';

export default (props) => {
    const {
        show,
        setShowModal,
		actionOk,
        actionCancel = '',
        currentUnit,
    } = props
    
    const [description, setDescription] = useState('');

    const handleClose = () => {
		if (actionCancel) {
			actionCancel();
        }
		setShowModal(false);
	};

    const handleOk = () => {
        actionOk(description)
        handleClose()
    };
    
    useEffect(() => {
        if (show) {
            setDescription(currentUnit.note);
        }
    },[show])
    
    return (
        <>
            <Modal as={Form} show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Mô tả bài học</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4">
                        <Form.Label>Mô tả cho bài học {currentUnit.lesson}</Form.Label>
                        <InputGroup>
                            <Form.Control name="description" as="textarea" value={description} onChange={(e) => setDescription(e.target.value)} rows="5" placeholder="Nhập mô tả cho bài học" />
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="info" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
