import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {BsPersonPlusFill, BsShieldLockFill,} from "react-icons/bs";
import {toast} from 'react-toastify';

import Modal from 'react-bootstrap/Modal';
import Loading from "components/main/Loading";
import Breadcrumbs from "components/main/Breadcrumbs";
import {Routes} from "routes";
import {generateRandomPassword, getRoleName, setPageTitle, formatDate, changeQueryString, addReturnUrlToParamString, getSearchParams} from "common/function";
import constants from "config/constants";
import axiosCommon from 'config/axiosCommon';
import {ApiPath} from "apiPath";
import DatePicker from "react-datepicker";

export default function ListStaffs() {
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const [staff, setStaff] = useState(null)
    const [newPassword, setNewPassword] = useState('')
    const [staffs, setStaffs] = useState([]);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [isConfirmChangePasswordOpen, setIsConfirmChangePasswordOpen] = useState(false)
    const [totalRows, setTotalRows] = useState(1);
    const [key, setKey] = useState(Date.now());

    const [search, setSearch] = useState({
        full_name: "",
        created_at: "",
        role_id: "",
        per_page: constants.arrPerPage[0],
        page: 1,
    })

    const fetchListStaff = async  (inputs = null) => {
        setIsLoading(true)
		let searchInfo = {...search, created_at: formatDate(search.created_at, '', 'YYYY-MM-DD')}
		if (inputs) {
			searchInfo = {...searchInfo, ...inputs}
		} else {
			changeQueryString(searchInfo)
		}
        const res = await axiosCommon.get(ApiPath.staffs, { params: searchInfo }).catch(() => null)
        if(res) {
            setStaffs(res.data.data)
			setTotalRows(res.data.total)
        } else {
            toast.error('Vui lòng tải lại trang!', {
				autoClose: false,
				position: "top-right",
				hideProgressBar: true,
				closeOnClick: true,
			})
        }
        setIsLoading(false)
    }

    const handleDeleteButtonClick = (staff) => {
        setStaff(staff)
        setIsConfirmDeleteOpen(true)
    }

    const handleChangePasswordButtonClick =(staff) => {
        setStaff(staff)
        setNewPassword(generateRandomPassword())
        setIsConfirmChangePasswordOpen(true)
    }

    const handleChangePasswordStaff = async ({ id }, newPassword) => {
        setIsConfirmChangePasswordOpen(false)
        setIsLoading(true)
        const res = await axiosCommon.put(ApiPath.adminResetPassword + `/${id}`, { password: newPassword }).catch(() => null)
        if(res) {
            toast.success('Đổi mật khẩu thành công!', {
                autoClose: 3000,
                hideProgressBar: true,
                position: "top-right",
                closeOnClick: true,
            })
        } else {
            toast.error('Đổi mật khẩu thất bại, vui lòng thử lại!', {
				autoClose: false,
				position: "top-right",
				hideProgressBar: true,
				closeOnClick: true,
			})
        }
        setIsLoading(false)
    }

    const handleDeleteStaff = async ({ id, user_name}) => {
        setIsConfirmDeleteOpen(false)
        setIsLoading(true)
        const res = await axiosCommon.delete(ApiPath.staffs + `/${id}`).catch(() => null)
        setIsLoading(false)
        if(res) {
            await fetchListStaff()
            toast.success('Xoá nhân viên thành công!', {
                autoClose: 3000,
                hideProgressBar: true,
                position: "top-right",
                closeOnClick: true,
            })
        } else {
            toast.error('Xoá nhân viên thất bại, vui lòng thử lại!', {
				autoClose: false,
				position: "top-right",
				hideProgressBar: true,
				closeOnClick: true,
			})
        }
    }

    useEffect(() => {
        setPageTitle('Quản lý nhân viên');
        setKey(Date.now())
		const searchParams = getSearchParams()
		setSearch({ ...search, ...searchParams, created_at: searchParams.created_at ? new Date(searchParams.created_at) : ''})
        fetchListStaff(searchParams);
        const notification = location.state?.notification
        if(notification) {
            switch(notification.type) {
                case 'success':
                    toast.success(notification.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        position: "top-right",
                        closeOnClick: true,
                    })
                    break
            }
            navigate(location.pathname + location.search, { replace: true })
        }
    }, [location])

    const handlePageChange = (page) => {
        const searchParams = {
            ...search,
            page: page
        }
        setSearch(searchParams)
        changeQueryString(searchParams)
        fetchListStaff(searchParams)
    }
    const handlePerPageChange = (newPerPage) => {
        const searchParams = {
            ...search,
            per_page: newPerPage,
            page: 1
        }
        setSearch(searchParams)
        changeQueryString(searchParams)
        fetchListStaff(searchParams)
    }

    const columnsConfig = [
        {
            name: 'STT',
            selector: row => row.id,
            sortable: true,
            width: '100px',
            cell: row =>  <span>{row.id}{Array(6).fill(<>&nbsp;</>)}</span>,
            center: true
        },
        {
            name: 'Họ và tên',
            selector: row => row.full_name || '-',
            grow: 6,
        },
        {
            name: 'SĐT',
            selector: row => row.phone || '-',
            grow: 4,
        },
        {
            name: 'Email',
            selector: row => row.email || '-',
            grow: 6,
            sortable: true
        },
        {
            name: 'Tên tài khoản',
            selector: row => row.user_name || '-',
            grow: 6,
            sortable: true,
        },
        {
            name: 'Quyền hạn',
            selector: row => getRoleName(row.role_id),
            grow: 4,
            sortable: true,
        },
        {
            name: 'Kích hoạt',
            cell: row => <input type="checkbox" checked={row.is_active} readOnly/>,
            selector: row => row['is_active'],
            grow: 4,
            sortable: true,
            center: true,
        },
        {
            name: '',
            grow: 4,
            cell: (row) => {
                return (
                    <div className="d-flex gap-1">
                        <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa nhân viên</Tooltip>}>
                            <NavLink className={'btn btn-info btn-sm'} to={`/staffs/${row.id}/edit?` + addReturnUrlToParamString() }>
                                <i className="fa-regular fa-pen-to-square"></i>
                            </NavLink>
                        </OverlayTrigger>
                        <OverlayTrigger placement='left' overlay={<Tooltip>Đổi mật khẩu</Tooltip>}>
                            <Button className="btn btn-warning btn-sm" onClick={() => handleChangePasswordButtonClick(row)}>
                                <BsShieldLockFill  size={16} fill="white"/>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='left' overlay={<Tooltip>Xoá nhân viên</Tooltip>}>
                            <Button className="btn btn-danger btn-sm" onClick={() => handleDeleteButtonClick(row)}>
                                <i className="fa-regular fa-trash-can"></i>
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            },
        },
    ]

    const tableStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        }
    }
    
    return (
        <>
            <Loading show={isLoading}/>
            <div className="row mb-3">
                <div className="col">
                    <Breadcrumbs firstLocation={Routes.Staffs}/>
                </div>
                <div className="col d-flex justify-content-end align-items-center">
                    <NavLink className="btn btn-tertiary d-flex align-items-center" style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}} to={Routes.CreateStaff.path + '?' + addReturnUrlToParamString()}>
                        <BsPersonPlusFill size={20}/> &nbsp; Thêm nhân viên
                    </NavLink>
                </div>
            </div>
      
            <div className="row mb-3">
                <div className="col">
                    <Form.Label>Tên nhân viên</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tên nhân viên"
                        value={search.full_name}
                        onChange={e => setSearch({ ...search, full_name: e.target.value })}
                    />
                </div>
                <div className="col">
                    <Form.Label>Quyền hạn</Form.Label>
                    <Form.Select
                        type="text"
                        placeholder="Quyền hạn"
                        value={search.role_id}
                        onChange={e => setSearch({ ...search, role_id: e.target.value })}
                    >
                        <option value="">Tất cả</option>
                        {Object.values(constants.roles).map(role => <option value={role.id} key={role.id}>{role.name}</option>)}
                    </Form.Select>
                </div>
                <div className="col">
                    <Form.Label>Ngày tạo</Form.Label>
                    <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon placeholderText="Ngày tạo" selected={search.created_at} onChange={value => setSearch({ ...search, created_at: value })}/>
    
                </div>
                <div className="col d-flex flex-column justify-content-end">
                    <Button variant="secondary" style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}} onClick={() => fetchListStaff()}>Tìm kiếm</Button>
                </div>
            </div>
           
            <DataTable
                key={key}
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={{
                    rowsPerPageText: 'Số hàng mỗi trang:',
                    rangeSeparatorText: 'của',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Tất cả',
                }}
                onChangePage={handlePageChange}
                currentPage={search?.page}
                title="Danh sách nhân viên"
                columns={columnsConfig}
                data={staffs}
                pagination
                customStyles={tableStyles}
                onChangeRowsPerPage={handlePerPageChange}
                paginationRowsPerPageOptions={constants.arrPerPage}
                paginationPerPage={parseInt(search?.per_page)}
                noDataComponent={<div className="p-4">Không tìm thấy nhân viên nào.</div>}
            />

            <Modal show={isConfirmDeleteOpen} onHide={() => setIsConfirmDeleteOpen(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>"Xác nhận xoá nhân viên"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bạn có muốn xoá nhân viên "{staff?.user_name}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setIsConfirmDeleteOpen(false)} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteStaff(staff)}>OK</Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={isConfirmChangePasswordOpen} onHide={() => setIsConfirmChangePasswordOpen(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>"Xác nhận đổi mật khẩu"</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Mật khẩu của nhân viên "{staff?.user_name}" sẽ được đổi thành: </span><br/>
                    <span className="text-underline font-italic font-weight-normal">{newPassword}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setIsConfirmChangePasswordOpen(false)} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={() => handleChangePasswordStaff(staff, newPassword)}>OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
