import React, {useEffect, useState} from "react";
import {Col, Row, Form, Button, Container, Alert} from 'react-bootstrap';
import BgImage from "../../assets/img/signin.svg";
import Loading from "../../components/main/Loading";
import axiosCommon from "../../config/axiosCommon";
import {setPageTitle} from "../../common/function";
import OtpInput from "react-otp-input";
import {Routes} from "../../routes";

export default () => {
    const [loaded, setLoaded] = useState(false);
    let email = new URLSearchParams(window.location.search).get("email");
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [otp, setOtp] = useState('');
    const onSubmit = (e) => {
        setLoaded(true);
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setLoaded(false);
            return;
        }
        if (!email) {
            setErrorText('Email không tồn tại!')
            setShowAlert(true)
            return
        }
        setErrorText('')
        setShowAlert(false)
        axiosCommon.post('/api' + Routes.ForgotPasswordCheckCode.path, {email, verify_code: otp})
            .then(res => {
                    setShowAlert(false)
                    window.location.href = '/forgot-password/reset-password?email=' + email + '&verify_code=' + otp
                },
                error => {
                    setLoaded(false);
                    if (error.response && error.response.data) {
                        setErrorText('Email hoặc mã không đúng vui lòng kiểm tra lại hoặc liên hệ admin!')
                        setShowAlert(true)
                    } else {
                        setErrorText('Đã xảy ra lỗi đăng nhập hệ thống. Vui lòng thử lại')
                        setShowAlert(true)
                    }
                },
            )
    }

    useEffect(() => {
        setPageTitle('Quên mật khẩu');
    }, [])


    return (
        <main>
            <Loading show={loaded}/>
            <section className="align-items-center mt-lg-5 mb-lg-5">
                <Container>
                    <Row className="justify-content-center form-bg-image" style={{backgroundImage: `url(${BgImage})`}}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">OTP</h3>
                                </div>
                                <Alert
                                    variant="danger"
                                    show={showAlert}
                                    onClose={() => setShowAlert(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlert(false)}/>
                                    </div>
                                </Alert>
                                <Alert
                                    variant="success"
                                    show={showAlertSuccess}
                                    onClose={() => setShowAlertSuccess(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlertSuccess(false)}/>
                                    </div>
                                </Alert>
                                <div>Một mã OTP đã được gửi đến mail của bạn, vui lòng nhập mã để xác nhận tài khoản
                                    mail và xác nhận để tiếp tục thay đổi mật khẩu!
                                </div>
                                <Form className="mt-4">
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>Nhập mã code:</Form.Label>
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            renderSeparator={<span></span>}
                                            renderInput={(props) => <input
                                                {...props}
                                                type="text"
                                                className="form-control otp-input text-center mx-1 mt-3"
                                                style={{width: 52}}
                                            />}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={onSubmit} className="w-100">Xác
                                        minh</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
