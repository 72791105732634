import React, { useEffect, useState, Fragment, useRef } from "react";
import {ApiPath} from "../../apiPath";
import axiosCommon from '../../config/axiosCommon';
import Breadcrumbs from "../../components/main/Breadcrumbs";
import { Card, Col, Row, Form, InputGroup, ProgressBar, Button } from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import { Routes } from "../../routes";
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import "../../scss/student-info.scss";
import {formatDate, checkRoleNavBar, setPageTitle, getTitleFromId} from "../../common/function";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import constants from "../../config/constants";
import Chart from 'chart.js/auto'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faChartLine, faChartPie, faChartColumn } from '@fortawesome/free-solid-svg-icons';

function DashboardStudent(props) {
    const navigate = useNavigate();
    const {currentUser} = useSnapshot(userProxy)
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [inputs, setInputs] = useState({
        assigned_user:''
    });
    
    useEffect(() => {
        setIsLoading(true)
        setPageTitle('Dashboard');
        axiosCommon.get(ApiPath.studentDashboard)
            .then((res) => {
                setData(res.data.data)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [])
    
    const handleChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (value && event.target.attributes['number']?.value) {
            value = value.match(/\d/g)?.join('') ?? '';
        } else if (event.target.attributes['type']?.value == 'checkbox') {
			value = event.target.checked
		}
        setInputs(values => ({...values, [name]: value}))
    }
    
	const cardStyle = (index) => {
		const colors = ['#61DAFB', '#F5B759', '#1B998B', '#0948B3', '#FA5252', '#05A677', '#8965E0', '#C96480'];
		return {
			borderLeftColor: colors[index%colors.length], 
			borderTopLeftRadius: '4px', 
			borderLeftWidth: '3px', 
			borderBottomLeftRadius: '4px'
		}
	}
    return (
        <div className={`m-4`}>
            <Loading show={isLoading}/>
        	<div className="mt-3">
        		<h4>Danh sách khóa học của bạn</h4>
        	</div>
    		<Row className="mb-3">
				{ data.map((item, index) => { 
				let courseDetail = JSON.parse(item.course_detail)
				let timeStudy = item.time_study ? item.time_study + ' ' + getTitleFromId(item.type_time_study, constants.time_study_types) : courseDetail?.studyDetail?.week + ' tuần'
				let timeStudyWeekDay = courseDetail?.studyDetail ? courseDetail?.studyDetail?.week + ' tuần - ' + courseDetail?.studyDetail?.day + ' buổi/tuần' :''
				let timeStudyTotal = courseDetail?.studyDetail ? courseDetail?.studyDetail?.unitTotal + ' buổi' : ''
				
				return (
				<Col xs={12} sm={6} xl={4} className="mb-4" key={index}>
					<Card className="col-12 col-sm-10" style={cardStyle(index)}>
						<Card.Body>
                            {item?.course_name && <h5>{item?.course_name}</h5>}
							{/*<p><i>Thời gian học:</i> {timeStudy}</p>*/}
							<p><i>Lộ trình học :</i> {timeStudyWeekDay}</p>
                            {item?.total_assistants_session && <p><i>Số buổi học với trợ giảng :</i> {item?.total_assistants_session}</p>}
                            {item?.total_test_session && <p><i>Số bài Test với GVNN :</i> {item?.total_test_session}</p>}
                            {item?.total_admin_correct_task && <p><i>Số bài chữa với thầy Phi :</i> {item?.total_admin_correct_task}</p>}
                            {item?.total_examiner_correct_task && <p><i>Số bài chữa với cựu Examiner :</i> {item?.total_examiner_correct_task}</p>}
							{/*<p><i>Tổng số buổi học :</i> {timeStudyTotal}</p>*/}
							<Button as={Link} to={`/course-details/${item.id}`} className="me-1" variant="info">Chi tiết</Button>
							{/* <Button className="me-1" variant="tertiary">Ghi chú</Button> */}

						</Card.Body>
					</Card>
				</Col>)
				})}
	        </Row>
        </div>
    );
}

export default DashboardStudent;
