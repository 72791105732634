import {proxy} from "valtio/vanilla";

const userProxy = proxy({

    userSelect: '',
    currentUser: {},
    saleList: [],
    salesPlusList: [],

    setUserSelect: (data) => {
        userProxy.userSelect = data
    },
    setCurrentUser: (data) => {
        userProxy.currentUser = {...data}
    },
    setSaleList: (data) => {
        userProxy.saleList = [...data]
    },
    setSalesPlusList: (data) => {
        userProxy.salesPlusList = [...data]
    },

})

export {
    userProxy
}
