import React, {Fragment, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import {snapshot} from "valtio";
import {questionProxy} from "../../store/proxy/question.proxy";
import {Button} from "react-bootstrap";
import {Grid} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import {cloneDeep, isArray} from "lodash";
import ModalCustom from "../../components/Custom/ModalCustom";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import {v4 as uuid} from 'uuid';
import {toast} from "react-toastify";
import {setPageTitle} from "../../common/function";

function Question() {
    const {questionType, questionData, defaultQuestion, textQuestionType} = snapshot(questionProxy)
    const [questions, setQuestion] = useState([]);
    const [showModalDelAns, setShowModalDelAns] = useState(false);
    const [idDel, setIdDel] = useState();
    const [errList, setErrList] = useState([]);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(questions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setQuestion(items);
    }

    const handleUpAndDown = (indexFrom, indexTo) => {
        if (questions[indexFrom] && questions[indexTo]) {
            let arr = cloneDeep(questions)
            let temp = arr[indexFrom];
            arr[indexFrom] = arr[indexTo];
            arr[indexTo] = temp;
            setQuestion(arr)
        }
    }
    const handleAddNewQuestion = (index) => {
        const id = uuid();
        let _questions = cloneDeep(questions)
        let element = {
            id: id,
            title: '',
            type: 1,
            is_required: true,
            answers: null
        }
        _questions.splice(index + 1, 0, element);
        setQuestion(_questions)
    }

    const handleChangeValue = (index, name, e) => {
        let _questions = cloneDeep(questions)
        _questions[index] = {
            ..._questions[index],
            [name]: name === 'is_required' ? e.target.checked : e.target.value
        }
        setQuestion(_questions)
    }

    const handleChangeAns = (index, indAns, e) => {
        let _questions = cloneDeep(questions)
        let arrAns = _questions[index]?.answers
        arrAns[indAns] = e.target.value
        _questions[index] = {
            ..._questions[index],
            answers: arrAns
        }
        setQuestion(_questions)
    }
    const handleAddOrDelAns = (index, indAns = undefined) => {
        let _questions = cloneDeep(questions)
        let arrAns = _questions[index]?.answers || []
        if (indAns === undefined) {
            if (arrAns.length === 0) {
                arrAns = [""]
            } else {
                arrAns.push("");
            }
        } else {
            arrAns.splice(indAns, 1)
        }
        _questions[index] = {
            ..._questions[index],
            answers: arrAns
        }
        setQuestion(_questions)
    }
    const handleDelAns = async () => {
        if (idDel !== null) {
            let _questions = cloneDeep(questions)
            _questions.splice(idDel, 1)
            setQuestion(_questions)
        }
    }

    const checkValidate = () => {
        let err = []
        questions.map((question, index) => {
            if (!question.title) {
                err.push("Câu" + (index + 1) + ": Title không được bỏ trống.")
            }
            if (question?.type == questionType[1] || question?.type == questionType[2]) {
                if (!question?.answers) {
                    err.push("Câu" + (index + 1) + ": Câu trả lời của " + textQuestionType[question.type - 1] + " không được bỏ trống.")
                    return
                }
                question?.answers.map((que, ind) => {
                    if (que === "") {
                        err.push("Câu" + (index + 1) + ": Câu trả lời thứ " + (ind + 1) + " không được bỏ trống.")
                    }
                })
            }
        })
        setErrList(err)
        return Boolean(err.length)
    }
    const handleSubmit = () => {
        const check = checkValidate()
        if (check) return
        let dataQues = cloneDeep(questions)
        dataQues.map((val) => {
            val.answers = JSON.stringify(val.answers)
        })
        axiosCommon.post(ApiPath.question, {data: dataQues})
            .then(response => {
                toast.success('Lưu thành công!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    position: "top-right",
                    closeOnClick: true,
                })
            }).catch(err => {
            toast.error('Lưu thất bại, vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        })
            .finally(() => {
                getData()
            });
    }

    useEffect(() => {
        setQuestion(questionData)
    }, [questionData])

    useEffect(() => {
        if (!showModalDelAns) {
            setIdDel(null)
        }
    }, [showModalDelAns])

    const getData = () => {
        axiosCommon.get(ApiPath.question)
            .then(
                response => {
                    if (response.data.length) {
                        let newData = []
                        response.data.map((val, ind) => {
                            newData[ind] = {
                                id: "ans-" + val?.id,
                                is_required: Boolean(val?.is_required),
                                type: val?.type_qa,
                                title: val?.questions,
                                answers: val?.answers ? JSON.parse(val?.answers) : null
                            }
                        })
                        setQuestion(newData)
                    } else {
                        setQuestion([defaultQuestion])
                    }
                },
            )
            .finally(() => {
            });
    }

    useEffect(() => {
        getData()
        setPageTitle('Quản lý câu hỏi');
    }, [])

    return (
        <Fragment>
            {/*<Loading show={!loaded}/>*/}
            <Breadcrumbs firstLocation={Routes.Question}/>
            <div>
                <div className="" style={{display: "flow-root"}}>
                    <Button type="submit" onClick={handleSubmit}
                            className="btn btn-secondary m-2 float-end" 
                            style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}>Lưu</Button>
                </div>
                <div className="bg-white p-3">
                    {errList.length > 0 &&
                        <div className="mx-3">{
                            errList.map((err, index) => {
                                return <div className={`text-danger`}>{err}</div>
                            })
                        }</div>

                    }
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="questions">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {questions && questions.map((question, index) => {
                                        return (
                                            <Draggable key={question?.id} draggableId={question?.id} index={index}>
                                                {(provided) => (
                                                    <Grid
                                                        className="border rounded border-secondary bgLightBlueCustom m-3 p-2 mb-5"
                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                    >
                                                        <div className={`position-relative`}>
                                                            <div className={`w-full position-relative`}>
                                                                <div>Câu {index + 1}</div>
                                                                {questions.length > 1 &&
                                                                    <div className={`position-absolute right-0 top-0`}>
                                                                        <CloseIcon
                                                                            onClick={() => {
                                                                                setShowModalDelAns(true)
                                                                                setIdDel(index)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <Grid container className={"m-2"}>
                                                                <Grid item xs={12} md={3}>
                                                                    <label
                                                                        className="form-check-label mr-10">
                                                                        Kiểu câu hỏi
                                                                    </label>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <select
                                                                        value={question?.type}
                                                                        onChange={(e) => handleChangeValue(index, 'type', e)}
                                                                        className="form-select"
                                                                        aria-label="Default select example">
                                                                        {questionType.map((queType, ind) => {
                                                                            return <option
                                                                                value={queType}>{textQuestionType[ind]}</option>
                                                                        })}
                                                                    </select>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container className={"m-2"}>
                                                                <Grid item xs={12} md={3}>
                                                                    <label
                                                                        className="form-check-label mr-10">
                                                                        Tiêu đề câu hỏi
                                                                    </label>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={question?.title}
                                                                        onChange={(e) => handleChangeValue(index, 'title', e)}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container className={"m-2"}>
                                                                <Grid item xs={12} md={3}>
                                                                    <label
                                                                        style={{height: 43.59}}
                                                                        className="form-check-label mr-10"
                                                                        htmlFor={`flexSwitch-${question?.id}`}>
                                                                        Bắt buộc
                                                                    </label>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            role="switch"
                                                                            id={`flexSwitch-${question?.id}`}
                                                                            checked={question?.is_required}
                                                                            onChange={(e) => handleChangeValue(index, 'is_required', e)}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                            {
                                                                (question?.type == questionType[1] || question?.type == questionType[2]) &&
                                                                <Grid container className={"m-2"}>
                                                                    <Grid item xs={12} md={3}>
                                                                        <label
                                                                            className="form-check-label mr-10"
                                                                            htmlFor={`flexSwitch-${question?.id}`}>
                                                                            Câu trả lời
                                                                        </label>
                                                                        <AddCircleOutlineTwoToneIcon
                                                                            onClick={() => {
                                                                                handleAddOrDelAns(index)
                                                                            }}/>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        {
                                                                            isArray(question?.answers) &&
                                                                            question?.answers.map((ans, indAns) => {
                                                                                return (
                                                                                    <div className="d-flex my-1">
                                                                                        <input
                                                                                            key={indAns + "ans"}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            value={ans}
                                                                                            onChange={(e) => handleChangeAns(index, indAns, e)}
                                                                                        />
                                                                                        <i
                                                                                            onClick={() => {
                                                                                                handleAddOrDelAns(index, indAns)
                                                                                            }}
                                                                                            className="fa-solid fa-delete-left m-auto p-2"></i>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                            <Grid
                                                                className={`position-absolute`}
                                                                style={{
                                                                    right: "50%",
                                                                    bottom: "-50px",
                                                                    transform: 'translate(-50%)'
                                                                }}>
                                                                <AddIcon onClick={() => {
                                                                    handleAddNewQuestion(index)
                                                                }}
                                                                         className={`border border-secondary bgLightBlueCustom`}/>
                                                            </Grid>
                                                            <Grid className={`position-absolute`}
                                                                  style={{
                                                                      top: "50%", right: "-44px",
                                                                      transform: 'translate(-50%, -50%)'
                                                                  }}>
                                                                <Grid>
                                                                    <ExpandLessIcon onClick={() => {
                                                                        handleUpAndDown(index, index - 1)
                                                                    }}
                                                                                    className={`border border-secondary bgLightBlueCustom`}/>
                                                                </Grid>
                                                                <Grid>
                                                                    <ExpandMoreIcon onClick={() => {
                                                                        handleUpAndDown(index, index + 1)
                                                                    }}
                                                                                    className={`border border-secondary bgLightBlueCustom`}/>
                                                                </Grid>
                                                            </Grid>
                                                        </div>

                                                    </Grid>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
            <ConfirmModal
                setDefault={handleDelAns}
                showModal={showModalDelAns}
                setShowModal={setShowModalDelAns}
                title={`Xác nhận xoá câu hỏi`}
                content={`Bạn có muốn xoá câu hỏi này không?`}
            />
        </Fragment>
    );
}

function ConfirmModal({setDefault, showModal, setShowModal, title, content}) {
    return (
        <ModalCustom
            title={title}
            actionOk={setDefault}
            showModal={showModal}
            setShowModal={setShowModal}
        >
            <div>{content}</div>
        </ModalCustom>
    )
}

export default Question;
