import {proxy} from "valtio/vanilla";


const notificationProxy = proxy({

    dataNotification: [],
    setDataNotification: (data) => {
        notificationProxy.dataNotification = [...data]
    },

    numberNotificationUnseen: 0,
    setNumberNotificationUnseen: (data) => {
        notificationProxy.numberNotificationUnseen = data
    },
})

export {
    notificationProxy
}
