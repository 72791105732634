import React, {useState} from "react";
import SimpleBar from 'simplebar-react';
import {Link, useLocation} from "react-router-dom";
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faBook,
    faBookOpen,
    faCircleQuestion,
    faClock,
    faGraduationCap,
    faMagnifyingGlassChart,
    faRoute,
    faTimes,
    faUser,
    faUserCheck
} from "@fortawesome/free-solid-svg-icons";
import {Badge, Button, Image, Nav, Navbar} from 'react-bootstrap';
import {Routes} from "../../routes";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import {checkRoleNavBar} from "../../common/function";
import constants from "../../config/constants";

export default (props = {}) => {
    const location = useLocation();
    const {currentUser} = useSnapshot(userProxy)

    const {pathname} = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const onCollapse = () => setShow(!show);

    const NavItem = (props) => {
        const {
            title,
            link,
            external,
            target,
            icon,
            image,
            badgeText,
            badgeBg = "secondary",
            badgeColor = "primary"
        } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? {href: link} : {as: Link, to: link};

        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon}/> </span> : null}
                        {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon"/> : null}
                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor}
                               className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    const navbar = [
        {
            title: "StudentInfos",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id],
            icon: faGraduationCap
        },
        {
            title: "Test",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id, constants.roles.EXPERT.id, constants.roles.EXPERT_LEADER.id],
            icon: faUserCheck
        },
        {
            title: "LearningPaths",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id, constants.roles.EXPERT.id, constants.roles.EXPERT_LEADER.id],
            icon: faRoute
        },
        // {
        //     title: "GoalSetting",
        //     role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id],
        //     icon: faCapsules
        // },
        {
            title: "SynthesisReport",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id],
            icon: faMagnifyingGlassChart,
            hr: true
        },
        {
            title: "SaleHours",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id],
            icon: faClock
        },
        {
            title: "Subskills",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id, constants.roles.EXPERT.id, constants.roles.EXPERT_LEADER.id],
            icon: faBook
        },
        {
            title: "Students",
            role: [constants.roles.ADMIN.id, constants.roles.SALE.id, constants.roles.SALE_LEADER.id, constants.roles.EXPERT.id, constants.roles.EXPERT_LEADER.id],
            icon: faBookOpen,
            hr: true
        },
        {
            title: "Staffs",
            role: [constants.roles.ADMIN.id],
            icon: faUser
        },
        {
            title: "Question",
            role: [constants.roles.ADMIN.id],
            icon: faCircleQuestion
        },

        {
            title: "Courses",
            role: [constants.roles.STUDENT.id],
            icon: faCircleQuestion
        },
    ]

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5" as={Link} to="#">
                    {/* <Image src={ReactHero} className="navbar-brand-light"/> */}
                    <h4 style={{color: 'white'}}>IELTS Xuân Phi </h4>
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon"/>
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                    <div className="sidebar-inner px-1 pt-3" >
                        <div
                            className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </Nav.Link>
                        </div>
                        <h5 className="text-center">
                            <Link to="/" className="text-dark">IELTS Xuân Phi</Link>
                        </h5>
                        <hr style={{color: '#000000'}}/>
                        <Nav className="flex-column pt-3 pt-md-0 main-sidebar mb-5">
                            {navbar.map(value => {
                                return (
                                    <div key={value.title}>
                                        {checkRoleNavBar(value?.role, currentUser?.role_id) &&
                                            <NavItem title={Routes[value.title].name} link={Routes[value.title].path}
                                                     icon={value.icon}/>
                                        }
                                        {(checkRoleNavBar(value?.role, currentUser?.role_id) && value?.hr) && <hr className="m-2" style={{color: '#000000'}}/>}
                                    </div>
                                )
                            })}
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};
