
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import NotFoundImage from "../../assets/img/404.svg";
import {setPageTitle} from "../../common/function";
import {useEffect} from "react";

export default () => {
    useEffect(() => {
        setPageTitle('404 - Không tìm thấy trang');
    },[])

    return (
        <main>
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                            <div>
                                <Card.Link as={Link} to={Routes.Dashboard.path}>
                                    <Image src={NotFoundImage} className="img-fluid w-75" />
                                </Card.Link>
                                <h1 className="text-primary mt-5">
                                    404 - Không tìm thấy trang
                                </h1>
                                <p className="lead my-4">
                                    Không thể tìm thấy nội dung bạn đang tìm kiếm. Vui lòng thử lại trong giây lát.
                                </p>
                                <Button as={Link} variant="primary" className="animate-hover" to={Routes.Dashboard.path}>
                                    <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                                    Quay về trang chủ
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
