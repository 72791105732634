import React, {useEffect} from 'react';
import {Button, Toast} from 'react-bootstrap';
import {useSnapshot} from "valtio";
import {toastProxy} from "../../store/proxy/toast.proxy";
import {cloneDeepWith} from "lodash";

function ToastsCustom() {
    const {contentToast, setContentToast} = useSnapshot(toastProxy)
    const handleClose = () => {
        setContentToast('')
    }
    useEffect(() => {
        if (Boolean(contentToast)) {
            setTimeout(() => {
                handleClose()
            }, 3000)
        }
    }, [cloneDeepWith(contentToast)])
    return (
        <>
            <Toast
                show={Boolean(contentToast)}
                onClose={handleClose}
                style={{position: 'fixed', top: 0, right: 10}}
                className="bg-primary text-white my-3"
                bg="tertiary"
            >
                <Toast.Header className="text-primary" closeButton={false}>
                    <i className="fa-solid fa-triangle-exclamation text-warning"></i>
                    <strong className="me-auto ms-2">Cảnh báo dữ liệu </strong>
                    <Button variant="close" size="xs" onClick={handleClose}/>
                </Toast.Header>
                <Toast.Body>
                    {contentToast}
                </Toast.Body>
            </Toast>
        </>
    );
}

export default ToastsCustom;
