import React, {memo, useEffect, useState} from "react";
import UnitNumberTable from "./UnitNumberTable";
import UnitNameTable from "./UnitNameTable";
import {cloneDeep, cloneDeepWith, isObject} from "lodash";
import WeekHead from "./WeekHead";
import {convertDataSubSkill, distributeUnit} from "../../common/function";

function SkillTable({skills, indexSkill}) {
    const [arrUnit, setArrUnit] = useState()

    useEffect(() => {
        let newUnit = []
        let _skills = cloneDeep(skills)
        _skills.detail.map((skill) => {
                newUnit.push(...skill.unit)
            }
        )
        setArrUnit(cloneDeepWith(newUnit))
    }, [skills])

    return (
        <div>
            <table className="table table-bordered bg-white m-0 text-center"
                   style={{position: 'sticky', top: 0, zIndex: 10}}>
                <thead>
                <tr style={{position: 'sticky', left: 0, zIndex: 1}}>
                    {
                        indexSkill === 0 &&
                        <th rowSpan={2} style={{
                            width: 236,
                            verticalAlign: "middle",
                            left: 0,
                            zIndex: 1
                        }}
                        >Timetable</th>
                    }
                    <th className={`position-relative`}>
                        <h6>
                            <input
                                type='text'
                                readOnly
                                disabled
                                className="text-center ms-2 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={skills.skill}
                            /></h6>
                    </th>
                </tr>
                </thead>
            </table>
            <div className={`d-flex`}>
                <div>
                    {skills.detail.map((skill, ind) => {
                        return (
                            <div className={`d-flex`} key={ind + 'skill_table'}>
                                {
                                    indexSkill === 0 &&
                                    <table className="table table-bordered bg-white m-0 text-center"
                                           style={{width: 105}}>
                                        <thead className="align-middle">
                                        <tr className={`border-bottom-0`}>
                                            <th style={{padding: 10}}>
                                                <WeekHead
                                                    week={skill.week}
                                                />
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                }
                                <div>
                                    <UnitNumberTable
                                        unit={skill.unit}
                                        skill={skill}
                                        indexSkill={indexSkill}
                                        indexDetail={ind}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <UnitNameTable
                        arrUnitIds={distributeUnit(arrUnit, skills?.unitId)}
                        arrSubSillIds={convertDataSubSkill(arrUnit, skills?.subSkillId)}
                        notes={skills?.notes}
                        isComplete={skills?.isComplete || {}}
                        indexSkill={indexSkill}
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(SkillTable);
