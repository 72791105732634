import {memo} from "react";

function WeekHear({week}) {

    return (
        <table className={`text-left`}>
            <tbody>
            <tr>
                <td>Tuần:</td>
                <td>{week}</td>
            </tr>
            </tbody>
        </table>
    )
}

export default memo(WeekHear);
