import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Col, Form, Row} from "react-bootstrap";
import {Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, TextField} from "@mui/material";
import Constants from "../../config/constants";
import SurveyForm from "../survey";
import {NavLink, useLocation} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {Routes} from "../../routes";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import {setPageTitle, getReturnUrlFromParamString} from "../../common/function";
import {toast} from "react-toastify";
import {snapshot, useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import {questionProxy} from "../../store/proxy/question.proxy";
import {cloneDeep, isArray, isEmpty} from "lodash";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TestDetail() {
    const location = useLocation()
    const navigate = useNavigate()
    const {salesPlusList} = useSnapshot(userProxy)
    const id = location.pathname.match(/\d/g).join("");
    const [value, setValue] = React.useState(0);
    const [formEdit, setFormEdit] = React.useState({});
    
    //1111
    const {questionType} = snapshot(questionProxy)
    const [questions, setQuestion] = useState([]);
    const [formData, setFormData] = useState([]);
    const [errFrom, setErrForm] = useState([]);
    const [checkIsUpdate, setCheckIsUpdate] = useState(false);
    const handleChangeSurveyForm = (index, e) => {
        let _formData = cloneDeep(formData)
        if (_formData[index].type === questionType[2]) {
            _formData[index].value = {
                ..._formData[index].value,
                [e.target.name]: e.target.checked
            }
        } else {
            _formData[index].value = e.target.value
        }
        setFormData(_formData)
    }

    const setFormDataDefault = (newDataQues) => {
        if (newDataQues.length > 0) {
            let newData = []
            newDataQues.map((que) => {
                newData.push({
                    is_required: Boolean(que.is_required),
                    type: que.type,
                    title: que.title,
                    value: que.type === questionType[2] ? {} : ""
                })
            })
            setFormData(newData)
        }
    }

    const checkHaveVal = (obj) => {
        for (let o in obj)
            if (obj[o]) return false;
        return true;
    }
    const validateForm = () => {
        let err = []
        if (checkIsUpdate) {
            formData.map((que, index) => {
                if (que.is_required) {
                    if (
                        ((que.type === questionType[0] || que.type === questionType[1]) && (!que.value || que.value === "")) ||
                        (que.type === questionType[2] && checkHaveVal(que.value)))
                        err.push(index)
                }
            })
            setErrForm(err)
            return err
        }
        if (questions.length > 0) {
            questions.map((que, index) => {
                if (que.is_required) {
                    if (
                        (que.type === questionType[0] && !formData[index].value) ||
                        ((que.type === questionType[1] || que.type === questionType[2]) && isEmpty(formData[index].value)))
                        err.push(index)
                }
            })
            setErrForm(err)
        }
        return err
    }

    const submitSurvey = async () => {
        const studentInfoId = formEdit?.student_informations_id
        let checkErr = validateForm()
        if (!checkErr.length) {
            const dataUpdate = {
                student_information_id: studentInfoId,
                survey_qa: JSON.stringify(questions),
                survey_results: JSON.stringify(formData)
            }
            let api = ''
            if (checkIsUpdate) {
                api = axiosCommon.put(ApiPath.SurveyQuestionsResult + '/' + studentInfoId, dataUpdate)
            } else {
                api = axiosCommon.post(ApiPath.SurveyQuestionsResult, dataUpdate)
            }
            try {
                await api.then()
                await axiosCommon.put(ApiPath.markAndCorrect + "/" + formEdit?.id, {
                    ...formEdit,
                    status_mark_test: 1,
                }).then()
                await navigate(getReturnUrlFromParamString() ?? Routes.Test.path);
                toast.success('Lưu thành công!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    position: "top-right",
                    closeOnClick: true,
                })
            } catch (err) {
                toast.error('Lưu thất bại, vui lòng thử lại!', {
                    autoClose: false,
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                })
            }
        }
    }

    const getSurveyQuestion = () => {
        const studentInfoId = formEdit?.student_informations_id
        axiosCommon.get(ApiPath.SurveyQuestionsResult + "/" + studentInfoId)
            .then((res) => {
                if (res.data?.survey_results) {
                    setCheckIsUpdate(true)
                    setQuestion(JSON.parse(res.data?.survey_qa))
                    setFormData(JSON.parse(res.data?.survey_results))
                } else {
                    getData()
                }
            })
        return {}
    }

    const getData = () => {
        axiosCommon.get(ApiPath.question)
            .then(
                response => {
                    let newData = []
                    if (response.data.length) {
                        response.data.map((val, ind) => {
                            newData[ind] = {
                                id: "ans-" + val?.id,
                                is_required: Boolean(val?.is_required),
                                type: val?.type_qa,
                                title: val?.questions,
                                answers: val?.answers ? JSON.parse(val?.answers) : null
                            }
                        })
                        setQuestion(newData)
                    } else {
                        setQuestion([])
                    }
                    setFormDataDefault(newData)
                },
            )
    }
    ///1111

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeEdit = (data) => {
        setFormEdit({
            ...formEdit,
            ...data
        })
    }

    const getListStudentInfo = () => {
        axiosCommon.get(ApiPath.markAndCorrect + "/" + id)
            .then((res) => {
                setFormEdit(res.data.data)
            })
    }

    const handleSubmit = () => {
        axiosCommon.put(ApiPath.markAndCorrect + "/" + id, formEdit)
            .then((res) => {
                navigate(getReturnUrlFromParamString() ?? Routes.Test.path)
                toast.success('Lưu thành công!', {
                    autoClose: 3000,
                    hideProgressBar: true,
                    position: "top-right",
                    closeOnClick: true,
                })
            }).catch(err => {
            toast.error('Lưu thất bại, vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        })
            .finally(() => {
                // setIsLoading(false);
            });
    }

    useEffect(() => {
        setPageTitle('Thông tin học viên');
        getListStudentInfo();
    }, [id])

    useEffect(() => {
        if (!formEdit?.student_informations_id) return
        getSurveyQuestion()
    }, [formEdit])

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Kết quả bài kiểm tra" {...a11yProps(0)} />
                    <Tab label="Thông tin học viên" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div style={{minHeight: "100vh"}}>
                    <div className={`container`} style={{maxWidth: 640, padding: 20}}>
                        <div>
                            {questions.length > 0 &&
                                questions.map((question, index) => {
                                    if (question.type === questionType[0]) {
                                        return (
                                            <div key={"question" + index} className={`form-field my-3 p-3 bg-white rounded`}
                                                 style={{border: errFrom.includes(index) ? "1px solid red" : "1px solid #dadce0"}}>
                                                <div className={`mb-4 d-flex`}>
                                                    <label htmlFor="name">Câu {index + 1}: {question.title}</label>
                                                    {question.is_required &&
                                                        <div className={`text-danger`}>*</div>
                                                    }
                                                </div>
                                                <div className={`mb-2`}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        variant="standard"
                                                        value={formData[index]?.value}
                                                        onChange={(e) => {
                                                            handleChangeSurveyForm(index, e)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (question.type === questionType[1]) {
                                        return (
                                            <div key={"question" + index} className={`form-field my-3 p-3 bg-white rounded`}
                                                 style={{border: errFrom.includes(index) ? "1px solid red" : "1px solid #dadce0"}}>
                                                <div className={`mb-2 d-flex`}>
                                                    <label htmlFor="name">Câu {index + 1}: {question.title}</label>
                                                    {question.is_required &&
                                                        <div className={`text-danger`}>*</div>
                                                    }
                                                </div>
                                                <div className={`mb-2`}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="female"
                                                        name="radio-buttons-group"
                                                    >
                                                        {isArray(question?.answers) &&
                                                            question.answers.map((answer, ind) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        key={"ans" + ind}
                                                                        name={"radio_" + ind}
                                                                        value={answer}
                                                                        control={<Radio
                                                                            checked={formData[index]?.value === answer}/>}
                                                                        label={answer}
                                                                        onChange={(e) => {
                                                                            handleChangeSurveyForm(index, e)
                                                                        }}
                                                                    />
                                                                )
                                                            })}
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (question.type === questionType[2]) {
                                        return (
                                            <div key={"question" + index} className={`form-field my-3 p-3 bg-white rounded`}
                                                 style={{border: errFrom.includes(index) ? "1px solid red" : "1px solid #dadce0"}}>
                                                <div className={`mb-2 d-flex`}>
                                                    <label htmlFor="name">Câu {index + 1}: {question.title}</label>
                                                    {question.is_required &&
                                                        <div className={`text-danger`}>*</div>
                                                    }
                                                </div>
                                                <div className={`mb-2`}>
                                                    <FormControl sx={{m: 1}} component="fieldset" variant="standard">
                                                        <FormGroup>
                                                            {question?.answers &&
                                                                question.answers.map((answer, ind) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={'check' + ind}
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={Boolean(formData[index]?.value?.["checkbox_" + ind])}/>
                                                                            }
                                                                            onChange={(e) => {
                                                                                handleChangeSurveyForm(index, e)
                                                                            }}
                                                                            name={"checkbox_" + ind}
                                                                            label={answer}
                                                                        />
                                                                    )
                                                                })}
                                                        </FormGroup>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <Col sm={12}>
                        <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                            <NavLink className={'btn btn-light me-2'} to={getReturnUrlFromParamString() ?? Routes.Test.path}>
                                Quay lại
                            </NavLink>
                            <Button
                                type="submit"
                                variant="info"
                                onClick={submitSurvey}
                            >Lưu thông tin</Button>
                        </div>
                    </Col>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Tên học viên</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <Form.Control
                                disabled
                                type="text"
                                name="full_name"
                                value={formEdit?.full_name}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Email</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <Form.Control
                                disabled
                                type="text"
                                name="email"
                                value={formEdit?.email}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>SĐT</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <Form.Control
                                disabled
                                type="text"
                                name="phone"
                                value={formEdit?.phone}
                            />
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Sale ghi chú</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <div className="form-outline">
                                <textarea
                                    readOnly
                                    disabled
                                    name="sale_note"
                                    value={formEdit?.sale_note}
                                    className="form-control" rows="4"/>
                            </div>
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>MKT ghi chú</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <div className="form-outline">
                                <textarea
                                    readOnly
                                    disabled
                                    name="sale_note"
                                    value={formEdit?.MKT_note}
                                    className="form-control" rows="4"/>
                            </div>
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Ghi chú</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <textarea
                                name="note"
                                value={formEdit?.note}
                                onChange={(e) => handleChangeEdit({note: e.target.value})}
                                className="form-control" rows="4"/>
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Người chữa test</Form.Label>
                        <Col sm={9} className={`m-auto`}>
                            <Form.Select
                                name="assigned_user_id"
                                value={formEdit?.assigned_user_id}
                                onChange={e => {
                                    handleChangeEdit({assigned_user_id: e.target.value});
                                }}
                            >
                                <option value="" defaultValue>Tất cả người phụ trách</option>
                                {
                                    salesPlusList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.full_name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Tình trạng chấm bài test</Form.Label>
                        <Col sm={9}>
                            {Constants.gradingStatus.map((grading, ind) => {
                                return (
                                    <FormControlLabel
                                        key={"status_mark_test" + ind}
                                        name={"status_mark_test"}
                                        value={ind}
                                        control={<Radio
                                            checked={formEdit?.status_mark_test === ind}/>}
                                        label={grading}
                                        onChange={(e) => {
                                            handleChangeEdit({status_mark_test: Number(e.target.value)})
                                        }}
                                    />
                                )
                            })}
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3}>Tình trạng chữa bài test</Form.Label>
                        <Col sm={9}>
                            {Constants.unpostedStatus.map((unposted, ind) => {
                                return (
                                    <FormControlLabel
                                        key={"status_correct_test" + ind}
                                        name={"status_correct_test"}
                                        value={ind}
                                        control={<Radio
                                            checked={formEdit?.status_correct_test === ind}/>}
                                        label={unposted}
                                        onChange={(e) => {
                                            handleChangeEdit({status_correct_test: Number(e.target.value)})
                                        }}
                                    />
                                )
                            })}
                        </Col>
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <div className='div-btn fixed-bottom' style={{bottom: 75}}>
                        <NavLink className={'btn btn-light me-2'} to={getReturnUrlFromParamString() ?? Routes.Test.path}>
                            Quay lại
                        </NavLink>
                        <Button
                            type="submit"
                            variant="info"
                            onClick={handleSubmit}
                        >Lưu thông tin</Button>
                    </div>
                </Col>
            </TabPanel>
            
        </Box>
    );
}
