import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import axiosCommon from '../../config/axiosCommon';
import {Button, Col, Form, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import {setPageTitle} from "../../common/function";
import DatePicker from "react-datepicker";
import moment from "moment";
import {useSnapshot} from "valtio";
import constants from "../../config/constants";
import {saleRepostIdProxy} from "../../store/proxy/saleRepostId.proxy";

function SaleList() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [data, setData] = useState([]);
    const {setSaleRepostId} = useSnapshot(saleRepostIdProxy)
    const [formSearch, setFormSearch] = useState({
        full_name: '',
        start_date: firstDay,
        end_date: new Date()
    });
    const [loaded, setLoaded] = useState(false);
    const [disableSearch, setDisableSearch] = useState(false);

    const [columns] = useState(
        [
            {
                name: 'STT',
                selector: (row, ind) => ind + 1,
                width: 5,
                sortable: true,
            },
            {
                name: 'Tên Sale',
                selector: row => row.full_name,
                grow: 3,
            },
            {
                name: '',
                width: 50,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top'
                                            overlay={<Tooltip id='tooltip-top'>Chi tiết</Tooltip>}>
                                <Button className={'btn btn-info btn-sm me-1'}
                                        onClick={() => setSaleRepostId(row.id)}>
                                    <i className="fa-solid fa-address-book"></i>
                                </Button>
                            </OverlayTrigger>
                        </Fragment>
                    );
                },
            },
        ]
    );

    const onSubmit = (e) => {
        e.preventDefault();
        searchSale();
    }

    const handleChange = (data) => {
        setFormSearch({
            ...formSearch,
            ...data
        })
    }

    const searchSale = () => {
        setLoaded(false);
        axiosCommon.get(ApiPath.employee, {
            params: {
                ...formSearch,
                start_date: formSearch.start_date ? moment(formSearch.start_date).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
                end_date: formSearch.end_date ? moment(formSearch.end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            }
        })
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoaded(true);
            });
    }

    useEffect(() => {
        if (moment(formSearch.start_date).diff(formSearch.end_date, 'minutes') > 0) {
            setDisableSearch(true)
        } else {
            setDisableSearch(false)
        }
    }, [formSearch])

    useEffect(() => {
        setPageTitle('Báo cáo tổng hợp');
        searchSale();
    }, [])

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };

    return (
        <Fragment>
            <Loading show={!loaded}/>
            {/*<h2>Doanh số cá nhân</h2>*/}
            {/*<hr/>*/}
            <Form onSubmit={onSubmit}>
                <Row className="mb-1 border-b-1">
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Ngày bắt đầu</Form.Label>
                            <Col sm={9} className={`m-auto`}>
                                <DatePicker
                                    name="start_date"
                                    selected={formSearch?.start_date}
                                    onChange={(e) => {
                                        handleChange({start_date: e})
                                    }}
                                    className={`form-control`}
                                    dateFormat={"dd/MM/yyyy"}
                                    showIcon
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Ngày kết thúc</Form.Label>
                            <Col sm={9} className={`m-auto`}>
                                <DatePicker
                                    name="end_date"
                                    selected={formSearch.end_date}
                                    onChange={(e) => {
                                        handleChange({end_date: e})
                                    }}
                                    className={`form-control`}
                                    dateFormat={"dd/MM/yyyy"}
                                    showIcon
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Tên nhân viên</Form.Label>
                            <Col sm={9} className={`m-auto`}>
                                <Form.Control
                                    type="text"
                                    name="full_name"
                                    value={formSearch?.full_name}
                                    onChange={(e) => handleChange({full_name: e.target.value})}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Button
                            disabled={disableSearch}
                            type="submit"
                            className="btn btn-secondary float-end mb-4"
                            style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}
                        >Tìm kiếm</Button>
                    </Col>
                </Row>
            </Form>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
                title="Danh sách mục tiêu"
                paginationRowsPerPageOptions={constants.arrPerPage}
                noDataComponent={<div className="p-4">Không tìm thấy Goal nào.</div>}
            />
        </Fragment>
    );
}

export default React.memo(SaleList)
