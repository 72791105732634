import {proxy} from "valtio/vanilla";

const saleRepostIdProxy = proxy({

    saleRepostId: null,

    setSaleRepostId: (data) => {
        saleRepostIdProxy.saleRepostId = data
    },

})

export {
    saleRepostIdProxy
}
