import {proxy} from "valtio/vanilla";
import {cloneDeep} from "lodash";

const timeTableProxy = proxy({
    subskills: {},
    lessons: {},
    linkAudios: {},
    linkLessons: {},
    dataTimeTable: [],

    studyDefault: {
        week: 4,
        day: 4,
        unit: 1,
        unitTotal: 16,
        details: [],
        results: [4,4,4,4]
    },

    skillDefault: ['Listening', 'Reading', 'Speaking', 'Writing'],

    maxDayTimetable: [4],

    checkCom: [],

    setDataTimeTable: (data) => {
        timeTableProxy.dataTimeTable = [...data]
    },

    setCheckCom: (data) => {
        timeTableProxy.checkCom = [...data]
    },

    setStudyDefault: (data) => {
        timeTableProxy.studyDefault = {...data}
    },

    setSkillDefault: (data) => {
        timeTableProxy.skillDefault = [...data]
    },

    setMaxDayTimetable: (data) => {
        timeTableProxy.maxDayTimetable = [...data]
    },
    setLessons: (data) => {
        timeTableProxy.lessons = cloneDeep(data)
    },
    setSubskills: (data) => {
        timeTableProxy.subskills = cloneDeep(data)
    },
    setLinkAudios: (data) => {
        timeTableProxy.linkAudios = cloneDeep(data)
    },
    setLinkLessons: (data) => {
        timeTableProxy.linkLessons = cloneDeep(data)
    }
})

export {
    timeTableProxy
}
