import {proxy} from "valtio/vanilla";

const toastProxy = proxy({

    contentToast: '',

    setContentToast: (data) => {
        toastProxy.contentToast = data
    },

})

export {
    toastProxy
}
