import React, {Fragment, useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {ApiPath} from "../../apiPath";
import {NavLink} from "react-router-dom";
import {Routes} from "../../routes";
import Breadcrumbs from "../../components/main/Breadcrumbs";
import axiosCommon from '../../config/axiosCommon';
import {Button, Col, Form, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import DeleteSubskillModal from "../../components/subskill/DeleteSubskillModal";
import AddSubskillModal from "../../components/subskill/AddSubskillModal";
import EditSubskillModal from "../../components/subskill/EditSubskillModal";
import {setPageTitle, changeQueryString, addReturnUrlToParamString, getSearchParams} from "../../common/function";
import {userProxy} from "../../store/proxy/user.proxy";
import {useSnapshot} from "valtio";
import constants from "../../config/constants";

export default () => {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [inputs, setInputs] = useState({
        subskill_name: '',
        skill_type_id: '',
    });
    const {currentUser} = useSnapshot(userProxy)
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [currentSubskill, setCurrentSubskill] = useState({});

    const [columns] = useState(
        [
            {
                name: 'ID',
                selector: row => row.id,
                width: 5,
                sortable: true,
            },
            {
                name: 'Tên Subskill',
                selector: row => row.name,
                sortable: true,
                grow: 3,
            },
            {
                name: 'Tên kỹ năng',
                selector: row => row.skill_type,
                sortable: true,
                width: 50
            },
            {
                name: 'Ngày tạo',
                selector: row => row.created_at,
                width: 50,
                sortable: true,
                center: true,
            },
            {
                name: '',
                width: 50,
                cell: (row) => {
                    return (
                        <Fragment>
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Chỉnh sửa Subskill</Tooltip>}>
                                <Button className={'btn btn-info btn-sm me-1'} onClick={() => processDisplayEditPopup(row)}>
                                    <i className="fa-regular fa-pen-to-square"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Hiển thị danh sách bài học</Tooltip>}>
                                <NavLink className={'btn btn-success btn-sm me-1'} to={`/lessons/${row.id}`}>
                                    <i className="fa-solid fa-book"></i>
                                </NavLink>
                            </OverlayTrigger>
                            {currentUser?.role_id === constants.roles.ADMIN.id &&
                                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Xóa Subskill</Tooltip>}>
                                    <Button className={'btn btn-danger btn-sm me-1'} onClick={() => confirmDeleteSubskill(row)}>
                                        <i className="fa-regular fa-trash-can"></i>
                                    </Button>
                                </OverlayTrigger>
                            }
                        </Fragment>
                    );
                },
            },
        ]
    );
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const onSubmit = (e) => {
        e.preventDefault();
        searchSubskills();
    }

    const searchSubskills = (searchs = null) => {
        setLoaded(false);
		let searchInfo = {...inputs}
		if (searchs) {
			searchInfo = {...searchs}
		} else {
			changeQueryString(searchInfo)
		}
        axiosCommon.get(ApiPath.subskills, { params: searchInfo })
            .then((res) => {
                setData(res.data)
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }

    const confirmDeleteSubskill = (subskill) => {
        setCurrentSubskill(subskill)
        setShowDeletePopup(true)
    }

    const processDisplayEditPopup = (subskill) => {
        setCurrentSubskill(subskill)
        setShowEditPopup(true)
    }

    const deleteSubskill = () => {
        setLoaded(false);
        axiosCommon.delete(ApiPath.subskills + `/${currentSubskill.id}`)
            .then((res) => {
                searchSubskills();
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }

    const addSubskill = (subskill) => {
        setLoaded(false);
        axiosCommon.post(ApiPath.subskills, subskill)
            .then((res) => {
                searchSubskills();
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }
   
    const editSubskill = (subskill) => {
        setLoaded(false);
        axiosCommon.put(ApiPath.subskills, subskill)
            .then((res) => {
                searchSubskills();
            })
			.finally(()=> {
			   setLoaded(true);
			});
    }

    useEffect(() => {
        setPageTitle('Quản lý Subskill');
		const searchParams = getSearchParams()
		setInputs({ ...inputs, ...searchParams})
        searchSubskills(searchParams);
    },[])
    
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };
    
    return (
        <Fragment>
            <Loading show={!loaded}/>
            <Breadcrumbs firstLocation={Routes.Subskills}/>
            <Row className="mb-5">
                <Col sm={12}>
                    <Button className="btn btn-tertiary float-end" style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}} onClick={() => setShowAddPopup(true)}>
                        <i className="fa-solid fa-plus"></i>
                        Thêm mới Subskill
                    </Button>
                </Col>
            </Row>
            <Form onSubmit={onSubmit}>
                <Row className="mb-1">
                    <Col sm={5}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Tên Subskill</Form.Label>
                            <Col sm={9}>
                                <Form.Control type="text" name="subskill_name" value={inputs.subskill_name} onChange={handleChange} placeholder="Tên subskill" />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={5}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={3}>Kỹ năng</Form.Label>
                            <Col sm={9}>
                                <Form.Select name="skill_type_id" value={inputs.skill_type_id} onChange={handleChange}>
                                    <option value="" defaultValue>Tất cả các kỹ năng</option>
                                    <option value="1">Listening</option>
                                    <option value="2">Reading</option>
                                    <option value="3">Speaking</option>
                                    <option value="4">Writing</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={2}>
                        <Button type="submit" className="btn btn-secondary float-end" style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}>Tìm kiếm</Button>
                    </Col>
                </Row>
            </Form>
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
                title="Danh sách Subskill"
                paginationRowsPerPageOptions={constants.arrPerPage}
                noDataComponent={<div className="p-4">Không tìm thấy Subskill nào.</div>}
            />
            <DeleteSubskillModal show={showDeletePopup} setShowModal={setShowDeletePopup} 
                actionOk={deleteSubskill}  actionCancel={() => setCurrentSubskill({})}/>
            { showAddPopup && <AddSubskillModal show={showAddPopup} setShowModal={setShowAddPopup} actionOk={addSubskill}/> }
            { showEditPopup && <EditSubskillModal show={showEditPopup} setShowModal={setShowEditPopup} actionOk={editSubskill} subskill={currentSubskill}/> }
        </Fragment>
    );
}
