export const Routes = {
    // pages
    Dashboard: {path: "/", name: "Trang chính"},
    ForgotPasswordSendMail: {path: "/forgot-password/send-mail", name: "Mail lấy lại mật khẩu"},
    ForgotPasswordCheckCode: {path: "/forgot-password/check-code", name: "Nhập mã OTP"},
    ForgotPasswordChangePass: {path: "/forgot-password/reset-password", name: "Đổi mật khẩu"},
    StudentInfos: {path: "/student-infos", name: "Thông tin học viên"},
    SaleHours: {path: "/list-sale-hours", name: "Danh sách giờ gọi của sale"},
    Notification: {path: "/list-notification", name: "Danh sách thông báo"},
    StudentInfoDetail: {path: "/student-infos/:id", name: "Chi tiết thông tin học viên"},
    CreateStudentInfo: {path: "/student-infos/create", name: "Tạo mới thông tin học viên"},
    Students: {path: "/students", name: "Thiết lập thời gian biểu"},
    StudentDetail: {path: "/students/:id", name: "Chi tiết thời gian biểu"},
    CopyStudent: {path: "/students/copy", name: "Copy thời gian biểu"},
    CreateStudent: {path: "/students/create", name: "Tạo mới thời gian biểu"},
    Subskills: {path: "/subskills", name: "Quản lý tài liệu"},
    Lessons: {path: "/lessons/:id", name: "Quản lý bài học"},
    Question: {path: "/question", name: "Quản lý câu hỏi"},
    Test: {path: "/test", name: "Chấm và chữa bài kiểm tra"},
    TestDetail: {path: "/test/:id", name: "Chi tiết chấm và chữa bài kiểm tra"},
    SynthesisReport: {path: "/synthesis-report", name: "Báo cáo tổng hợp"},
    GoalSetting: {path: "/goal-setting", name: "Thiết lập mục tiêu"},
    Timetable: {path: "/timetable"},
    Survey: {path: "/survey"},
    Staffs: {path: "/staffs", name: "Quản lý nhân viên"},
    CreateStaff: {path: "/staffs/create", name: "Thêm nhân viên mới"},
    EditStaff: {path: "/staffs/:id/edit", name: "Chỉnh sửa nhân viên"},
    LearningPaths: {path: "/learning-paths", name: "Danh sách làm lộ trình"},
    EditLearningPath: {path: "/learning-paths/:id/edit", name: "Chỉnh sửa lộ trình"},
    Login: {path: "/login"},
    Logout: {path: "/logout"},
    NotFound: {path: "/page-not-found"},
    Courses: {path: "/courses", name: "Khóa học của tôi"},
    CourseDetails: {path: "/course-details/:id", name: "Chi tiết khóa học"},
};
