import React, {useEffect, useState} from 'react';
import Breadcrumbs from "../../components/main/Breadcrumbs";
import {Routes} from "../../routes";
import PaginationCustom from "../../components/pagination/PaginationCustom";
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import DatePicker from "react-datepicker";
import constants from "../../config/constants";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import moment from "moment";
import Loading from "../../components/main/Loading";
import {
    addReturnUrlToParamString,
    changeQueryString,
    formatDate,
    formatTime,
    getSearchParams,
    getTitleFromId,
    getValueFromId, setPageTitle,
    showToastSuccess
} from "../../common/function";
import {cloneDeep, isEmpty} from "lodash";
import {makeStyles} from "tss-react/mui";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import EditAssignedUserModalHours from "../../components/Custom/EditAssignedUserModalHours";

const useStyles = makeStyles()(() => ({
    text100: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        width: 100
    },
    text200: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        width: 200
    }
}));
const thTable = ['', 'Giờ', 'Tên', 'Email', 'SĐT', 'MKT ghi chú', 'Đã nhắc test', 'Trạng thái', 'Ghi chú cuộc gọi', 'Nguyên nhân từ chối', 'Người phụ trách', '']
export default function SaleHours() {
    const location = useLocation()
    const navigate = useNavigate()
    const {classes} = useStyles();
    const [page, setPage] = useState(1)
    const [rowSpan, setRowSpan] = useState({})

    const [loading, setIsLoading] = useState(false);
    const [data, setData] = useState({data: {}});
    const [sales, setSales] = useState([]);
    const [showAssignedModal, setShowAssignedModal] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const targetTimeMorning = moment('12:00:00', 'HH:mm:ss')
    const targetTimeAfter = moment('18:00:00', 'HH:mm:ss')
    const [search, setSearch] = useState({
        full_name: '',
        phone: '',
        email: '',
        date: '',
        status: '',
        status_reject: '',
        assigned_user: '',
    })

    const fetchData = async (newPage, searchNew = search) => {
        setIsLoading(true)
        if (newPage) {
            await setPage(newPage)
        }
        let params = {
            ...searchNew,
            number_page: newPage || page,
            date: formatDate(searchNew.date, '', 'YYYY-MM-DD'),
        }
        changeQueryString(params)
        axiosCommon.get(ApiPath.timeCall, {params: params})
            .then((res) => {
                const obj = (res.data) || {}
                Object.values(obj.data).forEach(arr => {
                    arr.sort((a, b) => new Date(`1970/01/01 ${a.call_hour}`) - new Date(`1970/01/01 ${b.call_hour}`));
                });

                setData(obj)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const setRowSwitch = (rowSwitch, index) => {
        axiosCommon.post(ApiPath.studentUpdateData, {
            student_info_id: rowSwitch.id,
            is_test_reminded: !rowSwitch.is_test_reminded,
        }).then((res) => {
            showToastSuccess('Chuyển đổi trạng thái Đã nhắc test thành công.')
            let newData = cloneDeep(data)
            newData.data[rowSwitch.call_day][index].is_test_reminded = !rowSwitch.is_test_reminded
            setData({...newData})
        })
    }

    const updateAssignedUser = (assignedUserId) => {
        setIsLoading(true)
        axiosCommon.post(ApiPath.studentUpdateData, {
            student_info_id: currentRow?.data?.id,
            assigned_user: assignedUserId,
        }).then((res) => {
            showToastSuccess('Cập nhật người phụ trách thành công.')
            let newData = cloneDeep(data)

            newData.data[currentRow?.data?.call_day][currentRow?.index].assigned_user = assignedUserId
            setData({...newData})
        })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        setPageTitle('Danh sách giờ gọi');
        axiosCommon.post(ApiPath.commonData)
            .then((res) => {
                setSales(res.data.sales)
            })
    }, [])

    useEffect(() => {
        let newRow = {}
        Object.keys(data.data).map((key) => {
            let s = 0, c = 0, t = 0
            data.data[key].map((value, index) => {
                let targetTime = moment(value.call_hour, 'HH:mm:ss')
                if (targetTime.isBefore(targetTimeMorning)) return s++
                if ((targetTime.isAfter(targetTimeMorning) || targetTime.isSame(targetTimeMorning)) && targetTime.isBefore(targetTimeAfter)) return c++
                if ((targetTime.isAfter(targetTimeAfter) || targetTime.isSame(targetTimeAfter))) return t++
            })
            newRow = {
                ...newRow,
                [key]: {s: s, c: c, t: t}
            }
        })
        setRowSpan(newRow)
    }, [data])

    useEffect(() => {
        const searchParams = getSearchParams()
        setSearch({...search, ...searchParams, date: searchParams?.date ? new Date(searchParams.date) : ''})
        fetchData('', {...search, ...searchParams, date: searchParams?.date ? new Date(searchParams.date) : ''})
        navigate(location.pathname + location.search, {replace: true})
    }, [page])

    return (
        <>
            <Loading show={loading}/>
            <div className="row m-3">
                <div className="col position-relative">
                    <Breadcrumbs firstLocation={Routes.SaleHours}/>
                </div>
                <div>
                    <div className="row mb-2">
                        <div className="col">
                            <Form.Label>Tên học viên</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Tên học viên"
                                value={search.full_name}
                                onChange={e => setSearch({...search, full_name: e.target.value})}
                            />
                        </div>
                        <div className="col">
                            <Form.Label>Số điện thoại</Form.Label>
                            <Form.Control
                                placeholder="Số điện thoại"
                                value={search.phone}
                                onChange={e => setSearch({...search, phone: e.target.value})}
                            />
                        </div>
                        <div className="col">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Email"
                                value={search.email}
                                onChange={e => setSearch({...search, email: e.target.value})}
                            />
                        </div>

                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            <Form.Label>Ngày gọi</Form.Label>
                            <DatePicker
                                className="form-control" dateFormat="dd/MM/yyyy" showIcon
                                placeholderText="Ngày gọi"
                                selected={search.date}
                                onChange={value => {
                                    setSearch({...search, date: value})
                                }}/>
                        </div>
                        <div className="col">
                            <Form.Label>Trạng thái</Form.Label>
                            <Form.Select name="status" value={search.status}
                                         onChange={e => setSearch({...search, status: e.target.value})}>
                                <option value="" defaultValue>Tất cả trạng thái</option>
                                {
                                    constants.statuses.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.title}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </div>

                        <div className="col">
                            <Form.Label>Người phụ trách</Form.Label>
                            <Form.Select
                                type="text"
                                placeholder="Người phụ trách"
                                value={search.assigned_user}
                                onChange={e => setSearch({...search, assigned_user: e.target.value})}
                            >
                                <option value="">Tất cả</option>
                                {sales.map(sale => <option value={sale.id} key={sale.id}>{sale.full_name}</option>)}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        { constants.arr_reject_status_ids.includes(parseInt(search.status)) ?
                            <div className="col">
                                <Form.Label>Nguyên nhân từ chối</Form.Label>
                                <Form.Select name="status_reject" value={search.status_reject}
                                             onChange={e => setSearch({...search, status_reject: e.target.value})}>
                                    <option value="" defaultValue>Tất cả nguyên nhân</option>
                                    {
                                        constants.status_rejects.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.title}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                            : <div className="col"></div>
                        }
                        <div className="col"></div>
                        <div className="col d-flex flex-column justify-content-end">
                            <Button
                                style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}
                                variant="secondary"
                                onClick={() => {
                                    fetchData(1)
                                }}
                            >Tìm kiếm</Button>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col d-flex flex-column justify-content-end">
                            <div className={`float-end`}>
                                <PaginationCustom
                                    page={page}
                                    setPage={setPage}
                                    lastPage={data?.last_page || 1}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'overflow-auto'}>
                    {!isEmpty(data?.data) ? Object.keys(data.data).map((key) => {
                        return <div key={'table_sale_hours_tr_' + key}>
                            <table className="table table-hover bg-white w-100">
                                <thead>
                                <tr>
                                    <th colSpan={13}>
                                        <div className={`text-center bold`} style={{
                                            background: '#fcee38',
                                            fontSize: 16
                                        }}>{moment(key).format('DD/MM/YYYY')}</div>
                                    </th>
                                </tr>
                                <tr>
                                    {thTable.map((value, index) => {
                                        return <th key={'table_sale_hours_th_' + index} style={{textAlign: 'left'}}
                                                   scope="col">{value}</th>
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {data.data[key].map((value, index) => {
                                    return <tr key={'table_sale_hours_tr_' + index}>
                                        {index === 0 && rowSpan[key]?.s !== 0 &&
                                            <td className={`text-center align-middle border-secondary border-1`}
                                                style={{background: '#fce5cd'}}
                                                rowSpan={rowSpan[key]?.s || 0}>Sáng</td>}
                                        {index === rowSpan[key]?.s && rowSpan[key]?.c !== 0 &&
                                            <td className={`text-center align-middle border-secondary border-1`}
                                                style={{background: '#fce5cd'}}
                                                rowSpan={rowSpan[key]?.c || 0}>Chiều</td>}
                                        {index === (rowSpan[key]?.s + rowSpan[key]?.c) && rowSpan[key]?.t !== 0 &&
                                            <td className={`text-center align-middle`} style={{background: '#fce5cd'}}
                                                rowSpan={rowSpan[key]?.t || 0}>Tối</td>}
                                        {/*<td className={`text-center`}>{index + 1}</td>*/}
                                        <td className={`text-center`}>{formatTime(value.call_hour)}</td>
                                        <td>
                                            <div className={`${classes.text100}`}>
                                                {value.full_name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${classes.text100}`}>
                                                {value.email}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${classes.text100}`}>
                                                {value.phone}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${classes.text200}`}>
                                                {value.MKT_note}
                                            </div>
                                        </td>
                                        <td>
                                            <Form.Check
                                                type="switch"
                                                name="is_test_reminded"
                                                defaultChecked={false}
                                                checked={value.is_test_reminded}
                                                onChange={() => setRowSwitch(value, index)}
                                            />
                                        </td>
                                        <td>
                                            <div className={`${classes.text100}`}>
                                                {value.status ? getValueFromId(value.status, constants.statuses, '-', 'title') : '-'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${classes.text200}`}>
                                                {value.sale_note || '-'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${classes.text100}`}>
                                                {value.status_reject ? getTitleFromId(value.status_reject, constants.status_rejects) : '-'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={`${classes.text100}`}>
                                                {value.assigned_user ? getValueFromId(value.assigned_user, sales, '-', 'full_name') : '-'}
                                            </div>
                                        </td>
                                        <td className={`text-center`}>
                                            <OverlayTrigger placement='left'
                                                            overlay={<Tooltip>Chỉnh sửa học viên</Tooltip>}>
                                                <NavLink className={'btn btn-info btn-sm me-1'}
                                                         to={`/student-infos/${value.id}?` + addReturnUrlToParamString()}>
                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                </NavLink>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement='left'
                                                            overlay={<Tooltip>Chỉnh sửa người phụ trách</Tooltip>}>
                                                <Button className={'btn btn-secondary btn-sm me-1'} onClick={() => {
                                                    setCurrentRow({data: value, index: index});
                                                    setShowAssignedModal(true);
                                                }}>
                                                    <i className="fa-solid fa-user text-white"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    }) : <div className={`w-100 text-center bg-white`}>Không có dữ liệu</div>}
                </div>
                {showAssignedModal &&
                    <EditAssignedUserModalHours
                        show={showAssignedModal}
                        setShowModal={setShowAssignedModal}
                        currentRow={currentRow}
                        actionOk={updateAssignedUser}
                        actionCancel={() => setCurrentRow(null)}
                    />
                }
            </div>
        </>
    );
};

