import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'scss/common.scss'

import {Route, Routes as RouteList} from 'react-router-dom';
import React from "react";
import Main from "./views/Main";
import Dashboard from "./views/home/Dashboard";

import SubskillList from "./views/subskills/SubskillList";

import LessonList from "./views/lesson/LessonList";

import StudentInfoList from "./views/student-infos/StudentInfoList";
import CreateStudentInfo from "./views/student-infos/CreateStudentInfo";
import StudentInfoDetail from "./views/student-infos/StudentInfoDetail";

import StudentList from "./views/students/StudentList";
import StudentDetail from "./views/students/StudentDetail";
import CreateStudent from "./views/students/CreateStudent";
import CopyStudent from "./views/students/CopyStudent";

import ListStaffs from "./views/staffs/ListStaffs";
import CreateStaff from "views/staffs/CreateStaff"
import EditStaff from "views/staffs/EditStaff"

import ListLearningPath from "views/learningPath/ListLearningPath";
import EditLearningPath from "views/learningPath/EditLearningPath";

import Login from "./views/users/Login";
import Logout from "./views/users/Logout";
import ToastsCustom from "./components/Custom/ToastsCustom";
import NotFound from "./views/error/NotFound";
import ServerError from "./views/error/ServerError";
import Question from "./views/question";
import Test from "./views/test";
import TestDetail from "./views/test/detail";
import {Routes} from "./routes";
import SynthesisReport from "./views/synthesisReport";
import "react-datepicker/dist/react-datepicker.css";
import ToastsNotificationCustom from "./components/Custom/ToastsNotificationCustom";
import SalesReportModal from "./components/Custom/SalesReportModal";
import Forbidden from "./views/error/Forbidden";
import SaleHours from "./views/list-sale-hours";
import NotificationListAll from "./views/notification";
import CourseDetails from "./views/courseDetails";
import ForgotPassword from "./views/users/ForgotPassword";
import OTPInput from "./views/users/OTP";
import NewPassword from "./views/users/NewPassword";

function App() {

    return (
        <>
            <RouteList>
                <Route path="/" element={<Main/>}>
                    <Route path={Routes.Dashboard.path} element={<Dashboard/>}/>
                    <Route path={Routes.CreateStudentInfo.path} element={<CreateStudentInfo/>}/>
                    <Route path={Routes.StudentInfoDetail.path} element={<StudentInfoDetail/>}/>
                    <Route path={Routes.StudentInfos.path} element={<StudentInfoList/>}/>
                    <Route path={Routes.SaleHours.path} element={<SaleHours/>}/>
                    <Route path={Routes.CreateStudent.path} element={<CreateStudent/>}/>
                    <Route path={Routes.CopyStudent.path + "/:id"} element={<CopyStudent/>}/>
                    <Route path={Routes.Students.path} element={<StudentList/>}/>
                    <Route path={Routes.Students.path + "/:id"} element={<StudentDetail/>}/>
                    <Route path={Routes.Subskills.path} element={<SubskillList/>}/>
                    <Route path={Routes.Lessons.path} element={<LessonList/>}/>
                    <Route path={Routes.Notification.path} element={<NotificationListAll/>}/>
                    <Route path={Routes.Question.path} element={<Question/>}/>
                    <Route path={Routes.Test.path} element={<Test/>}/>
                    <Route path={Routes.TestDetail.path} element={<TestDetail/>}/>
                    <Route path={Routes.SynthesisReport.path} element={<SynthesisReport/>}/>
                    {/*<Route path={Routes.GoalSetting.path} element={<GoalSetting/>}/>*/}
                    <Route path={Routes.Staffs.path} element={<ListStaffs/>}/>
                    <Route path={Routes.CreateStaff.path} element={<CreateStaff/>}/>
                    <Route path={Routes.EditStaff.path} element={<EditStaff/>}/>
                    <Route path={Routes.LearningPaths.path} element={<ListLearningPath/>}/>
                    <Route path={Routes.EditLearningPath.path} element={<EditLearningPath/>}/>
                    <Route path={Routes.Courses.path} element={<Dashboard/>}/>
                    <Route path={Routes.CourseDetails.path} element={<CourseDetails/>}/>
                </Route>
                <Route path={Routes.Login.path} element={<Login/>}/>
                <Route path={Routes.Logout.path} element={<Logout/>}/>
                <Route path={Routes.ForgotPasswordSendMail.path} element={<ForgotPassword/>}/>
                <Route path={Routes.ForgotPasswordCheckCode.path} element={<OTPInput/>}/>
                <Route path={Routes.ForgotPasswordChangePass.path} element={<NewPassword/>}/>
                <Route path="/page-error" element={<ServerError/>}/>
                <Route path="*" element={<NotFound/>}/>
                <Route path="/forbidden" element={<Forbidden/>}/>
            </RouteList>
            <ToastsCustom/>
            <ToastsNotificationCustom/>
            <SalesReportModal/>
            <ToastContainer/>
        </>
    );
}

export default App;
