import React, { useEffect, useState} from "react";
import { Form, Modal, Button, InputGroup, Row, Col, Container ,Table, Alert } from 'react-bootstrap';
import {cloneDeep} from "lodash";

function DetailWeekUnitModal(props) {
    const {
        showModal,
        setShowModal,
		actionOk,
        actionCancel = '',
        studyDetails = '',
    } = props
    
    const [showAlert, setShowAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [inputs, setInputs] = useState({week: '', day: '', details: [], results: []});

    const deleteRow = (index) => {
        let _detail = cloneDeep(inputs.details)
        _detail.splice(index, 1);
        setInputs({...inputs, details: _detail})
        setInputs(values => ({...values, details: _detail}))
    }

    const addRow = () => {
        let _detail = [...inputs.details, {start: '', end: '', unit: ''}]
        setInputs({...inputs, details: _detail})
    }

    const handleChangeDetail = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        let _detail = cloneDeep(inputs.details)
        _detail[index][name] = value
        setInputs({...inputs, details: _detail})
    }

    const handleChangeInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const numberCheck = (input, name, minValue = 1, isRequired = true) => {
        if(isRequired && !input) {
            setErrorText(`${name} không được để trống`);
            return false;
        } else if (input && Number(input) < minValue) {
            setErrorText(`${name} không được nhỏ hơn ${minValue}.`);
            return false;
        }
        return true;
    }

    const getGridData = () => {
        let weeks = []
        let isSetWeeks = {}
        
        for (let i = 1; i <= Number(inputs.week); i++) {
            weeks.push(Number(inputs.day))
            isSetWeeks[i] = false
        }
        for(var index = 0; index < inputs.details.length; index++) {
            const item = inputs.details[index];
            // Empty row
            if(!`${item.start}${item.end}${item.unit}`) {
                continue;
            }
            if(!numberCheck(item.start, `Dòng ${index + 1}: Tuần bắt đầu`) || 
                    !numberCheck(item.end, `Dòng ${index + 1}: Tuần kết thúc`, 1, false) ||
                    !numberCheck(item.unit, `Dòng ${index + 1}: Số ngày học`)) {
                setShowAlert(true)
                return;
            }
            
            let start = Number(item.start)
            let end = !item.end ? start : Number(item.end)
            if(end < start) {
                setErrorText(`Dòng ${index + 1}: Tuần học kết thúc không được trước tuần bắt đầu.`);
                return false;
            }
            for (let i = start; i <= end; i++) {
                if (isSetWeeks[i]) {
                    setErrorText(`Dòng ${index + 1}: Tuần học thứ ${i} đã có dữ liệu. Vui lòng kiểm tra lại.`);
                    return false;
                } else if (i > inputs.week) {
                    continue
                }
                isSetWeeks[i] = true
                weeks[i-1] = Number(item.unit)
            }
        }

        return weeks
    }

    const handleClose = () => {
		if (actionCancel) {
			actionCancel();
        }
		setShowModal(false);
	};

    const handleOk = () => {
        setShowAlert(false)
        if(!numberCheck(inputs.week, 'Số tuần học') || !numberCheck(inputs.day, 'Số ngày học trong tuần')) {
            setShowAlert(true)
            return;
        }
        
        let results = getGridData()
        if(!results) {
            setShowAlert(true)
            return false
        }
        
        actionOk({...inputs, unitTotal: results.reduce((a, b) => a + b, 0), results: results})
        handleClose()
    };
    
    useEffect(() => {
        if (showModal) {
            setInputs(studyDetails);
        }
    },[showModal])
    
    return (
        <>
            <Modal size="lg" as={Form} show={showModal} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Thiết lập số ngày học theo tuần</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Alert
                        variant="danger"
                        show={showAlert}
                        onClose={() => setShowAlert(false)}>
                        <div className="d-flex justify-content-between">
                        <div>{errorText}</div>
                        <Button variant="close" size="xs" onClick={() => setShowAlert(false)} />
                        </div>
                    </Alert>
                    <Row className="mb-1">
                        <Col sm={2}/>
                        <Col sm={10}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}>Số tuần học<span className='ps-1 text-danger'>*</span></Form.Label>
                                <Col sm={8}>
                                    <Form.Control className='text-right' type="number" value={inputs.week} min="1" onChange={handleChangeInput} name="week" />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={2}/>
                        <Col sm={10}>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={4}>Số ngày học/tuần<span className='ps-1 text-danger'>*</span></Form.Label>
                                <Col sm={8}>
                                    <Form.Control className='text-right' type="number" value={inputs.day} onChange={handleChangeInput} name="day" />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <h5>Bảng thiết lập số ngày học theo tuần</h5>                            
                    <Table bordered hover as={Row}>
                        <thead>
                            <tr>
                                <th className='th-detail'></th>
                                <th className='th-detail'>Tuần bắt đầu</th>
                                <th className='th-detail'>Tuần kết thúc<br/><span style={{'textTransform': 'lowercase'}}>(Bỏ trống: set cho 1 tuần)</span></th>
                                <th className='th-detail'>Số ngày học</th>
                            </tr>
                        </thead>
                        <tbody>
                            { inputs.details.map((week, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="text-center">
                                            <i className="fa-regular fa-trash-can fa-xl" role="button" onClick={() => deleteRow(index)}></i>
                                        </td>
                                        <td>
                                            <input
                                                className='td-week-detail'
                                                name='start'
                                                type='number'
                                                value={week.start}
                                                onChange={(e) => handleChangeDetail(e, index)}
                                            /><span className='ps-1 text-danger'>*</span>
                                        </td>
                                        <td>
                                            <input
                                                className='td-week-detail'
                                                name='end'
                                                type='number'
                                                value={week.end}
                                                onChange={(e) => handleChangeDetail(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className='td-week-detail'
                                                name='unit'
                                                type='number'
                                                value={week.unit}
                                                onChange={(e) => handleChangeDetail(e, index)}
                                            /><span className='ps-1 text-danger'>*</span>
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                            <tr onClick={addRow}>
                                <td>
                                    <i className="fa-solid fa-circle-plus fa-xl" role="button"></i>
                                </td>
                                <td colSpan='3' style={{cursor: 'pointer', fontSize: 13, marginLeft: 3}}/>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="info" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DetailWeekUnitModal;