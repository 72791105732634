import "@fortawesome/fontawesome-free/css/all.min.css";
import {Grid} from "@mui/material";
import SkillTable from "./SkillTable";
import {useSnapshot} from "valtio";
import React, {useEffect, useState} from "react";
import {cloneDeep} from "lodash";
import "../../scss/timetable.scss";
import {timeTableProxy} from "../../store/proxy/timeTable.proxy";
import axiosCommon from "../../config/axiosCommon";
import {ApiPath} from "../../apiPath";
import Loading from "../../components/main/Loading";

function TimetableCourse() {
    const {
        dataTimeTable,
        setMaxDayTimetable,
        setLessons,
        setSubskills,
        setLinkAudios,
        setLinkLessons, checkCom, setCheckCom
    } = useSnapshot(timeTableProxy)
    const [isLoading, setIsLoading] = useState(false);
    const [dataAllSubSkill, setDataAllSubSkill] = useState([])

    const formatCom = (arr, obj) => {
        let arrObj = Object.values(obj || {});
        let currentIndex = 0
        let comFm = []
        for (const value of arr) {
            let dd = []
            for (let i = currentIndex; i < currentIndex + value; i++) {
                const key = arrObj[i]
                if (key !== undefined && key !== "") {
                    dd.push(!key)
                }
            }
            currentIndex += value;
            comFm.push(dd.length === 0 || !dd.includes(false))
        }
        return comFm
    }

    useEffect(() => {
        let _dataTimeTable = cloneDeep(dataTimeTable)
        if (_dataTimeTable.length > 0) {
            let maxDayTimetable = []
            let listCheckCom = []
            _dataTimeTable.map((value, index) => {
                let unitAr = []
                value?.detail.map((val, indW) => {
                    let max = 0
                    if (max < val.day) {
                        max = Number(val.day)
                    }
                    maxDayTimetable[val.week - 1] = max
                    unitAr.push(...val?.unit)
                })
                let isCom = formatCom(unitAr, value?.isComplete)
                listCheckCom.push(isCom)
            })
            let check = []
            for (let i = 0; i < listCheckCom[0].length; i++) {
                let checkChill = 0
                for (let j = 0; j < listCheckCom.length; j++) {
                    if (!listCheckCom[j][i]) checkChill++
                }
                check.push(checkChill !== 0)
            }
            setCheckCom(check);
            setMaxDayTimetable([...maxDayTimetable])
        }
    }, [dataTimeTable])

    useEffect(() => {
        setIsLoading(true)
        axiosCommon.get('/api/timetable')
            .then(
                response => {
                    setLessons(response.data.lessons)
                    setSubskills(response.data.subskills)
                    setLinkAudios(response.data.link_audios)
                    setLinkLessons(response.data.link_lessons)
                },
            )
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    //get data for unit modal
    useEffect(() => {
        axiosCommon.get(ApiPath.subSkillGroup)
            .then(response => {
                setDataAllSubSkill(response.data)
            })
    }, [])

    return (
        <Grid className={`mt-4`}>
            <Loading show={isLoading}/>
            <h5>Lộ trình học</h5>
            <hr/>
            <div className="w-full mt-2">
                <div className="d-flex w-full"
                     style={{
                         background: 'white',
                         border: '1px solid #eaedf2',
                         width: 'fit-content',
                         position: 'sticky',
                         top: 0,
                         zIndex: 10
                     }}>
                    {
                        dataTimeTable.map((data, index) => {
                            return (
                                <div key={index + 'time_table'}>
                                    <SkillTable
                                        skills={data}
                                        indexSkill={index}
                                        dataAllSubSkill={dataAllSubSkill}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Grid>
    );
}

export default TimetableCourse;
