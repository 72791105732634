import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faKey, faSignOutAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import {Container, Dropdown, Image, Nav, Navbar} from 'react-bootstrap';
import manager from '../../assets/img/manager.png';
import {useSnapshot} from "valtio";
import {saleRepostIdProxy} from "../../store/proxy/saleRepostId.proxy";
import constants from "config/constants";
import {iAm} from "common/function";

import axiosCommon from "config/axiosCommon";
import {ApiPath} from "apiPath";

import NotificationsList from "../Custom/NotificationsList";
import SaleInfoModal from "components/Custom/SaleInfoModal";
import ChangePasswordModal from "components/Custom/ChangePasswordModal";

export default () => {
    const {setSaleRepostId} = useSnapshot(saleRepostIdProxy)
    const [notifications, setNotifications] = useState([])
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

    const [me, setMe] = useState({})
    const fetchMe = async () => {
        const res = await axiosCommon.post(ApiPath.me).catch(() => null)
        if(res) {
            setMe(res.data)
        }
    }
    
    const fetchNotifications = async () => {
        const userId = me?.id
        if (userId) {
            const res = await axiosCommon.get(ApiPath.notifications + '/' + userId)
            setNotifications(res.data)
        }
    }
    useEffect(() => {
        fetchNotifications()
        fetchMe()
    }, [])

    return (
        <>
            <Navbar
                variant="dark" expanded className={`ps-0 pe-2 pb-0`}
            >
                <Container fluid className="px-0">
                    <div className="d-flex flex-row-reverse w-100">
                        <Nav className="align-items-center">
                            {me?.role_id !== 6 &&
                                <NotificationsList dataNotifications={notifications}/>
                            }
                            <Dropdown as={Nav.Item}>
                                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                                    <div className="media d-flex align-items-center position-relative">
                                        <Image src={manager} className="user-avatar md-avatar rounded-circle"/>
                                        <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                                            <span className="mb-0 font-small fw-bold">{me?.full_name}</span>
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                                    {[constants.roles.ADMIN.id, constants.roles.EXPERT.id, constants.roles.EXPERT_LEADER.id].every(role => !iAm(role)) && me?.role_id !== 6 &&
                                        <Dropdown.Item className="fw-bold"
                                                       onClick={() => setSaleRepostId(me?.id)}>
                                            <FontAwesomeIcon icon={faAddressBook} className="me-2"/> Báo cáo doanh số
                                        </Dropdown.Item>}
                                    <Dropdown.Item className="fw-bold" onClick={() => setShowInfoModal(true)}>
                                        <FontAwesomeIcon icon={faUser} className="me-2"/> Thông tin cá nhân
                                    </Dropdown.Item>
                                    <Dropdown.Item className="fw-bold" onClick={() => setShowChangePasswordModal(true)}>
                                        <FontAwesomeIcon icon={faKey} className="me-2"/>Đổi mật khẩu
                                    </Dropdown.Item>
                                    <Dropdown.Item className="fw-bold" href={`/logout`}>
                                        <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"/> Đăng xuất
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </div>
                </Container>
            </Navbar>
            <SaleInfoModal visible={showInfoModal} hide={() => setShowInfoModal(false)}/>
            <ChangePasswordModal visible={showChangePasswordModal} hide={() => setShowChangePasswordModal(false)}/>
        </>
    );
};
