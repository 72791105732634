import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import { Button, Form} from 'react-bootstrap';
import {BsArrowReturnLeft} from "react-icons/bs"

import Loading from "components/main/Loading";
import Breadcrumbs from "components/main/Breadcrumbs";
import {Routes} from "routes";
import {setPageTitle, generateRandomPassword, getReturnUrlFromParamString} from "common/function";
import constants from "config/constants"
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';

import axiosCommon from 'config/axiosCommon';
import { ApiPath } from "apiPath";

export default function CreateStaff() {
	const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
	
	const handleFormSubmit = async () => {
		setIsLoading(true)
		const res = await axiosCommon.post(ApiPath.staffs, formik.values).catch(e => e.response.data)
		setIsLoading(false)
		if(!res.error) {
			navigate(Routes.Staffs.path, {
				state: {
					notification: { type: 'success', message: "Tạo nhân viên thành công!" }
				}
			})
		} else {
            if(res.error.email) {
                return toast.error('Email đã tồn tại!', {
                    autoClose: false,
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                })
            }
            if(res.error.user_name) {
                return toast.error('Tên tài khoản đã tồn tại!', {
                    autoClose: false,
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                })
            }
            toast.error('Tạo nhân viên thất bại. Vui lòng thử lại!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
		}
    }

    const formik = useFormik({
    	initialValues: {
			user_name: '',
			full_name: '',
			phone: '',
			email: '',
			role_id: '',
			password: '',
			is_active: true
      	}, 
		validationSchema: Yup.object({
			user_name: Yup.string()
				.required('Vui lòng cung cấp tên tài khoản!'),
			full_name: Yup.string()
				.required('Vui lòng cung cấp tên nhân viên'),
			phone: Yup.string()
				.matches(/^\+?[\d- ]+$/, 'Số điện thoại không hợp lệ!')
				.required('Vui lòng cung cấp số điện thoại!'),
			email: Yup.string()
				.email('Email không hợp lệ!')
				.required('Vui lòng cung cấp email!'),
			role_id: Yup.number()
				.required('Vui lòng chọn quyền hạn!'),
			password: Yup.string()
				.min(6, 'Mật khẩu tối thiểu 6 kí tự!')
				.required('Vui lòng cung cấp mật khẩu'),
		}),
		onSubmit: handleFormSubmit
    });

    useEffect(() => {
        setPageTitle('Thêm nhân viên mới');
    }, [])
  
    return (
		<>
			<Loading show={isLoading}/>
			<div className="row mb-3">
				<div className="col">
					<Breadcrumbs firstLocation={Routes.CreateStaff}/>
				</div>
				<div className="col d-flex justify-content-end align-items-center">
					<NavLink className="btn btn-danger d-flex align-items-center" to={getReturnUrlFromParamString() ??  Routes.Staffs.path}>
						<BsArrowReturnLeft size={20}/> &nbsp; Quay lại
					</NavLink>
				</div>
			</div>
			<Form onSubmit={formik.handleSubmit}>
				<h5 className="text-center">Thông tin nhân viên</h5>
				<div className="border border-primary p-5 mx-4 rounded bg-white">
					<div className="row gap-3 mb-4">
						<div className="col">
							<Form.Label>Tên nhân viên</Form.Label>
							<Form.Control
								type="text"
								placeholder="Tên nhân viên"
								name="full_name"
								value={formik.values.full_name}
								onChange={formik.handleChange}
								isInvalid={formik.touched.full_name && formik.errors.full_name}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.full_name}</Form.Control.Feedback>
						</div>
						
						<div className="col">
							<Form.Label>Số điện thoại</Form.Label>
							<Form.Control
								type="text"
								placeholder="Số điện thoại"
								name="phone"
								value={formik.values.phone}
								onChange={formik.handleChange}
								isInvalid={formik.touched.phone && formik.errors.phone}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.phone}</Form.Control.Feedback>
						</div>
					</div>
					<div className="row gap-3">
						<div className="col">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="text"
								placeholder="Email"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								isInvalid={formik.touched.email && formik.errors.email}
							/>
							<Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
						</div>
						<div className="col"></div>
					</div>
				</div>

				<h5 className="text-center mt-5">Thông tin tài khoản và quyền hạn</h5>
				<div className="border border-primary p-5 mx-4 rounded bg-white">
				<div className="row mb-4 gap-3">
					<div className="col">
					<Form.Label>Tên tài khoản</Form.Label>
					<Form.Control
						type="text"
						placeholder="Tên tài khoản"
						name="user_name"
						value={formik.values.user_name}
						onChange={formik.handleChange}
						isInvalid={formik.touched.user_name && formik.errors.user_name}
					/>
					<Form.Control.Feedback type="invalid">{formik.errors.user_name}</Form.Control.Feedback>
					</div>
					
					<div className="col">
						<Form.Label>Quyền hạn</Form.Label>
						<Form.Select
							type="text"
							placeholder="Quyền hạn"
							name="role_id"
							value={formik.values.role_id}
							onChange={formik.handleChange}
							isInvalid={formik.touched.role_id && formik.errors.role_id}
						>
							<option></option>
							{Object.entries(constants.roles).map(([key, role]) => key !== constants.KEY_ADMIN ? <option value={role.id} key={role.id}>{role.name}</option> : null)}
						</Form.Select>
						<Form.Control.Feedback type="invalid">{formik.errors.role_id}</Form.Control.Feedback>
					</div>
				</div>
				<div className="row mb-4">
					<div className="col">
						<Form.Label>Kích hoạt</Form.Label>
						<Form.Check
							type="switch"
							name="is_active"
							checked={formik.values.is_active}
							onChange={formik.handleChange}
						/>
					</div>
				</div>
				<div className="row gap-2">
					<div className="col">
						<Form.Label>Mật khẩu</Form.Label>
						<Form.Control
						type="text"
						placeholder="Mật khẩu"
						name="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						isInvalid={formik.touched.password && formik.errors.password}
						/>
						<Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
					</div>
					<div className="col d-flex flex-column justify-content-end">
						<Button variant="tertiary" onClick={() => formik.setFieldValue('password', generateRandomPassword())}>Tạo mật khẩu</Button>
					</div>
				</div>
				</div>
				<div className="row mt-4 mx-4 gap-3">
					<Button type="submit" className="btn btn-success">Lưu thông tin</Button>
				</div>
			</Form>
		</>
    )
}
