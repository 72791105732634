import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert} from 'react-bootstrap';
import BgImage from "../../assets/img/signin.svg";
import {Link, useNavigate} from 'react-router-dom';
import Loading from "../../components/main/Loading";
import axiosCommon from "../../config/axiosCommon";
import {Routes} from "../../routes";
import {setPageTitle} from "../../common/function";

export default () => {
    const [loaded, setLoaded] = useState(false);
    const [email, setEmail] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');

    const onSubmit = (e) => {
        setLoaded(true)
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setLoaded(false);
            return;
        }
        setErrorText('')
        setShowAlert(false)
        let config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: true,
        }
        axiosCommon.post('/api' + Routes.ForgotPasswordSendMail.path, {email}, config)
            .then(res => {
                    setShowAlert(false)
                    window.location.href = '/forgot-password/check-code?email=' + email
                },
                error => {
                    setLoaded(false);
                    setErrorText('Email bạn nhập không đúng hoặc chưa được đăng ký vui lòng kiểm tra lại hoặc liên hệ admin!')
                    setShowAlert(true)
                },
            )
    }

    useEffect(() => {
        setPageTitle('Quên mật khẩu');
    }, [])

    return (
        <main>
            <Loading show={loaded}/>
            <section className="align-items-center mt-lg-5 mb-lg-5">
                <p className="text-center">
                    <Card.Link as={Link} to={Routes.Login.path} className="small text-end">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                             data-icon="angle-left"
                             className="svg-inline--fa fa-angle-left fa-w-8 me-2" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                            <path fill="currentColor"
                                  d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                        </svg>
                        Quay lại</Card.Link>
                </p>
                <Container>
                    <Row className="justify-content-center form-bg-image" style={{backgroundImage: `url(${BgImage})`}}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Quên mật khẩu</h3>
                                </div>
                                <Alert
                                    variant="danger"
                                    show={showAlert}
                                    onClose={() => setShowAlert(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlert(false)}/>
                                    </div>
                                </Alert>
                                <Alert
                                    variant="success"
                                    show={showAlertSuccess}
                                    onClose={() => setShowAlertSuccess(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlertSuccess(false)}/>
                                    </div>
                                </Alert>
                                <div>Đừng lo lắng! Nhập email của bạn và chúng tôi sẽ gửi cho bạn một mã để đặt lại mật
                                    khẩu!
                                </div>
                                <Form className="mt-4">
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>Tài khoản</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faUserTie}/>
                                            </InputGroup.Text>
                                            <Form.Control
                                                required name="email" maxLength="100"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="text"
                                                placeholder="Vui lòng nhập email của bạn"/>
                                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={onSubmit} className="w-100">Khôi
                                        phục mật
                                        khẩu</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
