import axios from 'axios';
import cookieHelper from './cookie-helper';
import constants from './constants';
import authUtil from './auth-util';

const axiosCommon = axios.create({
    baseURL: process.env.REACT_APP_API_KEY,
    // timeout: 2000,
    headers: { 'Content-Type': 'application/json' },
})

const isProductionMode = () => {
    return process.env.NODE_ENV == constants.mode.production
}

// handle request
axiosCommon.interceptors.request.use(
    (config) => {
        const token = cookieHelper.getCookie(constants.accessToken)
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (sessionStorage.lenderId) config.headers.lenderId = sessionStorage.lenderId
        if (sessionStorage.mode) config.headers.mode = sessionStorage.mode
        // if (!isProductionMode()) console.log(config)
        return config
    },
    (error) => {
        // if (!isProductionMode()) console.log(error)
        return Promise.reject(error)
    },
)

// handle response
axiosCommon.interceptors.response.use(
    (response) => {
        // todo handle response success
        // if (!isProductionMode()) console.log(response)
        return response
    },
    (error) => {
        // Commented by thuannd 27/02/23
        // Uncomment below for debug mode
        // if (!isProductionMode()) {
        //     console.log(error)
        //     alert(error.response.status + "-" + error.response.statusText)
        //     return Promise.reject(error)
        // }
        if (!error.response || 
            window.location.pathname === '/page-error' || 
            window.location.pathname === '/page-not-found' || 
            window.location.pathname === '/forbidden') {
            return Promise.reject(error)
        }
        switch (true) {
            case (error.response.status === 401):
                authUtil.removeAuth()
                window.location.href = '/login'
                break
            case (error.response.status === 403):
                window.location.href = '/forbidden'
                break
            case (error.response.status === 404):
                window.location.href = '/page-not-found'
                break
            case (error.response.status >= 500):
                if (!isProductionMode()) {
                    sessionStorage.errorContent = error.response.data
                }
                window.location.href = '/page-error'
                break
        }
        return Promise.reject(error)
    },
)

export default axiosCommon
