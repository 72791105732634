
const constants = {
    mode: {
        development: 'development',
        production: 'production',
    },
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',

    gradingStatus: [
        "chưa chấm",
        "đã chấm"
    ],

    unpostedStatus: [
        "chưa chữa",
        "đã chữa"
    ],

    typeTarget: {
        user: 1,
        company: 0
    },
    roles: {
        ADMIN: {
            id: 1,
            name: 'Administrator'
        },
        SALE_LEADER: {
            id: 2,
            name: 'Sale Leader'
        },
        SALE: {
            id: 3,
            name: 'Sale'
        },
        EXPERT: {
            id: 4,
            name: "Học thuật"
        },
        EXPERT_LEADER: {
            id: 5,
            name: "Lead học thuật"
        },
        STUDENT: {
            id: 6,
            name: "Học viên"
        }
    },
    KEY_ADMIN: 'ADMIN',
    KEY_SALE_LEADER: 'SALE_LEADER',
    KEY_SALE: 'SALE',
    KEY_EXPERT: 'EXPERT',
    ROLE_ID_ADMIN: 1,
    ROLE_ID_SALE: 3,
    contact_sources: [
        { id: 1, title: "Inbox" },
        { id: 2, title: "Fanpage" },
        { id: 3, title: "Web" },
        { id: 4, title: "Khác" },
    ],
    statuses: [
        { id: 13, title: "Cuộc gọi khai thác" },
        { id: 1, title: "Chưa gửi test" },
        { id: 2, title: "Đã gửi test tới học viên" },
        { id: 3, title: "Đang chờ xếp lịch gọi - không chấm test" },
        { id: 4, title: "Đang chờ xếp lịch gọi - có chấm test" },
        { id: 5, title: "Đã xếp lịch gọi - không chấm test" },
        { id: 6, title: "Đã xếp lịch gọi - có chấm test" },
        { id: 9, title: "Đã đặt cọc" },
        { id: 10, title: "Đã hoàn thành học phí" },
        { id: 11, title: "Đã gửi lộ trình" },
        { id: 12, title: "Từ chối đăng ký" },
    ],
    status_rejects: [
        { id: 1, title: "Tài chính" },
        { id: 2, title: "Tham khảo thêm trung tâm khác" },
        { id: 3, title: "Không thích học video" },
        { id: 4, title: "Không liên lạc được" },
        { id: 5, title: "Thời gian chưa gấp nên chưa học ngay" },
        { id: 6, title: "Đang xem xét và cân nhắc" },
    ],
    writing_skill_tests: [
        { id: 1, title: "Writing" },
        { id: 0, title: "Not Writing" },
    ],
    time_study_types: [
        { id: 1, title: "Ngày" },
        { id: 2, title: "Tuần" },
        { id: 3, title: "Tháng" },
    ],
    arr_call_status_ids: [3, 4, 5, 6],
    arr_test_status_ids: [4, 6],
    arr_tuition_fees_status_ids: [8, 9],
    arr_learning_path_status_ids: [9, 10],
    arr_tuition_fees_required_status_ids: [9, 10, 11],
    arr_reject_status_ids: [12],
    notification_status: {
        UNSEEN: 1,
        SEEN: 0,
    },
    notification_types: {
        PAY_FEES: 1,
        LEARNING_PATH: 2,
        MARK_TEST: 3,
        CORRECT_TEST: 4,
        NEW_TEST: 5,
        NEW_LEARNING_PATH: 6,
        NEED_CALL: 7,
    },
    LIMIT_MONEY: 100000000000,
    arrPerPage: [10, 15, 20, 25, 30, 50],
}

export default constants
