
export const ApiPath = {
    // API URL
    dashboard: '/api/dashboard',
    studentDashboard: '/api/student-dashboard',
    studentInfos: '/api/student-info',
    studentImport: '/api/student-info/import',
    studentUpdateData: '/api/student-info/update',
    detailStudentInfo: '/api/student-info/',
    commonData: '/api/common-data',
    students: '/api/students',
    detailStudent: '/api/student/',
    subskills: '/api/subskill',
    goal: '/api/target',
    employee: '/api/employee-report',
    lessons: '/api/lesson',
    login: '/api/login',
    logout: '/api/logout',
    deleteStudent: '/api/student/',
    storeStudent: '/api/student',
    subSkillGroup: '/api/subskill-group',
    staffs: '/api/users',
    markAndCorrect: '/api/mark_and_correct_the_test',
    SurveyQuestionsResult: '/api/survey_questions_result',
    question: '/api/master_survey_questions',
    learningPaths: 'api/learning_path',
    notifications: 'api/notifications',
    companyReport: '/api/company-report',
    companyReportChart: 'api/company-report-chart',
    me: 'api/me',
    updateMe: 'api/update-me',
    resetPassword: '/api/reset-password',
    adminResetPassword: 'api/admin-reset-password',
    externalRevenue: 'api/external-revenue',
    timeCall: 'api/time-call',
    exportStudentInfo: 'api/export-student-info',
    createAccount: 'api/student/create-account',
};
