import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import axiosCommon from '../../config/axiosCommon';
import {ApiPath} from "../../apiPath";
import Loading from "../../components/main/Loading";
import {Col, Form, Button, Row} from 'react-bootstrap';
import ModalCustom from "../Custom/ModalCustom";

const initialState = {
    isLoading: false,
    oldTuitionFeePaid: '',
    listFeePaids: [],
    inputs: {
        input_fees:'',
        tuition_fees:'',
        tuition_fee_paid:'',
        remaining_tuition_fees:''
    }
};

export default (props) => {
    const {
        show,
        setShowModal,
        id,
		actionOk,
		actionCancel = '',
    } = props
    const [isLoading, setIsLoading] = useState(initialState.isLoading);
    const [oldTuitionFeePaid, setOldTuitionFeePaid] = useState(initialState.oldTuitionFeePaid);
    const [listFeePaids, setListFeePaids] = useState(initialState.listFeePaids);
    const [inputs, setInputs] = useState(initialState.inputs);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [columns] = useState(
        [
            {
                name: 'No',
                selector: row => row.row_no,
                sortable: true,
                width: 10,
            },
            {
                name: 'Ngày nộp',
                sortable: true,
                selector: row => row.create_date,
                grow: 2,
            },
            {
                name: 'Số tiền nộp',
                selector: row => new Intl.NumberFormat().format(row.tuition_fee_paid),
                grow: 2,
            },
            {
                name: 'Người phụ trách',
                sortable: true,
                selector: row => row.user_full_name,
                grow: 2,
            },
        ]
    );

    useEffect(() => {
        if (show) {
            setIsLoading(initialState.isLoading)
            setInputs(initialState.inputs)
            setListFeePaids(initialState.listFeePaids)
            setOldTuitionFeePaid(initialState.oldTuitionFeePaid)
            handleGetStudentInfo()
        }
    }, [show])

    const handleGetStudentInfo = () => {
        setIsLoading(true);
        axiosCommon.get(ApiPath.detailStudentInfo + id)
	      .then((res) => {
              setInputs({...res.data.student_info, input_fees: ''})
              setListFeePaids(res.data.paid_history)
              setOldTuitionFeePaid(res.data.student_info.tuition_fee_paid)
	      })
		.finally(()=> {
	        setIsLoading(false);
		});
    }

    const handleClose = () => {
		if (actionCancel) {
			actionCancel();
		}
		setShowModal(false);
    };
    
    const handleOk = () => {
        actionOk()
        handleClose()
    };
    
    const handleChangeFee = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        value = value ? value.match(/\d/g).join('') : value;
        setInputs(values => ({...values, [name]: value}))

        // Show fee
        let inputFees = name == 'input_fees' ? Number(value) : Number(inputs.input_fees ?? 0)
        let tuitionFees = name == 'tuition_fees' ? Number(value) : Number(inputs.tuition_fees)
        let tuitionFeePaid = Number(oldTuitionFeePaid) + inputFees
        let remainingTuitionFees = tuitionFees - tuitionFeePaid
        setInputs(values => ({...values, tuition_fee_paid: tuitionFeePaid, remaining_tuition_fees: remainingTuitionFees}))
    }

    const handlerUpdateStudentInfo = (event) => {
        setIsLoading(true);
        axiosCommon.put(ApiPath.detailStudentInfo + id, {...inputs, return_data: true})
            .then((res) => {
                // handleGetStudentInfo()
                setInputs({...res.data.student_info, input_fees: ''})
                setListFeePaids(res.data.paid_history)
                setOldTuitionFeePaid(res.data.student_info.tuition_fee_paid)
            })
            .finally(()=> {
                setIsLoading(false);
            });
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };

    return (
        <>
            <Loading show={isLoading}/>
            <Modal show={show} onHide={handleClose} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title>Chỉnh sửa thông tin đóng học phí</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ps-1">
                        <Row className="mb-3">
                            <Form.Group as={Col} md="7" controlId="input_fees">
                                <Form.Label>Số tiền nộp</Form.Label>
                                <Form.Control className={inputs.check_edit_fees?'text-danger':''} type="text" placeholder="Học phí nộp lần này" 
                                    name="input_fees" value={inputs.input_fees && new Intl.NumberFormat().format(inputs.input_fees)} onChange={handleChangeFee}/>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="position-relative">
                                <Button disabled={!inputs.input_fees || Number(inputs.input_fees) <= 0} type="button" variant="warning" className="position-absolute" 
                                style={{bottom: 0}} onClick={() => inputs.input_fees && setShowConfirmModal(true)} >Nộp học phí</Button>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="7" controlId="tuition_fees">
                                <Form.Label>Tổng học phí</Form.Label>
                                <Form.Control className={inputs.check_edit_fees?'text-danger':''} type="text" placeholder="Tổng học phí phải đóng" 
                                    name="tuition_fees" value={inputs.tuition_fees && new Intl.NumberFormat().format(inputs.tuition_fees)} onChange={handleChangeFee}/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="7" controlId="tuition_fee_paid">
                                <Form.Label>Học phí đã nộp</Form.Label>
                                <Form.Control className={inputs.check_edit_fees?'text-danger':''} type="text" disabled placeholder="Học phí đã nộp" 
                                    name="tuition_fee_paid" value={inputs.tuition_fee_paid && new Intl.NumberFormat().format(inputs.tuition_fee_paid)} onChange={handleChangeFee}/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="7" controlId="remaining_tuition_fees">
                                <Form.Label>Học phí chưa nộp</Form.Label>
                                <Form.Control className={inputs.check_edit_fees?'text-danger':''} type="text" disabled placeholder="Học phí chưa nộp"
                                    name="remaining_tuition_fees" value={inputs.remaining_tuition_fees && new Intl.NumberFormat().format(inputs.remaining_tuition_fees)} onChange={handleChangeFee}/>
                            </Form.Group>
                        </Row>
                    </div>
                    <DataTable
                        columns={columns}
                        data={listFeePaids}
                        pagination
                        title="Bảng lịch sử đóng học phí"
                        customStyles={customStyles}
                        noDataComponent={<div className="p-4">Không tìm thấy dữ liệu nào.</div>}
                    />
                    <hr/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <ModalCustom
                title={`Xác nhận nộp học phí`}
                className="mt-10"
                actionOk={handlerUpdateStudentInfo}
                showModal={showConfirmModal}
                setShowModal={setShowConfirmModal}>
                <div>Bạn có chắc muốn nộp học phí không?</div>
            </ModalCustom>

        </>
    );
}
