import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie, faUnlockAlt} from "@fortawesome/free-solid-svg-icons";
import {Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert} from 'react-bootstrap';
import BgImage from "../../assets/img/signin.svg";
import {Link, useNavigate} from 'react-router-dom';
import Loading from "../../components/main/Loading";
import axiosCommon from "../../config/axiosCommon";
import authUtil from "../../config/auth-util";
import {Routes} from "../../routes";
import {setPageTitle} from "../../common/function";

export default () => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        user_name: '',
        password: '',
    });
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            setLoaded(true);
            return;
        }

        setLoaded(false);
        setErrorText('')
        setShowAlert(false)
        axiosCommon.post('/api/login', inputs)
            .then(
                response => {
                    authUtil.setAuth(response.data)
                    sessionStorage.removeItem('email')
                    if (sessionStorage.returnUrl) {
                        navigate(sessionStorage.returnUrl);
                        sessionStorage.removeItem('returnUrl')
                    } else {
                        navigate(Routes.Dashboard.path);
                    }
                },
                error => {
                    setLoaded(true);
                    if (error.response && error.response.data) {
                        setErrorText('Tên tài khoản hoặc mật khẩu không đúng. Vui lòng thử lại')
                        setShowAlert(true)
                    } else {
                        setErrorText('Đã xảy ra lỗi đăng nhập hệ thống. Vui lòng thử lại')
                        setShowAlert(true)
                    }
                },
            )
            .finally(() => {
                setLoaded(true);
            });
    }

    useEffect(() => {
        setPageTitle('Đăng nhập vào hệ thống');
        setLoaded(true);
    }, [])

    return (
        <main>
            <Loading show={loaded ? false : true}/>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <Row className="justify-content-center form-bg-image" style={{backgroundImage: `url(${BgImage})`}}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Đăng nhập hệ thống</h3>
                                </div>

                                <Alert
                                    variant="danger"
                                    show={showAlert}
                                    onClose={() => setShowAlert(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlert(false)}/>
                                    </div>
                                </Alert>
                                <Form className="mt-4" onSubmit={onSubmit} noValidate validated={validated}>
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>Tài khoản</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faUserTie}/>
                                            </InputGroup.Text>
                                            <Form.Control required name="user_name" maxLength="100"
                                                          value={inputs.user_name} onChange={handleChange} type="text"
                                                          placeholder="Vui lòng nhập tên tài khoản"/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group id="password" className="mb-4">
                                            <Form.Label>Mật khẩu</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUnlockAlt}/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    required name="password" type="password" maxLength="100"
                                                    value={inputs.password} onChange={handleChange}
                                                    placeholder="Vui lòng nhập mật khẩu"/>
                                            </InputGroup>
                                        </Form.Group>
                                        <div
                                            className="d-flex justify-content-between align-items-center mb-2 float-end">
                                            {/*<Form.Check type="checkbox">*/}
                                            {/*<FormCheck.Input id="defaultCheck5" className="me-2"/>*/}
                                            {/*<FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Ghi nhớ*/}
                                            {/*    tôi</FormCheck.Label>*/}
                                            {/*</Form.Check>*/}
                                            <Card.Link as={Link} to={Routes.ForgotPasswordSendMail.path}
                                                       className="small text-end">Quên mật khẩu?</Card.Link>
                                        </div>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100">Đăng nhập</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
