import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import axiosCommon from "../../config/axiosCommon";
import authUtil from "../../config/auth-util";
import Loading from "../../components/main/Loading";
import {setPageTitle} from "../../common/function";

export default () => {
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle('Đang đăng xuất');
        axiosCommon.post('/api/logout')
            .then(
                response => {
                    authUtil.removeAuth()
                },
                error => {
                    authUtil.removeAuth()
                },
            )
			.finally(()=> {
                navigate('/login');
			});
    },[])

    return (
        <Loading show={!loaded} />
    );
};
