import constants from './constants'
import cookieHelper from './cookie-helper'

const authUtil = {}

authUtil.setCookie = function (token) {
    cookieHelper.setCookie(constants.refreshToken, token, 7)
}

authUtil.setAuth = function (auth) {
    if (auth.user.id) {
        sessionStorage.id = auth.user.id
    }
    sessionStorage.role_id = auth.user.role_id
    sessionStorage.full_name = auth.user.full_name
    cookieHelper.setCookie(constants.accessToken, auth.access_token, auth.expires_in)
    cookieHelper.setCookie(constants.refreshToken, auth.access_token, 3600)
}

authUtil.checkLogged = function () {
    if (sessionStorage.isLoggedIn) {
        return true
    }
    return false
}

authUtil.removeAuth = function () {
    sessionStorage.clear()
    cookieHelper.deleteCookie(constants.accessToken)
    cookieHelper.deleteCookie(constants.refreshToken)
}

export default authUtil
