import {cloneDeep, isObject, range} from "lodash";
import {snapshot} from "valtio";
import {timeTableProxy} from "../store/proxy/timeTable.proxy";
import {ApiPath} from "../apiPath";
import cookieHelper from '../config/cookie-helper';
import constants from '../config/constants';
import moment from "moment";
import {toast} from "react-toastify";

export const setPageTitle = (title) => {
    document.title = title + (title ? ' - ' : '') + 'IELTS Xuân Phi'
}

export const openStudentPdfView = (studentId) => {
    let url = process.env.REACT_APP_API_KEY + ApiPath.storeStudent + '/' + studentId + '/pdf'
    const token = cookieHelper.getCookie(constants.accessToken)
    if (token) url += '?token=' + token
    window.open(url)
}

export const openRepostPdfView = (newDateSearch) => {
    let url = process.env.REACT_APP_API_KEY + ApiPath.companyReport + '?type=pdf&date=' + JSON.stringify(newDateSearch)
    const token = cookieHelper.getCookie(constants.accessToken)
    if (token) url += '&token=' + token
    window.open(url)
}

export const distributeUnit = (units, unitIds) => {
    if (units?.length > 0 && unitIds?.length > 0) {
        let arr = []
        let i = 0
        units.map((val, ind) => {
            let arrC = []
            for (let num of range(i, i + val)) {
                if (unitIds[num]) {
                    arrC.push(unitIds[num])
                }
            }
            i = i + val
            arr[ind] = arrC
        })
        return arr
    }
    return []
}

export const convertDataSubSkill = (arrUnit, subSkill) => {
    let _subSkill = cloneDeep(subSkill)
    if (_subSkill?.length > 0) {
        let skill = 0
        subSkill.map((val, ind) => {
            if (ind > 0) {
                if (subSkill[ind] === skill) {
                    _subSkill[ind] = " "
                } else {
                    skill = val
                }
            } else {
                skill = val
            }
        })
        return distributeUnit(arrUnit, _subSkill)
    }
    return []
}

export const getUnitDefault = (day = 4, unit = '') => {
    let unitDefault = []
    for (let i = 0; i < Number(day); i++) {
        unitDefault.push(unit !== '' ? Number(unit) : '')
    }
    return unitDefault
}
export const getStudyDefault = (studyDefault) => {
    let detail = []
    let results = studyDefault.results ?? []
    for (let i = 0; i < Number(studyDefault.week); i++) {
        let day = results[i] ?? studyDefault.day
        let unit = studyDefault.unit ?? 1
        detail.push({
            week: i + 1,
            day: day,
            unit: getUnitDefault(day, unit),
            subSkillId: getUnitDefault(day, unit),
            notes: getUnitDefault(day, unit),
            isComplete: getUnitDefault(day, unit),
        })
    }

    return detail
}

export const changeTimeTableDefault = () => {
    const {dataTimeTable, setDataTimeTable, skillDefault, studyDefault} = snapshot(timeTableProxy)

    let data = []
    skillDefault.map((skill, indSkill) => {
        data.push({
            skill: skill,
            detail: getStudyDefault(studyDefault),
            unitId: getUnitDefault(studyDefault.day * studyDefault.week * studyDefault.unit),
            subSkillId: getUnitDefault(studyDefault.day * studyDefault.week * studyDefault.unit),
            notes: getUnitDefault(studyDefault.day * studyDefault.week * studyDefault.unit),
            isComplete: getUnitDefault(studyDefault.day * studyDefault.week * studyDefault.unit),
        })
    })
    setDataTimeTable([...data])
}

export const changeStudyDefault = () => {
    const {studyDefault, setStudyDefault} = snapshot(timeTableProxy)
    let _studyDetail = {
        week: 4,
        day: 4,
        unit: 1,
        unitTotal: 16,
        details: [],
        results: [4,4,4,4]
    }
    setStudyDefault(_studyDetail);
}

export const addLearningSkill = ({skillName, numOfUnit}) => {
    const {dataTimeTable, setDataTimeTable, studyDefault} = snapshot(timeTableProxy)

    const study = {...studyDefault, unit: numOfUnit};
    let data = [...dataTimeTable]
    data.push({
        skill: skillName,
        detail: getStudyDefault(study),
        unitId: getUnitDefault(studyDefault.day * studyDefault.week * numOfUnit),
        subSkillId: getUnitDefault(studyDefault.day * studyDefault.week * numOfUnit),
        notes: getUnitDefault(studyDefault.day * studyDefault.week * numOfUnit),
        isComplete: getUnitDefault(studyDefault.day * studyDefault.week * numOfUnit),
    })
    setDataTimeTable([...data])
}

export const getCourseDatail = () => {
    const {dataTimeTable, studyDefault} = snapshot(timeTableProxy)
    const courseDetail = {studyDetail: studyDefault, timetable: dataTimeTable};
    return JSON.stringify(courseDetail)
}

const getListUnitNotes = (arrUnitIds) => {
    let newNotes = {};
    if (!arrUnitIds.length) return newNotes
    arrUnitIds.map(unitId => newNotes['unit_id_' + unitId] = false)
    return newNotes
}
const partData = (dataTime) => {
    if (dataTime?.length) {
        dataTime.map((value, index) => {
            if (!value?.isComplete || !Object.values(value?.isComplete)?.length || Array.isArray(value?.isComplete)){
                value.isComplete = getListUnitNotes(value?.unitId)
            }
        })
        return [...dataTime]
    }
    return []
}

export const setStudentCourseDetail = (courseDetailJson) => {
    const {setDataTimeTable, studyDefault, setStudyDefault} = snapshot(timeTableProxy)
    let courseDetail = JSON.parse(courseDetailJson)
    let studyDetail = courseDetail.studyDetail ?? studyDefault
    setStudyDefault(studyDetail)
    let timetable = courseDetail.timetable ?? courseDetail
    if (courseDetail.timetable) {
        setDataTimeTable(partData(courseDetail.timetable))
    } else {
        changeTimeTableDefault()
    }
}

export const getTitleFromId = (id, array, defaultValue = '-') => {
    let item = array.find(x => x.id == id)
    if (item) {
        return item.title
    }
    return defaultValue
}

export const getValueFromId = (id, array, defaultValue = '-', value) => {
    let item = array.find(x => x.id == id)
    if (item) {
        return item[value]
    }
    return defaultValue
}

export const formatDate = (value, defaultValue = '', format = 'DD/MM/YYYY') => {
    if (!value) {
        return defaultValue
    }
    return moment(value).format(format)
}

export const formatTime = (value, defaultValue = '', displayFormat = 'HH:mm') => {
    if (!value) {
        return defaultValue
    }
    return moment(value, 'HH:mm:ss').format(displayFormat)
}

export function generateRandomPassword() {
    return Math.random().toString(36).slice(-8)
}

export function getRoleName(id) {
    const role = Object.values(constants.roles).filter(role => role.id === id)[0]
    if (!role) {
        throw new Error(`role(${id}) does not exist`)
    }
    return role.name
}

export function checkRoleNavBar(role, userRole) {
    if (role === "allRole") return true
    if (!role?.length) return false
    return role.includes(userRole);
}

export const changeQueryString = (inputs) => {
	let searchParams = new URLSearchParams(window.location.search)
	Object.entries(inputs).forEach(([key, value]) => {
		searchParams.set(key, value)
	})
	if (searchParams.size > 0) {
		window.history.replaceState(null, null, '?' + searchParams.toString())
	}
}

export const addReturnUrlToParamString = () => {
	return 'returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search)
}

export const getReturnUrlFromParamString = () => {
	const urlParams = new URLSearchParams(window.location.search)
	const returnUrl = urlParams.get('returnUrl')
	return returnUrl;
}

export const getSearchParams = () => {
	let searchParams = {}
	const searchs = new URLSearchParams(window.location.search)
	for(const [key, value] of searchs.entries()) {
		searchParams = { ...searchParams, [key]: value}
	}
	return searchParams
}

export function iAm(role_id) {
    return parseInt(sessionStorage.role_id) === parseInt(role_id)
}

export function formatMoney(amount, fractionDigits = 1, unit = ' tr') {
    const number = (Number(amount) || 0) / 1e6
    if (number % 1 > 1 / 10 ** fractionDigits) {
        return number.toFixed(fractionDigits) + unit
    }
    return number + unit
}

export function formatMoneyComma(num, currency = '') {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + currency;
}

export function getNotificationContent({type_notification, full_name, student_information_id}) {
    switch (type_notification) {
        case constants.notification_types.PAY_FEES:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> có hẹn hoàn thành học phí</div>

        case constants.notification_types.LEARNING_PATH:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> đã làm xong lộ trình</div>

        case constants.notification_types.MARK_TEST:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> đã được chấm bài kiểm tra</div>

        case constants.notification_types.CORRECT_TEST:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> đã được chữa bài kiểm tra</div>

        case constants.notification_types.NEW_TEST:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> cần được chấm và chữa bài kiểm tra</div>

        case constants.notification_types.NEW_LEARNING_PATH:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> cần được làm lộ trình</div>

        case constants.notification_types.NEED_CALL:
            return <div>Học viên <strong className="text-info">{full_name}</strong> có mã <strong
                className="text-info">{student_information_id}</strong> đã được hẹn giờ trong ngày hôm nay</div>

        default:
            throw new Error(`notification type(${type_notification}) is in valid`)
    }
}

export const showToastSuccess = msg => {
    toast.success(msg, {
        autoClose: 3000,
        hideProgressBar: true,
        position: "top-right",
        closeOnClick: true
    })
}
export const checkLink = (link) => {
    if (link.charAt(0) !== '/') {
        link = '/' + link
    }
    return link
}
