import React, {useEffect} from 'react';
import {Button, Toast} from 'react-bootstrap';
import {useSnapshot} from "valtio";
import {toastNotificationProxy} from "../../store/proxy/toastNotification.proxy";


// cái này dùng cho thông báo lưu thành công hay thất bại
// hoặc có thể thông báo chung
// status: thể hiện trạng thái
// content: nội dung muốn thông báo
//cách dùng : const {setToastNotification} = useSnapshot(toastNotificationProxy)
// setToastNotification({status:true, content:"thong bao thanh cong!"})
// setToastNotification({status:false, content:"thong bao that bai!"})
function ToastNotificationCustom() {
    const {toastNotification, setToastNotification} = useSnapshot(toastNotificationProxy)
    const handleClose = () => {
        setToastNotification('')
    }

    useEffect(() => {
        if (Boolean(toastNotification?.content)) {
            setTimeout(() => {
                handleClose()
            }, 3000)
        }
    }, [toastNotification])
    return (
        <>
            <Toast
                show={Boolean(toastNotification?.content)}
                onClose={handleClose}
                style={{position: 'fixed', top: 0, right: 10}}
                className="bg-primary text-white my-3"
                bg={toastNotification?.status ? "success" : "danger"}
            >
                <Toast.Header className={``} closeButton={false}>
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <strong className="me-auto ms-2">Thông báo</strong>
                    <Button variant="close" size="xs" onClick={handleClose}/>
                </Toast.Header>
                <Toast.Body>
                    {toastNotification?.content}
                </Toast.Body>
            </Toast>
        </>
    );
}

export default ToastNotificationCustom;
