import {proxy} from "valtio/vanilla";

const defaultQuestion = {
    id: "ans-1",
    title: '',
    type: 1,
    is_required: true,
    answers: null
}
const questionProxy = proxy({

    questionType: [1, 2, 3],
    textQuestionType: ['text', 'multiple choice', 'checkbox'],


    defaultQuestion: defaultQuestion,
    questionData: [defaultQuestion],
    setQuestionData: (data) => {
        questionProxy.contentToast = [...data]
    },

})

export {
    questionProxy
}
