import React, {useEffect, useState} from "react";
import {NavLink, useParams, useNavigate} from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import {BsArrowReturnLeft} from "react-icons/bs"

import Loading from "components/main/Loading";
import Breadcrumbs from "components/main/Breadcrumbs";
import {Routes} from "routes";
import {setPageTitle, getReturnUrlFromParamString, addReturnUrlToParamString} from "common/function";
import axiosCommon from "config/axiosCommon";
import { ApiPath } from "apiPath";
import { toast } from 'react-toastify';
import { omit } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function EditLearningPath() {
	const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams()
	const [staffs, setStaffs] = useState([]);
	const [learningPath, setLearningPath] = useState({
		full_name: '',
		email: '',
		note: '',
		content_learning: '',
		learning_path_date: '',
		folder_link: '',
		phone: '',
		assigned_user: ''
	})

	const handleInputChange = (event) => {
		setLearningPath({
			...learningPath,
			[event.target.name]: event.target.value
		})
	}

    const handleFormSubmit = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		const data = omit(learningPath, ['email', 'phone', 'note'])
		const res = await axiosCommon.put(ApiPath.learningPaths + `/${id}`, {
            ...data,
            type: "edit",
            learning_path_date: data.learning_path_date ? moment(data.learning_path_date).format("YYYY-MM-DD") : null
        })
			.catch(() => null)
		setIsLoading(false)
		if(res) {
			navigate(getReturnUrlFromParamString() ?? Routes.LearningPaths.path, {
				state: {
					notification: {
						type: 'success',
						message: 'Cập nhật lộ trình thành công!'
					}
				}
			})
		} else {
			toast.error('Cập nhật lộ trình thất bại, vui lòng thử lại!', {
				autoClose: false,
				position: "top-right",
				hideProgressBar: true,
				closeOnClick: true,
			})
		}
    }
  
	const fetchData = async () => {
		setIsLoading(true)
		const data = await Promise.allSettled([
            axiosCommon.post(ApiPath.commonData),
            axiosCommon.get(ApiPath.learningPaths + `/${id}`)
        ])

        if(data.some(item => item.status === "rejected")) {
			toast.error('Vui lòng tải lại trang!', {
				autoClose: false,
				position: "top-right",
				hideProgressBar: true,
				closeOnClick: true,
			})
		} else {
			setStaffs(data[0].value.data.salesPlus)
			setLearningPath(data[1].value.data.data)
		}
		setIsLoading(false)
	}


	useEffect(() => {
		setPageTitle('Chỉnh sửa lộ trình');
		fetchData()
	}, []);

	return (
		<>
			<Loading show={isLoading}/>
			<div className="row mb-3">
				<div className="col">
					<Breadcrumbs firstLocation={Routes.EditLearningPath}/>
				</div>
				<div className="col d-flex justify-content-end align-items-center gap-3">
					<Button type="submit" className="btn btn-success" form="form">Cập nhật</Button>
					<NavLink className="btn btn-warning d-flex align-items-center" to={`/students/${learningPath.student_id}?` + addReturnUrlToParamString()}>
						Lộ trình học
					</NavLink>
					<NavLink className="btn btn-danger d-flex align-items-center" to={getReturnUrlFromParamString() ?? Routes.LearningPaths.path}>
						<BsArrowReturnLeft size={20}/> &nbsp; Quay lại
					</NavLink>
				</div>
			</div>
			<Form onSubmit={handleFormSubmit} id="form">
				<h5 className="text-center">Chi tiết lộ trình học viên {learningPath.full_name || ''}</h5>
				<div className="border border-primary p-5 mx-4 rounded bg-white">
					<div className="row gap-3 mb-4">
						<div className="col">
							<Form.Label>Họ tên</Form.Label>
							<Form.Control
								type="text"
								placeholder="Tên học viên"
								name="full_name"
								value={learningPath.full_name || ''}
								disabled={true}
							/>
						</div>
					
						<div className="col">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="text"
								placeholder="Email"
								name="email"
								value={learningPath.email || ''}
								disabled={true}
							/>
						</div>
					</div>
					<div className="row gap-3 mb-4">
						<div className="col">
							<Form.Label>Số điện thoại</Form.Label>
							<Form.Control
								type="text"
								placeholder="Số điện thoại"
								name="phone"
								value={learningPath.phone || ''}
								disabled={true}
							/>
						</div>
					
						<div className="col">
							<Form.Label>Ngày tạo lộ trình</Form.Label>
                            <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon placeholderText="Ngày tạo" selected={learningPath.learning_path_date ? new Date(learningPath.learning_path_date) : ''} onChange={value =>  setLearningPath({ ...learningPath, learning_path_date: value })}/>
						</div>
					</div>
					<div className="row gap-3 mb-4">
						<div className="col">
							<Form.Label>Link lộ trình</Form.Label>
							<Form.Control
								type="text"
								placeholder="Link lộ trình"
								name="folder_link"
								value={learningPath.folder_link || ''}
								onChange={handleInputChange}
							/>
						</div>
						<div className="col">
							<Form.Label>Người làm lộ trình</Form.Label>
							 <Form.Select
								type="text"
								name="assigned_user"
								placeholder="Người làm lộ trình"
								value={learningPath.assigned_user}
								onChange={handleInputChange}
							>
								<option value="">Người làm lộ trình</option>
								{staffs.map(staff => <option value={staff.id} key={staff.id}>{staff.full_name}</option>)}
							</Form.Select>
						</div>
					</div>

					<div className="row gap-3">
						<div className="col">
							<Form.Label>Note của sale</Form.Label>
							<Form.Control
								type="text"
								as="textarea"
								rows={5}
								placeholder="Note của sale"
								name="note"
								value={learningPath.note || ''}
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</Form>
		</>
	)
}
