import React, { useEffect, useState, Fragment, useRef } from "react";
import {ApiPath} from "../../apiPath";
import axiosCommon from '../../config/axiosCommon';
import Breadcrumbs from "../../components/main/Breadcrumbs";
import { Card, Col, Row, ProgressBar } from 'react-bootstrap';
import Loading from "../../components/main/Loading";
import { Routes } from "../../routes";
import { useNavigate } from 'react-router-dom';
import "../../scss/student-info.scss";
import {formatDate, checkRoleNavBar, setPageTitle} from "../../common/function";
import {useSnapshot} from "valtio";
import {userProxy} from "../../store/proxy/user.proxy";
import constants from "../../config/constants";
import Chart from 'chart.js/auto'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faChartLine, faChartPie, faChartColumn } from '@fortawesome/free-solid-svg-icons';

export default () => {
    const navigate = useNavigate();
    const {currentUser} = useSnapshot(userProxy)
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        student_new: 0,
        revenue_now: 0,
        rate_success: 0,
        reject_total: 0,
        targets: {
            target_of_month: 0,
            revenue_now: 0
        },
        revenue_sale: []
    });

    useEffect(() => {
        setIsLoading(true)
        setPageTitle('Dashboard');
        if (checkRoleNavBar([constants.roles.EXPERT.id, constants.roles.EXPERT_LEADER.id], currentUser?.role_id)) {
            navigate(Routes.Test.path)
        }
        axiosCommon.get(ApiPath.dashboard)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setIsLoading(false);
            });
        document.title = 'IELTS Xuân Phi';
    }, [])

    const formatNumber = (value, digit = 2) => {
        let num = Number(value).toFixed(digit)
        num = num.replace('.00', '').replace('.00', '')
        return num
    }

    const formatCurrency = value => {
        if (Math.abs(value) < 1000) {
            return formatNumber(value)
        } else if (Math.abs(value) < 1E9) {
            return formatNumber(value/1E6) + 'tr'
        }
        return formatNumber(value/1E9, 3) + 'tỷ'
    }

    const getPercent = (current, total, moreThan100 = false) => {
        let currentSales = Number(current)
        let totalSales = Number(total)

            if (!totalSales) {
                return currentSales > 0 ? 100 : 0
            } else if (currentSales > totalSales && moreThan100) {
                return 100
            } else {
                return formatNumber(currentSales/totalSales*100);
            }
        }

    const TargetProgressBar = (props) => {
        const { currentSales, totalSales } = props;
        const percent = getPercent(currentSales, totalSales)

        return (
            <div className="d-flex my-4">
                <div className="report-percent-total" style={{width: '100%'}}>
                    <div className="report-percent-current" style={{ width: Math.min(100, percent) + '%' }}>
                        {percent + '%'}
                    </div>
                </div>
                <div className="ml-10">{formatCurrency(currentSales) + '/' + formatCurrency(totalSales)}</div>
            </div>
        )
    };

    const CounterWidget = (props) => {
        const { icon, iconColor, category, title, showPercent = false } = props;

        return (
            <Card border="light" bg={iconColor} text="white" className="shadow-sm">
                <Card.Body>
                    <Row className="d-block d-xl-flex align-items-center">
                        <Col xs={12} xl={9} className="px-xl-0">
                            <h6 className="mb-3">{category}</h6>
                            <h2 className="mb-1">
                                {title}
                                {showPercent && <sup style={{fontSize: '20px'}}>%</sup> }
                            </h2>
                        </Col>
                        <Col xl={3} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                            <div className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}>
                                <FontAwesomeIcon icon={icon} />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    };

    const ProgressTrackWidget = (props) => {
        const { data } = props;
        const colors = ['secondary','warning','tertiary','info','danger','success','purple','quaternary'];

        return (
            <Card border="light" className="shadow-sm">
                <Card.Header className="border-bottom border-light">
                    <h5 className="mb-0">Doanh thu nhân viên so với mục tiêu</h5>
                </Card.Header>
                <Card.Body>
                    { 
                        data.map((item, index) => {
							if (item.role_id !== null && item.target_of_month !== null) {
								return (
									<Progress key={index} title={item.full_name} color={colors[index%colors.length]} 
										value={item.sum_tuition_fee_paid} total={item.target_of_month} />
								)
							}
                        })
                    }
                </Card.Body>
            </Card>
        );
    };

    const Progress = (props) => {
        const { title, value, total, color, last = false } = props;
        const extraClassName = last ? "" : "mb-2";
        const percentage = getPercent(value, total)

        return (
            <Row className={`align-items-center ${extraClassName}`}>
                <Col>
                    <div className="progress-wrapper">
                        <div className="progress-info mb-0">
                            <h6 className="mb-0">{title}</h6>
                            <small className="fw-bold text-dark">
                                <span>{formatCurrency(value)}/{formatCurrency(total)}</span>
                                {/* <span>{formatCurrency(value)}/{formatCurrency(total)} ({percentage}%)</span> */}
                            </small>
                        </div>
                        <ProgressBar style={{ minWidth: '40px' }} className='progress-xl' label={`${percentage}%`} variant={color} now={percentage} min={0} max={100} />
                    </div>
                </Col>
            </Row>
        );
    };

    const PieChart = ({data, total}) => {
        const chartRef = useRef(null);

        useEffect(() => {
            let chartLabels = []
            let saleData = []
            let totalSale = 0
            data.map((item, index) => {
                chartLabels.push(`${item.full_name}: ${formatCurrency(item.sum_tuition_fee_paid)}`)
                saleData.push(item.sum_tuition_fee_paid)
                totalSale += Number(item.sum_tuition_fee_paid)
            })
            if (totalSale < total) {
                chartLabels.push(`Khác: ${formatCurrency(total - totalSale)}`)
                saleData.push(total - totalSale)
            }

            const myChart = new Chart(chartRef.current, {
                type: 'pie',
                data: {
                    labels: chartLabels,
                    datasets: [{
                        label: "Doanh thu",
                        data: saleData,
                        backgroundColor: [
                            '#61DAFB',
                            '#F5B759',
                            '#1B998B',
                            '#0948B3',
                            '#FA5252',
                            '#05A677',
                            '#8965E0',
                            '#C96480',
                        ],
                        borderWidth: 1,
                    }],
                },
                options: {
                    plugins: {
                        datalabels: {
                            formatter: value =>  {
                                if (!total) {
                                    return 'Chưa có số liệu để thống kê';
                                }
                                return value > 0 ? formatNumber(value*100/total) + '%' : ''
                            },
                            color: total ? '#fff' : '#f00',
                            font: {
                                weight: total ? 'bold' : 'normal',
                                size: 14,
                            }
                        }
                    }

                },
            });

            return () => {
                myChart.destroy();
            };
        }, []);

        return (
            <div>
                <canvas ref={chartRef}/>
            </div>
        );
    };
    return (
        <Fragment>
            <Loading show={isLoading}/>
            <Breadcrumbs firstLocation={Routes.Dashboard} />
            <Row className="mb-1">
                <Col sm={12}>
                    <h5>Tổng hợp doanh thu tháng {formatDate(new Date(), '', 'MM/YYYY')}</h5>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm={8}>
                    <hr className="m-0" />
                    <TargetProgressBar currentSales={data.targets.revenue_now} totalSales={data.targets.target_of_month} />
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs={12} sm={6} xl={3} className="mb-4">
                    <CounterWidget
                        category="Số học viên mới"
                        title={data.student_new}
                        icon={faUserPlus}
                        iconColor="info"
                    />
                </Col>
                <Col xs={12} sm={6} xl={3} className="mb-4">
                    <CounterWidget
                        category="Doanh thu công ty"
                        title={formatCurrency(data.revenue_now)}
                        icon={faChartLine}
                        iconColor="tertiary"
                    />
                </Col>

                <Col xs={12} sm={6} xl={3} className="mb-4">
                    <CounterWidget
                        category="Tỉ lệ chốt thành công"
                        title={formatNumber(data.rate_success)}
                        showPercent={true}
                        icon={faChartPie}
                        iconColor="warning"
                    />
                </Col>
                <Col xs={12} sm={6} xl={3} className="mb-4">
                    <CounterWidget
                        category="Số học viên hủy"
                        title={data.reject_total}
                        // showPercent={true}
                        icon={faChartColumn}
                        iconColor="danger"
                    />
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm={12}>
                    <h5>Chi tiết doanh thu tháng {formatDate(new Date(), '', 'MM/YYYY')}</h5>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm={12} className="mb-3">
                    <hr className="m-0" />
                </Col>
                <Col sm={6}>
                  <ProgressTrackWidget data={data.revenue_sale} />
                </Col>
                <Col sm={6}>
                    <h5 align={"center"} className={`p-3`}>Doanh thu nhân viên so với công ty</h5>
                    <PieChart data={data.revenue_sale} total={data.targets.revenue_now} />
                </Col>
            </Row>
        </Fragment>
    );
}
