import React, { useEffect, useState} from "react";
import { Form, Modal, Button, InputGroup, Alert } from 'react-bootstrap';

export default (props) => {
    const {
        show,
        setShowModal,
		actionOk,
		actionCancel = '',
    } = props
    
    const [inputs, setInputs] = useState({
        name: '',
        skill_type_id: '',
    });

    const [showAlert, setShowAlert] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleClose = () => {
		if (actionCancel) {
			actionCancel();
        }
		setShowModal(false);
	};

    const handleOk = () => {
        if (!validateForm()) {
            setShowAlert(true)
            return
        }
        actionOk(inputs)
        handleClose()
    };
    
    useEffect(() => {
        setShowAlert(false);
        setInputs({
            name: '',
            skill_type_id: '',
        });
        setErrorText('');
    },[show])
    
    const validateForm = () => {
        if(!inputs.name.trim()) {
            setErrorText('Tên Subskill không được để trống');
            return false;
        }
        if(!inputs.skill_type_id) {
            setErrorText('Vui lòng chọn Kỹ năng.');
            return false;
        }

        return true;
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    return (
        <>
            <Modal as={Form} show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Tạo mới Subskill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert
                        variant="danger"
                        show={showAlert}
                        onClose={() => setShowAlert(false)}>
                        <div className="d-flex justify-content-between">
                        <div>{errorText}</div>
                        <Button variant="close" size="xs" onClick={() => setShowAlert(false)} />
                        </div>
                    </Alert>
                    <Form.Group className="mb-4">
                        <Form.Label>Tên Subskill <span className="text-danger">*</span></Form.Label>
                        <InputGroup>
                            <Form.Control required name="name" maxLength="100" value={inputs.name} onChange={handleChange} type="text" placeholder="Vui lòng nhập tên Subskill" />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Kỹ năng <span className="text-danger">*</span></Form.Label>
                        <InputGroup>
                            <Form.Select name="skill_type_id" value={inputs.skill_type_id} onChange={handleChange}>
                                <option value="" defaultValue>Vui lòng chọn kỹ năng</option>
                                <option value="1">Listening</option>
                                <option value="2">Reading</option>
                                <option value="3">Speaking</option>
                                <option value="4">Writing</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="info" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
