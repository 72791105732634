import React, {useEffect, useRef} from 'react';
import 'blueimp-canvas-to-blob';
import Chart from 'chart.js/auto'
import {formatMoneyComma} from "../../common/function";

const PieChart = ({data, type = ''}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!data?.label) return
        let customLabels = data?.label.map((lab, index) => {
            let customLabel = data.data[index]
            if (type === 'money') customLabel = formatMoneyComma(data.data[index])
            return (`${lab}: ${customLabel}`)
        })
        const myChart = new Chart(chartRef.current, {
            type: 'pie',
            data: {
                labels: customLabels,
                datasets: [{
                    label: "",
                    data: data?.data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                }],
            },
            options: {

                plugins: {
                    datalabels: {
                        formatter: (value, ctx) => {
                            let sum = 0;
                            let dataArr = ctx.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            return sum === 0 ? 'Chưa có số liệu để thống kê' : (value * 100 / sum).toFixed(0) + "%";
                        },
                    },
                }
            },
        });

        return () => {
            myChart.destroy();
        };
    }, [data]);

    return (
        <div>
            <canvas ref={chartRef}/>
        </div>
    );
};

export default React.memo(PieChart);
