import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default (props) => {
    const {
        show,
        setShowModal,
		actionOk,
		actionCancel = '',
    } = props

    const handleClose = () => {
		if (actionCancel) {
			actionCancel();
		}
		setShowModal(false);
	};

    const handleOk = () => {
        actionOk()
        handleClose()
    };
    
    return (
        <>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Xác nhận xóa bài học</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Bạn có chắc chắn muốn xóa bài học này không?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose} autoFocus>
                        Hủy
                    </Button>
                    <Button variant="danger" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
