import {memo, useState} from "react";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../../store/proxy/timeTable.proxy";
import {cloneDeep, findIndex} from "lodash";
import {getUnitDefault} from "../../../common/function";
import {toastProxy} from "../../../store/proxy/toast.proxy";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function WeekHear({week}) {
    const {setContentToast} = useSnapshot(toastProxy)
    const {setDataTimeTable, dataTimeTable, studyDefault, setStudyDefault} = useSnapshot(timeTableProxy)
    const [dayUnit, setDayUnit] = useState({
        day: '',
    })

    const changeInput = (name, val) => {
        if (val < 0)
            val = 0
        setDayUnit({...dayUnit, [name]: val})
    }

    const sync = () => {
        let day = Number(dayUnit.day)
        if (day <= 0) {
            setContentToast('Vui lòng nhập đúng giá trị cho số ngày.')
            return
        }

        //Update week study default
        let _studyDefault = cloneDeep(studyDefault)
        _studyDefault.results.map((unit, index) => {
            if (index === week - 1) {
                _studyDefault.results[index] = day
            }
        })
        _studyDefault.unitTotal = _studyDefault.results.reduce((a, b) => a + b, 0)
        setStudyDefault(_studyDefault)

        //Upadte Timetable
        let _dataTimeTable = cloneDeep(dataTimeTable)
        _dataTimeTable.map((skills) => {
            const indexSkill = findIndex(skills.detail, {week: week})
            if (indexSkill > -1) {
                skills.detail[indexSkill].day = day
                let arrUnit = [...skills.detail[indexSkill].unit, ...Array(day).fill(1)]
                arrUnit = arrUnit.slice(0, day)
                skills.detail[indexSkill].unit = arrUnit
            }
        })
        setDataTimeTable([..._dataTimeTable])
        changeInput('day', '')
    }

    return (
        <table className={`text-left`} style={{width: 89}}>
            <tbody>
                <tr>
                    <td>Tuần: </td>
                    <td>{week}</td>
                </tr>
                <tr>
                    <td>Day:</td>
                    <td>
                        <input
                            type='number'
                            style={{width: 30, marginTop: 0}}
                            className="border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder=""
                            value={dayUnit.day}
                            onChange={e => changeInput('day', e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip-bottom'>Thiết lập số ngày học trong tuần</Tooltip>}>
                            <button type="button" className="btn btn-success btn-sm" onClick={sync}>
                                Sync <i className="fa-solid fa-arrows-rotate"/>
                            </button>
                        </OverlayTrigger>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default memo(WeekHear);