import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//modal dùng chung có thể custom thêm nhưng phải để ý các modal khác.
// showModal: set show modal,
// setShowModal: cài đặt để show,
// children: truyền theo vào là nội dung của modal,
// title
// actionOk: function khi ấn ok sẽ thực hiện.
function ModalCustom(props) {
    const {
        showModal,
        setShowModal,
        children = '',
        title = '',
        disabled = false,
        statusShowModal = false,
        actionOk = '',
        showModalAction = '',
        closeModalAction = '',
        className = '',
        titleOk = 'OK',
        showCancelButton = true
    } = props

    const handleClose = () => {
        if (closeModalAction) {
            closeModalAction();
        }
        setShowModal(false)
    };
    const handleOk = () => {
        actionOk()
        if (statusShowModal) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }
    };

    useEffect(() => {
        if (showModal && showModalAction) {
            showModalAction();
        }
    }, [showModal])

    return (
        <>
            <Modal show={showModal} onHide={handleClose} className={className}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    {
                        showCancelButton &&
                        <Button variant="light" onClick={handleClose}>
                            Hủy
                        </Button>
                    }
                    {
                        actionOk &&
                        <Button disabled={disabled} variant="info" onClick={handleOk}>
                            {titleOk}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalCustom;
