import "@fortawesome/fontawesome-free/css/all.min.css";
import {Grid} from "@mui/material";
import SkillTable from "./SkillTable";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../../store/proxy/timeTable.proxy";
import React, {useEffect, useState} from "react";
import {addLearningSkill, getUnitDefault} from "../../../common/function";
import {cloneDeep, findIndex} from "lodash";
import ModalCustom from "../../Custom/ModalCustom";
import axiosCommon from "../../../config/axiosCommon";
import Loading from "../../main/Loading";
import "../../../scss/timetable.scss";
import {Button, Form, InputGroup} from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";
import DetailWeekUnitModal from "./DetailWeekUnitModal";
import {ApiPath} from "../../../apiPath";
import TimetablePdf from "../timetablePdf/TimetablePdf";
import {CopyTimeTable} from "./CopyTimeTable";

function Timetable() {
    const {
        dataTimeTable,
        setDataTimeTable,
        setMaxDayTimetable,
        studyDefault,
        setStudyDefault,
        setLessons,
        setSubskills,
        setLinkAudios,
        setLinkLessons
    } = useSnapshot(timeTableProxy)
    const [showModalAddLearning, setShowModalAddLearning] = useState(false)
    const [showModalPdf, setShowModalPdf] = useState(false)
    const [showModalDetailWeek, setShowModalDetailWeek] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [dataAllSubSkill, setDataAllSubSkill] = useState([])

    const showDeatailWeekUnitModal = () => {
        setShowModalDetailWeek(true);
    }

    const setDetailWeekUnit = (detailWeekUnits) => {
        setStudyDefault(detailWeekUnits)
        let _dataTimeTable = cloneDeep(dataTimeTable)
        //Set skill detail
        _dataTimeTable.map((skill) => {
            let detail = []
            let results = detailWeekUnits.results ?? []
            for (let i = 0; i < Number(detailWeekUnits.week); i++) {
                let week = i + 1
                let day = results[i] ?? detailWeekUnits.day
                let unit = detailWeekUnits.unit ?? 1
                let units = getUnitDefault(day, unit)
                let subSkillIds = getUnitDefault(day, unit)
                let notes = getUnitDefault(day, unit)

                // Get week detail if exist
                const weekIndex = findIndex(skill.detail, {week: week})
                if (weekIndex > -1) {
                    let arrUnit = [...skill.detail[weekIndex].unit, ...Array(day).fill(unit)]
                    units = arrUnit.slice(0, day)
                    subSkillIds = skill.detail[weekIndex].subSkillId
                    notes = skill.detail[weekIndex].notes
                }

                //Add week to detail
                detail.push({
                    week: week,
                    day: day,
                    unit: units,
                    subSkillId: subSkillIds,
                    notes: notes,
                })
            }
            skill.detail = detail
        })
        setDataTimeTable([..._dataTimeTable])
    }

    useEffect(() => {
        let _dataTimeTable = cloneDeep(dataTimeTable)
        if (_dataTimeTable.length > 0) {
            let cc = []
            _dataTimeTable.map((value) => {
                value?.detail.map((val) => {
                    let max = 0
                    if (max < val.day) {
                        max = Number(val.day)
                    }
                    cc[val.week - 1] = max
                })
            })
            setMaxDayTimetable([...cc])
        }
    }, [dataTimeTable])

    useEffect(() => {
        setIsLoading(true)
        axiosCommon.get('/api/timetable')
            .then(
                response => {
                    setLessons(response.data.lessons)
                    setSubskills(response.data.subskills)
                    setLinkAudios(response.data.link_audios)
                    setLinkLessons(response.data.link_lessons)
                },
            )
            .finally(() => {
                setIsLoading(false);
            });
    }, [])

    //get data for unit modal
    useEffect(() => {
        axiosCommon.get(ApiPath.subSkillGroup)
            .then(response => {
                setDataAllSubSkill(response.data)
            })
    }, [])

    return (
        <Grid>
            <Loading show={isLoading}/>
            <Grid className='pt-4'>
                <h5>Thiết lập lộ trình học</h5>
                <hr/>
            </Grid>

            <CopyTimeTable/>
            <table>
                <tbody>
                <tr>

                    <td>Số tuần học:</td>
                    <td><input
                        type="number"
                        style={{width: 40, marginTop: 0}}
                        className="text-end mt-1 border border-gray-200 text-gray-900 text-sm rounded-lg w-full p-2.5"
                        readOnly
                        value={studyDefault.week}
                        onChange={() => {
                        }}
                    /></td>
                    <td style={{paddingLeft: 20}}>Số ngày học mỗi tuần:</td>
                    <td><input
                        type="number"
                        style={{width: 40, marginTop: 0}}
                        className="text-end mt-1 border border-gray-200 text-gray-900 text-sm rounded-lg w-full p-2.5"
                        readOnly
                        value={studyDefault.day}
                        onChange={() => {
                        }}
                    /></td>
                    <td style={{paddingLeft: 20}}>Tổng số ngày học:</td>
                    <td><input
                        type="number"
                        style={{width: 40, marginTop: 0}}
                        className="text-end mt-1 border border-gray-200 text-gray-900 text-sm rounded-lg w-full p-2.5"
                        readOnly
                        value={studyDefault.unitTotal}
                        onChange={() => {
                        }}
                    /></td>
                </tr>
                </tbody>
            </table>
            <Grid container rowSpacing={1} className="mt-1" columnSpacing={{xs: 1, sm: 2, md: 3}}>
                <Grid item xs={6} sm={4} md={3} className={`flex`}>
                    <Button
                        variant="info"
                        onClick={() => setShowModalAddLearning(true)}
                    >Thêm kỹ năng học</Button>
                </Grid>
                <Grid item xs={6} sm={4} md={3} className={`flex`}>
                    <Button
                        variant="tertiary"
                        onClick={showDeatailWeekUnitModal}
                    >Thiết lập chi tiết</Button>

                </Grid>
            </Grid>

            <div className="w-full mt-2">
                <div className="d-flex w-full"
                     style={{
                         background: 'white',
                         border: '1px solid #eaedf2',
                         width: 'fit-content',
                         paddingRight: 5,
                         position: 'sticky',
                         top: 0,
                         zIndex: 10
                     }}>
                    {
                        dataTimeTable.map((data, index) => {
                            return (
                                <div key={index + 'time_table'}>
                                    <SkillTable skills={data} indexSkill={index} dataAllSubSkill={dataAllSubSkill}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <AddLearningSkillModal
                showModal={showModalAddLearning}
                setShowModal={setShowModalAddLearning}
            />
            <ModalPdfTimetable
                showModal={showModalPdf}
                setShowModal={setShowModalPdf}
            />
            {showModalDetailWeek &&
                <DetailWeekUnitModal
                    showModal={showModalDetailWeek}
                    setShowModal={setShowModalDetailWeek}
                    actionOk={setDetailWeekUnit}
                    studyDetails={studyDefault}
                />
            }
        </Grid>
    );
}

function AddLearningSkillModal({showModal, setShowModal}) {
    const [skillName, setSkillName] = useState('');
    const [numOfUnit, setNumOfUnit] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [validateSkillName, setValidateSkillName] = useState(false);
    const [validateNumOfUnit, setValidateNumOfUnit] = useState(false);
    const [status, setStatus] = useState(true);

    const skillNameHandleChange = (event) => {
        setSkillName(event.target.value);
        if (event.target.value === "") {
            setDisabled(true);
            setValidateSkillName(true);
        } else {
            setValidateSkillName(false);
            if (numOfUnit === "") {
                setValidateNumOfUnit(true);
                setDisabled(true);
            } else {
                setDisabled(false);
                setStatus(false);
            }
        }
    }
    const numOfUnitHandleChange = (event) => {
        setNumOfUnit(event.target.value);
        if (event.target.value === "") {
            setDisabled(true);
            setValidateNumOfUnit(true);
        } else {
            setValidateNumOfUnit(false);
            if (skillName === "") {
                setValidateSkillName(true);
                setDisabled(true);
            } else {
                setDisabled(false);
                setStatus(false);
            }
        }
    }

    const actionOK = () => {
        if (skillName !== "" && numOfUnit !== "") {
            addLearningSkill({skillName, numOfUnit})

        } else {
            setDisabled(true);
            setValidateSkillName(true);
            setValidateNumOfUnit(true);
        }
        setSkillName('');
        setNumOfUnit('');
        setDisabled(false);
        setValidateSkillName(false);
        setValidateNumOfUnit(false);
    }

    return (
        <ModalCustom
            title={`Thêm mới kỹ năng học`}
            actionOk={actionOK}
            showModal={showModal}
            setShowModal={setShowModal}
            disabled={disabled}
            statusShowModal={status}
        >
            <Form.Group id="skillName" className="mb-4">
                <Form.Label>Tên kỹ năng học<span className='ps-1 text-danger'>*</span></Form.Label>
                <InputGroup>
                    <Form.Control className={validateSkillName === true ? "is-invalid" : ""} required name="skillName"
                                  maxLength="100" value={skillName} onChange={skillNameHandleChange} type="text"
                                  placeholder="Vui lòng nhập tên kỹ năng học"/>
                </InputGroup>
            </Form.Group>
            <Form.Group id="numOfUnit" className="mb-4">
                <Form.Label>Số Unit/Buổi<span className='ps-1 text-danger'>*</span></Form.Label>
                <InputGroup>
                    <Form.Control className={validateNumOfUnit === true ? "is-invalid" : ""} required name="numOfUnit"
                                  maxLength="100" value={numOfUnit} onChange={numOfUnitHandleChange} type="number"
                                  placeholder="Vui lòng nhập Số Unit/buổi"/>
                </InputGroup>
            </Form.Group>
        </ModalCustom>
    )
}

function ModalPdfTimetable(props) {
    const {
        setShowModal,
        showModal,
    } = props;

    return (
        <Modal show={showModal} fullscreen={true} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Timetable Pdf</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TimetablePdf/>
            </Modal.Body>
        </Modal>
    );
}

export default Timetable;
