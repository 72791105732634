import {memo, useEffect, useState} from "react";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../store/proxy/timeTable.proxy";
import {cloneDeep, slice, sum} from "lodash";

function UnitNumberTable({indexSkill, indexDetail, skill}) {
    const {maxDayTimetable, dataTimeTable, setDataTimeTable, checkCom} = useSnapshot(timeTableProxy)

    function calculateSum(arr, index) {
        const slicedArr = slice(arr, 0, index)
        const sumAr = sum(slicedArr)
        return sumAr;
    }

    function updateObjectFromTo(obj, startIndex, endIndex, isCom) {
        const arrayOfObjects = Object.keys(obj || {}).map(key => ({
            unit_id: key,
            value: obj[key],
        }));
        for (let i = startIndex; i <= endIndex; i++) {
            if (arrayOfObjects[i]) {
                arrayOfObjects[i].value = isCom
            }
        }

        const updatedObj = {};

        arrayOfObjects.forEach(item => {
            if (item.unit_id) {
                updatedObj[item.unit_id] = item?.value;
            }
        });

        return updatedObj;
    }

    const updateDayComplete = (week, indDay, isCom) => {
        let _dataTime = cloneDeep(dataTimeTable)
        _dataTime.map((data, index) => {
            let ars = []
            let arr = data?.detail[week].unit
            for (let i = 0; i < week; i++) {
                ars.push(...data?.detail[i].unit)
            }
            const sumArs = ars.reduce((acc, currentValue) => acc + currentValue, 0);
            let obj = data?.isComplete
            let indexObj1 = calculateSum(arr, indDay)
            let indexObj2 = calculateSum(arr, indDay + 1)
            let upComplete = updateObjectFromTo(obj, indexObj1 + sumArs, indexObj2 - 1 + sumArs, isCom)

            data.isComplete = upComplete
        })
        setDataTimeTable(_dataTime)
    }

    return (
        <table className="table table-bordered bg-white m-0 align-middle items-center" style={{width: 131}}>
            <tbody>
            {[...Array(maxDayTimetable[indexDetail])].map((x, i) => {
                let eee = 0
                for (let ii= 0; ii <indexDetail; ii++){
                    eee += [...maxDayTimetable][ii]
                }
                return (
                        <tr className={`border-bottom-0`} key={i + `unit_number_table`}
                            id={i + `unit_number_table` + indexDetail + indexSkill}>
                            {indexSkill === 0 &&
                                <td style={{height: 151, padding: '0.35rem', textAlign: "center"}}>
                                    <div>Ngày {i + 1}</div>
                                    <div style={{minWidth: 115}}>
                                        <button
                                            type="button"
                                            style={{fontSize: 13}}
                                            onClick={() => updateDayComplete(skill.week - 1, i, !checkCom[eee + i])}
                                            className={`btn btn-link ${checkCom[eee + i] ? 'text-primary' : 'text-note-contain'}  p-0 text-decoration-underline`}
                                        >{!checkCom[eee + i] ? 'Hoàn thành' : 'Bỏ hoàn thành'}
                                        </button>
                                    </div>
                                </td>
                            }
                        </tr>
                    )
                }
            )}
            </tbody>
        </table>
    )
}

export default memo(UnitNumberTable);
