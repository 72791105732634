import React, {useEffect, useState} from "react";
import {Col, Row, Form, Button, Container, Alert} from 'react-bootstrap';
import BgImage from "../../assets/img/signin.svg";
import Loading from "../../components/main/Loading";
import axiosCommon from "../../config/axiosCommon";
import {Routes} from "../../routes";
import {setPageTitle, showToastSuccess} from "../../common/function";

export default () => {
    const currentURL = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentURL).search);
    const email = searchParams.get("email");
    const verify_code = searchParams.get("verify_code");
    const [loaded, setLoaded] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [data, setData] = useState({
        password: '',
        rePassword: '',
    })

    const updateField = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = (e) => {
        setLoaded(true)
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setLoaded(false);
            return;
        }
        if (data.password !== data.rePassword) {
            setErrorText('Mật khẩu không khớp, vui lòng kiểm tra lại!')
            setShowAlert(true)
            setLoaded(false);
            return
        }
        if (!data.password || data.password.length < 6) {
            setErrorText('Mật khẩu tối thiểu 6 kí tự!')
            setShowAlert(true)
            setLoaded(false);
            return
        }
        setErrorText('')
        setShowAlert(false)
        let config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: true,
        }
        let obj = {
            verify_code,
            email,
            password: data.password
        }
        axiosCommon.post('/api' + Routes.ForgotPasswordChangePass.path, obj, config)
            .then(res => {
                    setShowAlert(false)
                    window.location.href = '/login'
                showToastSuccess('Đổi mật khẩu thành công!')
                },
                error => {
                    setLoaded(false);
                    setErrorText('Không đổi được, vui lòng thử lại!')
                    setShowAlert(true)
                },
            )
    }

    useEffect(() => {
        setPageTitle('Đổi mật khẩu');
    }, [])

    return (
        <main>
            <Loading show={loaded}/>
            <section className="align-items-center mt-lg-5 mb-lg-5">
                <Container>
                    <Row className="justify-content-center form-bg-image" style={{backgroundImage: `url(${BgImage})`}}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    <h3 className="mb-0">Quên mật khẩu</h3>
                                </div>
                                <Alert
                                    variant="danger"
                                    show={showAlert}
                                    onClose={() => setShowAlert(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlert(false)}/>
                                    </div>
                                </Alert>
                                <Alert
                                    variant="success"
                                    show={showAlertSuccess}
                                    onClose={() => setShowAlertSuccess(false)}>
                                    <div className="d-flex justify-content-between">
                                        <div>{errorText}</div>
                                        <Button variant="close" size="xs" onClick={() => setShowAlertSuccess(false)}/>
                                    </div>
                                </Alert>
                                <Form className="mt-4">
                                    <Form.Group>
                                        <Form.Label>Mật khẩu mới</Form.Label>
                                        <Form.Control type="password" name="password" value={data.password} required
                                                      onChange={updateField}/>
                                    </Form.Group>
                                    <Form.Group className={`mt-4`}>
                                        <Form.Label>Nhập lại mật khẩu mới</Form.Label>
                                        <Form.Control type="password" name="rePassword" value={data.rePassword}
                                                      onChange={updateField}/>
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={onSubmit} className="w-100 mt-3">Đổi mật
                                        khẩu</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
