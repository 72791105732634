import {memo, useState} from "react";
import {cloneDeep} from "lodash";
import {useSnapshot} from "valtio";
import {timeTableProxy} from "../../../store/proxy/timeTable.proxy";
import {getUnitDefault} from "../../../common/function";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {toastProxy} from "../../../store/proxy/toast.proxy";

function UnitNumberTable({day, unit, indexSkill, indexDetail}) {
    const {setDataTimeTable, dataTimeTable, maxDayTimetable} = useSnapshot(timeTableProxy)
    const [unitPerWeek, setUnitPerWeek] = useState('')
    const {setContentToast} = useSnapshot(toastProxy)

    const changeInput = (val, ind) => {
        let _unit = cloneDeep(unit)
        let _dataTimeTable = cloneDeep(dataTimeTable)
        if (val < 0)
            val = 0
        _unit[ind] = Number(val)
        _dataTimeTable[indexSkill].detail[indexDetail].unit = [..._unit]
        setDataTimeTable([..._dataTimeTable])
    }

    const changeUnitPerWeek = () => {
        if (Number(unitPerWeek) <= 0) {
            setContentToast('Vui lòng nhập đúng giá trị cho số Unit/buổi')
            return
        }
        let _dataTimeTable = cloneDeep(dataTimeTable)
        if (unitPerWeek <= 0) return
        _dataTimeTable[indexSkill].detail[indexDetail].unit = getUnitDefault(day, unitPerWeek)
        setDataTimeTable([..._dataTimeTable])
        setUnitPerWeek('')
    }

    return (
        <table className="table table-bordered bg-white m-0 align-middle items-center">
            <tbody>
            {[...Array(maxDayTimetable[indexDetail])].map((x, i) => {
                    return (
                        <tr className={`border-bottom-0`} key={i + `unit_number_table`}
                            id={i + `unit_number_table` + indexDetail + indexSkill}>
	                        {indexSkill === 0 &&
	                            <td style={{padding: 10}}>
	                                <div style={{width:"57px"}}>Ngày {i + 1}</div>
	                            </td>
	                        }
                            {
                                i === 0 &&
                                <td className="text-center" rowSpan={maxDayTimetable[indexDetail]}>
                                    <div>Unit/buổi:</div>
                                    <input
                                        type='number'
                                        style={{width: 40, marginTop: 0}}
                                        className="text-center border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder=""
                                        value={unitPerWeek}
                                        onChange={e => setUnitPerWeek(e.target.value)}
                                    />
                                    <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Thiết lập số Unit/buổi cho các ngày trong tuần</Tooltip>}>
                                        <i className="fa-solid fa-arrows-rotate text-info" onClick={changeUnitPerWeek}
                                            style={{color: "blue", cursor: 'pointer', fontSize: 13, marginLeft: 3}}></i>
                                    </OverlayTrigger>
                                </td>
                            }
                            <td style={{height: 151, padding: '0.35rem'}}>
                                <input
                                    type='number'
                                    style={{width: 30, marginTop: 0}}
                                    className="border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    value={unit[i] || 0}
                                    onChange={e => changeInput(e.target.value, i)}
                                />
                            </td>
                        </tr>
                    )
                }
            )}
            </tbody>
        </table>
    )
}

export default memo(UnitNumberTable);
