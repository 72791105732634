import React, {Fragment, useState} from 'react';
import DataTable from 'react-data-table-component';
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';

export default (props) => {
    const {
        show,
        setShowModal,
        listData,
		actionOk,
    } = props

    const [columns] = useState(
        [
            {
                name: 'No',
                cell: (row, index) => index + 1,
                sortable: true,
                width: 10,
            },
            {
                name: 'Họ tên',
                selector: row => columnWithTooltip(row.full_name),
                grow: 2,
            },
            {
                name: 'Email',
                sortable: true,
                selector: row => columnWithTooltip(row.email),
                grow: 2,
            },
            {
                name: 'SĐT',
                sortable: true,
                selector: row => columnWithTooltip(row.phone),
                grow: 2,
            },
            {
                name: 'Ghi chú cuộc gọi',
                sortable: true,
                selector: row => columnWithTooltip(row.call_note),
                grow: 3,
            },
            {
                name: 'Sale ghi chú',
                sortable: true,
                selector: row => columnWithTooltip(row.sale_note),
                grow: 3,
            },
            {
                name: 'MKT ghi chú',
                sortable: true,
                selector: row => columnWithTooltip(row.MKT_note),
                grow: 3,
            },
            {
                name: 'Thời gian sửa',
                sortable: true,
                selector: row => columnWithTooltip(row.created_time),
                grow: 3,
            },
            {
                name: 'Người sửa',
                sortable: true,
                selector: row => columnWithTooltip(row.full_name_user),
                grow: 3,
            },
        ]
    );


    const columnWithTooltip = (input) => {
		if (input === '') {
			return <span className="text-danger">Đã xóa</span>
		}
		return (
			<Fragment>
				<OverlayTrigger placement='top' overlay={
					<Tooltip>{input}</Tooltip>}>
					<div>{input}</div>
				</OverlayTrigger>
				
			</Fragment>
		)
	};
	
    const handleClose = () => {
		setShowModal(false);
	};

    const handleOk = () => {
        actionOk()
        handleClose()
    };
    
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        },
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl" >
                <Modal.Header closeButton>
                    <Modal.Title>Lịch sử thay đổi dữ liệu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        columns={columns}
                        data={listData}
                        // pagination
                        title="Bảng lịch sử thay đổi dữ liệu"
                        customStyles={customStyles}
                        noDataComponent={<div className="p-4">Không tìm thấy dữ liệu nào.</div>}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleOk}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
