import React, {useEffect, useState} from "react";
import DataTable from 'react-data-table-component';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {toast} from 'react-toastify';
import constants from "../../config/constants";
import Loading from "components/main/Loading";
import Breadcrumbs from "components/main/Breadcrumbs";
import {Routes} from "routes";
import {setPageTitle, changeQueryString, addReturnUrlToParamString, getSearchParams} from "common/function";
import axiosCommon from 'config/axiosCommon';
import {ApiPath} from "apiPath";
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

const customStyles = {
    headCells: {
        style: {
            fontWeight: 'bold',
            fontSize: '16px',
        },
    },
};
const conditionalRowStyles = [
    {
        when: row => row.check_edit_fees,
        style: {
            backgroundColor: 'pink',
            color: 'red',
        },
    },
];

export default function ListLearningPath() {
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    const [target, setTarget] = useState(-1)

    const [sales, setSales] = useState([]);
    const [salesPlus, setSalesPlus] = useState([])
    const [learningPaths, setLearningPaths] = useState([]);
    const [totalRows, setTotalRows] = useState(1);
    const [key, setKey] = useState(Date.now());

    const [search, setSearch] = useState({
        full_name: '',
        phone: '',
        student_information_id: '',
        sale_assigned_user: '',
        assigned_user: '',
        start_date: '',
        end_date: '',
        per_page: constants.arrPerPage[0],
        page: 1,
    })

    const [assignedUser, setAssignedUser] = useState(0)

    const [isContentModalOpen, setIsContentModalOpen] = useState(false)

    const [isChangeAssignUserModalOpen, setIsChangeAssignUserModalOpen] = useState(false)

    const showContentModal = (id) => {
        setTarget(id)
        setIsContentModalOpen(true)
    }

    const showChangeAssignUserModal = (id) => {
        setTarget(id)
        setAssignedUser(learningPaths?.find(i => i.id == id)?.assigned_user_learning_path)
        setIsChangeAssignUserModalOpen(true)
    }

    const handleChangeAssignUser = async () => {
        setIsLoading(true)
        const data = {assigned_user: assignedUser, type: ""}
        const res = await axiosCommon.put(ApiPath.learningPaths + `/${target}`, data)
            .catch(() => null)
        if (res) {
            setIsChangeAssignUserModalOpen(false)
            await fetchData()
        }

        setIsLoading(false)
    }

    const fetchData = async (inputs = null) => {
        setIsLoading(true)
        let searchInfo = {
            ...search,
            start_date: search.start_date ? moment(search.start_date).format("YYYY-MM-DD") : '',
            end_date: search.end_date ? moment(search.end_date).format("YYYY-MM-DD") : ''
        }
        if (inputs) {
            searchInfo = {...searchInfo, ...inputs}
        } else {
            changeQueryString(searchInfo)
        }
        const data = await Promise.allSettled([
            axiosCommon.post(ApiPath.commonData),
            axiosCommon.get(ApiPath.learningPaths, {params: searchInfo})
        ])

        if (data.some(item => item.status === "rejected")) {
            toast.error('Vui lòng tải lại trang!', {
                autoClose: false,
                position: "top-right",
                hideProgressBar: true,
                closeOnClick: true,
            })
        } else {
            setSales(data[0].value.data.sales)
            setSalesPlus(data[0].value.data.salesPlus)
            setLearningPaths(data[1].value.data.data)
            setTotalRows(data[1].value.data.total)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setPageTitle('Quản lý lộ trình');
        const searchParams = getSearchParams()
        setSearch({
            ...search,
            ...searchParams,
            start_date: searchParams.start_date ? new Date(searchParams.start_date) : '',
            end_date: searchParams.end_date ? new Date(searchParams.end_date) : '',
        })
        setKey(Date.now())
        fetchData(searchParams)
        const notification = location.state?.notification
        if (notification) {
            switch (notification.type) {
                case 'success':
                    toast.success(notification.message, {
                        autoClose: 3000,
                        hideProgressBar: true,
                        position: "top-right",
                        closeOnClick: true,
                    })
                    break
            }
            navigate(location.pathname + location.search, {replace: true})
        }
    }, [location])

    const columnsConfig = [
        {
            name: 'Mã HV',
            selector: row => row.student_information_id,
            sortable: true,
            width: '120px',
            cell: row => <span>{row.student_information_id}{Array(6).fill(<>&nbsp;</>)}</span>,
            center: true,
        },
        {
            name: 'Tên học viên',
            selector: row => row.full_name_student || '-',
            grow: 6,
        },
        {
            name: 'Email',
            selector: row => row.email || '-',
            grow: 6,
        },
        {
            name: 'SĐT',
            selector: row => row.phone_student || '-',
            grow: 4,
        },
        {
            name: 'Ngày tạo lộ trình',
            cell: row => row.learning_path_date ? moment(row.learning_path_date).format("DD/MM/YYYY") : '-',
            selector: row => row.created_at,
            grow: 6,
            sortable: true,
        },
        {
            name: 'Link lộ trình',
            selector: row => row.folder_link || '-',
            grow: 6,
            sortable: true,
        },
        {
            name: 'Người tạo',
            selector: row => row.full_name_learning_path || '-',
            grow: 6,
            sortable: true,
        },
        {
            name: 'Sale phụ trách',
            selector: row => row.full_name_sale || '-',
            grow: 6,
            sortable: true,
        },
        {
            name: '',
            width: '120px',
            cell: (row) => {
                return (
                    <div className="d-flex gap-1">
                        <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa thông tin</Tooltip>}>
                            <NavLink className={'btn btn-info btn-sm'}
                                     to={`/learning-paths/${row.id}/edit?` + addReturnUrlToParamString()}>
                                <i className="fa-regular fa-pen-to-square"></i>
                            </NavLink>
                        </OverlayTrigger>
                        <OverlayTrigger placement='left' overlay={<Tooltip>Ghi chú của sale</Tooltip>}>
                            <Button className={'btn btn-success btn-sm'} onClick={() => showContentModal(row.id)}>
                                <i className="fa-solid fa-route"></i>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='left' overlay={<Tooltip>Chỉnh sửa người tạo</Tooltip>}>
                            <Button className={'btn btn-secondary btn-sm'}
                                    onClick={() => showChangeAssignUserModal(row.id)}>
                                <i className="fa-solid fa-user text-white"></i>
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            },
        },
    ]

    const tableStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '16px',
            },
        }
    }

    const getSearchInfo = (searchs = null) => {
        let searchInfo = {
            ...search,
            start_date: search.start_date ? moment(search.start_date).format("YYYY-MM-DD") : '',
            end_date: search.end_date ? moment(search.end_date).format("YYYY-MM-DD") : '',
        }
        if (searchs) {
            searchInfo = {...searchInfo, ...searchs}
        } else {
            changeQueryString(searchInfo)
        }
        return searchInfo
    }

    const handlePageChange = (page) => {
        let searchParams = {
            ...search,
            page: page
        }
        setSearch(searchParams)
        searchParams = {...getSearchInfo(), page: page}
        changeQueryString(searchParams)
        fetchData(searchParams)
    }
    const handlePerPageChange = (newPerPage) => {
        let searchParams = {
            ...search,
            per_page: newPerPage,
            page: 1
        }
        setSearch(searchParams)
        searchParams = {...getSearchInfo(), per_page: newPerPage, page: 1}
        changeQueryString(searchParams)
        fetchData(searchParams)
    }

    return (
        <>
            <Loading show={isLoading}/>
            <div className="row mb-3">
                <div className="col">
                    <Breadcrumbs firstLocation={Routes.LearningPaths}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <Form.Label>Mã học viên</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Mã học viên"
                        value={search.student_information_id}
                        onChange={e => setSearch({...search, student_information_id: e.target.value})}
                    />
                </div>
                <div className="col">
                    <Form.Label>Số điện thoại</Form.Label>
                    <Form.Control
                        placeholder="Số điện thoại"
                        value={search.phone}
                        onChange={e => setSearch({...search, phone: e.target.value})}
                    />
                </div>
                <div className="col">
                    <Form.Label>Ngày bắt đầu</Form.Label>
                    <div class="w-100">
	                    <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon placeholderText="Ngày bắt đầu"
	                                selected={search.start_date}
	                                onChange={value => setSearch({...search, start_date: value})}/></div>
                	</div>
                <div className="col">
                    <Form.Label>Ngày kết thúc</Form.Label>
                    <div class="w-100">
	                    <DatePicker className="form-control" dateFormat="dd/MM/yyyy" showIcon
	                                placeholderText="Ngày kết thúc" selected={search.end_date}
	                                onChange={value => setSearch({...search, end_date: value})}/>
                	</div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col">
                    <Form.Label>Tên học viên</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tên học viên"
                        value={search.full_name}
                        onChange={e => setSearch({...search, full_name: e.target.value})}
                    />
                </div>
                <div className="col">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Email"
                        value={search.email}
                        onChange={e => setSearch({...search, email: e.target.value})}
                    />
                </div>
                <div className="col">
                    <Form.Label>Người làm lộ trình</Form.Label>
                    <Form.Select
                        type="text"
                        placeholder="Người làm lộ trình"
                        value={search.assigned_user}
                        onChange={e => setSearch({...search, assigned_user: e.target.value})}
                    >
                        <option value="">Tất cả</option>
                        <option value="none">Chưa có người làm lộ trình</option>
                        {salesPlus.map(sale => <option value={sale.id} key={sale.id}>{sale.full_name}</option>)}
                    </Form.Select>
                </div>
                <div className="col">
                    <Form.Label>Sale phụ trách</Form.Label>
                    <Form.Select
                        type="text"
                        placeholder="Người làm lộ trình"
                        value={search.sale_assigned_user}
                        onChange={e => setSearch({...search, sale_assigned_user: e.target.value})}
                    >
                        <option value="">Tất cả</option>
                        {sales.map(sale => <option value={sale.id} key={sale.id}>{sale.full_name}</option>)}
                    </Form.Select>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col d-flex flex-column justify-content-end">
                    <Button style={{backgroundColor: '#2e3650', borderColor: '#2e3650', color: 'white'}}
                            variant="secondary" onClick={() => fetchData()}>Tìm kiếm</Button>
                </div>
            </div>

            <DataTable
                key={key}
                paginationServer
                paginationTotalRows={totalRows}
                paginationComponentOptions={{
                    rowsPerPageText: 'Số hàng mỗi trang:',
                    rangeSeparatorText: 'của',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Tất cả',
                }}
                onChangePage={handlePageChange}
                currentPage={search?.page}
                title="Danh sách làm lộ trình"
                columns={columnsConfig}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                data={learningPaths}
                pagination
                onChangeRowsPerPage={handlePerPageChange}
                paginationRowsPerPageOptions={constants.arrPerPage}
                paginationPerPage={parseInt(search?.per_page)}
                noDataComponent={<div className="p-4">Không tìm thấy lộ trình nào.</div>}
            />

            <Modal show={isContentModalOpen} onHide={() => setIsContentModalOpen(false)}>
                <Modal.Header closeButton className="d-flex align-content-center">
                    <Modal.Title>Ghi chú của sale</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex gap-2 flex-column px-3">
                    <Form.Control
                        type="text"
                        as="textarea"
                        rows={5}
                        value={learningPaths?.find(i => i.id === target)?.sale_note || 'Không Có ghi chú'}
                        disabled={true}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={isChangeAssignUserModalOpen} onHide={() => setIsChangeAssignUserModalOpen(false)}>
                <Modal.Header closeButton className="d-flex align-content-center">
                    <Modal.Title>Thay đổi người tạo lộ trình</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex gap-2 flex-column px-3">
                    <Form.Select
                        type="text"
                        placeholder="Người làm lộ trình"
                        value={assignedUser}
                        onChange={e => setAssignedUser(e.target.value)}
                    >
                        <option value="">Người làm lộ trình</option>
                        {salesPlus.map(sale => <option value={sale.id} key={sale.id}>{sale.full_name}</option>)}
                    </Form.Select>
                    <Button className="mt-3 mb-4" variant='info' onClick={handleChangeAssignUser}>Thay đổi</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}
