import {Fragment, memo, useState} from "react";
import {useSnapshot} from "valtio";
import {cloneDeep} from "lodash";
import {timeTableProxy} from "../../store/proxy/timeTable.proxy";
import ShowLinkModal from "./ShowLinkModal";

function UnitNameTable({arrUnitIds, notes, isComplete, indexSkill, arrSubSillIds}) {
    const {lessons, linkAudios, linkLessons, dataTimeTable, setDataTimeTable, subskills} = useSnapshot(timeTableProxy)
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [currentUnit, setCurrentUnit] = useState({});

    const showNoteModal = (unitId) => {
        if (lessons?.['lesson_deleted_id_' + unitId]) return
        setCurrentUnit({
            unitId,
            'lesson': lessons['lesson_id_' + unitId] ?? '',
            'link_audio': linkAudios['lesson_id_' + unitId] ?? '',
            'link_lesson': linkLessons['lesson_id_' + unitId] ?? '',
            'description': lessons['lesson_description_id_' + unitId] ?? '',
            'note': notes['unit_id_' + unitId] ?? '',
            'isComplete': isComplete['unit_id_' + unitId] ?? false
        })
        setShowEditPopup(true)
    }

    const updateIsComplete = (unitId) => {
        let _dataTimeTable = cloneDeep(dataTimeTable)
        let _isComplete = cloneDeep(isComplete)
        _isComplete['unit_id_' + unitId] = !isComplete['unit_id_' + unitId]
        if (_dataTimeTable?.length > 0) {
            _dataTimeTable.map((skills, index) => {
                if (index === indexSkill) {
                    skills.isComplete = _isComplete
                }
            })
            setDataTimeTable([..._dataTimeTable])
        }
    }

    return (
        <Fragment>
            <table
                className="table-bordered bg-white m-0"
                style={{
                    fontSize: 14,
                    fontFamily: '"Nunito Sans", sans-serif',
                    borderColor: '#eaedf2'
                }}
            >
                <tbody>
                {arrUnitIds.map((arrUnitId, i) => {
                        return (
                            <tr key={i + `arr_unit`}
                                style={{padding: '0.35rem', height: (i + 1) % 4 === 0 ? 151.5 : 151}}
                                className={`border-bottom-0`}>
                                <td>
                                    <div className="th-unit-name-course">
                                        {
                                            arrUnitId.map((unitId, indU) => {
                                                return (
                                                    <div key={indU + 'unit_id'} className="unit-name-list">
                                                        <div className="text-danger subskill-name-div">
                                                            {subskills['subskill_id_' + arrSubSillIds[i][indU]] ?? arrSubSillIds[i][indU]}
                                                        </div>
                                                        <span
                                                            onClick={() => showNoteModal(unitId)}
                                                            style={{
                                                                cursor: "pointer",
                                                                textDecoration: Boolean(isComplete['unit_id_' + unitId]) ? 'line-through' : ''
                                                            }}>
                                                            {lessons['lesson_id_' + unitId] ?? unitId}
                                                        </span>
                                                        {!lessons?.['lesson_deleted_id_' + unitId] &&
                                                            <button
                                                                type="button"
                                                                style={{fontSize: 13}}
                                                                onClick={() => updateIsComplete(unitId)}
                                                                className={`btn btn-link ${Boolean(isComplete['unit_id_' + unitId]) ? 'text-primary' : 'text-note-contain'}  py-0 text-decoration-underline`}
                                                            >{!Boolean(isComplete['unit_id_' + unitId]) ? 'Hoàn thành' : 'Bỏ hoàn thành'}
                                                            </button>
                                                        }
                                                        <div><span
                                                            style={{background: "yellow"}}>{notes['unit_id_' + unitId]}</span>
                                                        </div>
                                                        {lessons?.['lesson_deleted_id_' + unitId] &&
                                                            <div className={`text-danger fs-8`}>(Bài học đã bị xóa)</div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            {showEditPopup && <ShowLinkModal show={showEditPopup} setShowModal={setShowEditPopup}
                                             currentUnit={currentUnit}/>}
        </Fragment>

    )
}

export default memo(UnitNameTable);
